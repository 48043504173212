export const MuiPaper = {
  root: {
    '&.MuiAutocomplete-paper': {
      border: '1px #F0F0F0 solid',
      boxShadow: 'none',
    },
    '&.MuiAutocomplete-paper .MuiAutocomplete-listbox .MuiAutocomplete-option[data-focus="true"]': {
      backgroundColor: '#1D4982',
      color: '#FFFFFF',
    },
    '&.MuiAutocomplete-paper .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"]': {
      backgroundColor: '#F0F0F0',
      color: '#000000',
    },
  },
};
