export const MuiSelect = {
  select: {
    borderRadius: 0,
    position: 'relative',
    backgroundColor: '#FFFFFF',
    fontSize: 'inherit',
    padding: '10px 26px 10px 12px',
    boxShadow: '0px 3px 6px #00000024',
    '&:focus': {
      backgroundColor: '#1D4982',
      color: '#FFFFFF',
    },
  },
  icon: {
    color: 'inherit',
  },
};
