import React from 'react';


const epod: React.FC = () => {
  return (
    <div className="epod hero is-fullheight">
      <div className="hero-body">
        <div className="container has-text-centered">
          <img
            src="images/AWS-Error.jpg"
            alt="epod"
            className="ent-logo"
            width="727px"
            height="364px"
          />
          <div className="title is-6 mb-6">Something went wrong...</div>
          <div className="subtitle-epod">  Proof of delivery image no longer exist, it can</div>
          <p className="has-text-link ">
            only be stored in the system up to 90 Days
          </p> 
        </div>
      </div>
    </div>
  );
};

export default epod;