/* eslint-disable max-len */
import { useState, useEffect } from 'react';
import packageJson from '../../../package.json';

global.appVersion = packageJson.version;

declare global {
  namespace NodeJS {
    interface Global {
      document: Document;
      window: Window;
      navigator: Navigator;
      appVersion: string;
    }
  }
}

// version from response - first param, local version second param
export const semverGreaterThan = (versionA: string, versionB: string) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CacheBuster = ({ children }: any) => {
  const [versionState, setVersionState] = useState({
    loading: true,
    isLatestVersion: false,
  });
  const refreshCacheAndReload = () => {
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      // eslint-disable-next-line func-names, @typescript-eslint/no-explicit-any
      caches.keys().then(function (names: any) {
        for (const name of names) caches.delete(name);
      });
    }
    // delete browser cache and hard reload
    window.location.reload();
  };

  useEffect(() => {
    fetch(`/meta.json?${new Date().getTime()}`, { cache: 'no-cache' })
      .then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version;
        const currentVersion = global.appVersion;
        const shouldForceRefresh = semverGreaterThan(
          latestVersion,
          currentVersion
        );
        if (shouldForceRefresh) {
          // eslint-disable-next-line no-console
          console.log(
            `We have a new version - ${latestVersion}. Should force refresh`
          );
          setVersionState({
            loading: false,
            isLatestVersion: false,
          });
        } else {
          // eslint-disable-next-line no-console
          console.log(
            `You already have the latest version - ${latestVersion}. No cache refresh needed.`
          );
          setVersionState({
            loading: false,
            isLatestVersion: true,
          });
        }
      });
  }, []);
  const { loading, isLatestVersion } = versionState;
  return children({ loading, isLatestVersion, refreshCacheAndReload });
};

export default CacheBuster;
