/* eslint-disable @typescript-eslint/no-explicit-any */
import { all, call, takeLatest, put, select, delay } from 'redux-saga/effects';
import * as bulmaToast from 'bulma-toast';
import { actionTypes } from './reducer';
import { actionTypes as authActionTypes } from '../auth/reducer';
import {
  getAllSlaData as getAllSlaDataService,
  getSlaFilters as getSlaFiltersService,
  getSlaDataFiltered as getSlaDataFilteredService,
  generateSla as generateSlaService,
  getSlaFeedData as getSlaFeedDataService,
} from './service';
import {
  AllOrdersParams,
  GenerateSlaParams,
  SlaDataFilteredParams,
  SlaFeedParams,
} from './interface';

function* getAllSlaData({ page, limit }: AllOrdersParams): Generator<any> {
  try {
    const state: any = yield select();
    const data: any = yield call(getAllSlaDataService, page, limit);

    data.cachedSlaData = [];
    data.metadata = {
      total: data.total,
      limit: data.limit,
      hasNext: data.hasNext,
      hasPrevious: data.hasPrevious,
      nextPage: data.nextPage,
      currentPage: data.currentPage,
      previousPage: data.PreviousPage,
    };
    // if meta data next page has value
    if (!data.hasNext) {
      const newCachedSlaData = state.slaleadtime.cachedSlaData;
      newCachedSlaData[page] = data.data;
      data.cachedSlaData = newCachedSlaData;
      const finalPages = newCachedSlaData.length - 1;
      data.pageCount = finalPages;
    } else {
      // set page count to next page
      data.pageCount = data.metadata.nextPage;
      if (!state.slaleadtime.cachedSlaData[page]) {
        // concat cached orders
        const newCachedSlaData = state.slaleadtime.cachedSlaData;
        newCachedSlaData[page] = data.data;
        data.cachedSlaData = newCachedSlaData;
      }
    }
    yield put({ type: actionTypes.GET_ALL_SLA_SUCCESS, data });
  } catch (err) {
    if (err.status === 403) {
      yield put({ type: authActionTypes.LOGOUT_SUCCESS });
      bulmaToast.toast({
        message: 'Authentication Failed!',
        type: 'is-danger',
        position: 'top-center',
        dismissible: true,
        duration: 10000,
        closeOnClick: true,
      });
    } else {
      bulmaToast.toast({
        message: err.message || err.details,
        type: 'is-danger',
        position: 'top-center',
        dismissible: true,
        duration: 10000,
        closeOnClick: true,
      });
    }
    yield put({ type: actionTypes.GET_ALL_SLA_FAILED, err });
  }
}

function* getSlaDataFiltered({
  page,
  limit,
  owner,
  location,
  slaStatus,
  orderCreatedDateStart,
  orderCreatedDateEnd,
  e2eDaysLeft,
}: SlaDataFilteredParams): Generator<any> {
  try {
    const state: any = yield select();
    const data: any = yield call(
      getSlaDataFilteredService,
      page,
      limit,
      owner,
      location,
      slaStatus,
      orderCreatedDateStart,
      orderCreatedDateEnd,
      e2eDaysLeft
    );

    data.cachedSlaData = [];
    data.metadata = {
      total: data.total,
      limit: data.limit,
      hasNext: data.hasNext,
      hasPrevious: data.hasPrevious,
      nextPage: data.nextPage,
      currentPage: data.currentPage,
      previousPage: data.PreviousPage,
    };
    // if meta data next page has value
    if (!data.hasNext) {
      const newCachedSlaData = state.slaleadtime.cachedSlaData;
      newCachedSlaData[page] = data.data;
      data.cachedSlaData = newCachedSlaData;
      const finalPages = newCachedSlaData.length - 1;
      data.pageCount = finalPages;
    } else {
      // set page count to next page
      data.pageCount = data.metadata.nextPage;
      if (!state.slaleadtime.cachedSlaData[page]) {
        // concat cached orders
        const newCachedSlaData = state.slaleadtime.cachedSlaData;
        newCachedSlaData[page] = data.data;
        data.cachedSlaData = newCachedSlaData;
      }
    }
    yield put({ type: actionTypes.GET_SLA_FILTERED_SUCCESS, data });
  } catch (err) {
    if (err.status === 403) {
      yield put({ type: authActionTypes.LOGOUT_SUCCESS });
      bulmaToast.toast({
        message: 'Authentication Failed!',
        type: 'is-danger',
        position: 'top-center',
        dismissible: true,
        duration: 10000,
        closeOnClick: true,
      });
    } else {
      bulmaToast.toast({
        message: err.message || err.details,
        type: 'is-danger',
        position: 'top-center',
        dismissible: true,
        duration: 10000,
        closeOnClick: true,
      });
    }
    yield put({ type: actionTypes.GET_SLA_FILTERED_FAILED, err });
  }
}

function* getSlaFilters(): Generator<any> {
  try {
    const data = yield call(getSlaFiltersService);
    yield put({ type: actionTypes.GET_SLA_FILTERS_SUCCESS, data });
  } catch (err) {
    if (err.status === 403) {
      yield put({ type: authActionTypes.LOGOUT_SUCCESS });
      bulmaToast.toast({
        message: 'Authentication Failed!',
        type: 'is-danger',
        position: 'top-center',
        dismissible: true,
        duration: 10000,
        closeOnClick: true,
      });
    } else {
      bulmaToast.toast({
        message: err.message || err.details,
        type: 'is-danger',
        position: 'top-center',
        dismissible: true,
        duration: 10000,
        closeOnClick: true,
      });
    }
    yield put({ type: actionTypes.GET_SLA_FILTERS_FAILED, err });
  }
}

function* getSlaFeedData({ id }: SlaFeedParams): Generator<any> {
  try {
    const data: any = yield call(getSlaFeedDataService, id);
    if (data.status === 'SUCCESS') {
      yield put({ type: actionTypes.GET_SLA_FEED_SUCCESS, data });
      bulmaToast.toast({
        message: 'Successfully generated SLA List',
        type: 'is-success',
        position: 'bottom-right',
        dismissible: true,
        duration: 7000,
        closeOnClick: true,
        animate: { in: 'slideInRight', out: 'slideOutRight' },
      });
      window.open(data.downloadURL, '_blank');
    } else if (data.status === 'IN_PROGRESS') {
      // POLL HERE EVERY 60 SECONDS
      yield delay(60000);
      yield put({ type: actionTypes.GET_SLA_FEED_REQUEST, id });
    } else {
      yield put({ type: actionTypes.GET_SLA_FEED_FAILED });
      bulmaToast.toast({
        message: 'Cannot Generate SLA List',
        type: 'is-danger',
        position: 'top-center',
        dismissible: true,
        duration: 10000,
        closeOnClick: true,
      });
    }
  } catch (err) {
    if (err.status === 403) {
      yield put({ type: authActionTypes.LOGOUT_SUCCESS });
      bulmaToast.toast({
        message: 'Authentication Failed!',
        type: 'is-danger',
        position: 'top-center',
        dismissible: true,
        duration: 10000,
        closeOnClick: true,
      });
    } else {
      bulmaToast.toast({
        message: err.message || err.details,
        type: 'is-danger',
        position: 'top-center',
        dismissible: true,
        duration: 10000,
        closeOnClick: true,
      });
    }
    yield put({ type: actionTypes.GET_SLA_FEED_FAILED, err });
  }
}

function* generateSla({
  orderCreatedDateStart,
  orderCreatedDateEnd,
  location,
  e2eDaysLeft,
  slaStatus,
}: GenerateSlaParams): Generator<any> {
  try {
    const data = yield call(
      generateSlaService,
      orderCreatedDateStart,
      orderCreatedDateEnd,
      location,
      e2eDaysLeft,
      slaStatus
    );
    yield put({ type: actionTypes.GET_SLA_FEED_REQUEST, id: data });
    yield put({ type: actionTypes.GENERATE_SLA_SUCCESS, data });
  } catch (err) {
    if (err.status === 403) {
      yield put({ type: authActionTypes.LOGOUT_SUCCESS });
      bulmaToast.toast({
        message: 'Authentication Failed!',
        type: 'is-danger',
        position: 'top-center',
        dismissible: true,
        duration: 10000,
        closeOnClick: true,
      });
    } else {
      bulmaToast.toast({
        message: err.message || err.details,
        type: 'is-danger',
        position: 'top-center',
        dismissible: true,
        duration: 10000,
        closeOnClick: true,
      });
    }
    yield put({ type: actionTypes.GENERATE_SLA_SUCCESS, err });
  }
}

function* watchSlaRequests() {
  yield all([
    takeLatest(actionTypes.GET_ALL_SLA_REQUEST, getAllSlaData),
    takeLatest(actionTypes.GET_SLA_FILTERS_REQUEST, getSlaFilters),
    takeLatest(actionTypes.GET_SLA_FILTERED_REQUEST, getSlaDataFiltered),
    takeLatest(actionTypes.GET_SLA_FEED_REQUEST, getSlaFeedData),
    takeLatest(actionTypes.GENERATE_SLA_REQUEST, generateSla),
  ]);
}

export default [watchSlaRequests()];
