/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { all, call, takeLatest, put } from 'redux-saga/effects';
import * as bulmaToast from 'bulma-toast';
import { actionTypes } from './reducer';
import {
  getClaimsParameters as getClaimsParametersService,
  validateClaimsPackage as validateClaimsPackageService,
  createClaimsTicket as createClaimsTicketService,
  addClaimsPackages as addClaimsPackagesService,
  createClaimsDocuments as createClaimsDocumentsService,
  uploadDocuments as uploadDocumentsService,
  createClaimsEmployees as createClaimsEmployeesService,
  updateClaimsStatus as updateClaimsStatusService,
  uploadMultipleTns as uploadMultipleTnsService,
} from './service';
import {
  BodySaga,
  CreateClaimsTicketSaga,
  CreateClaimsDocumentsSaga,
  UploadDocumentsSaga,
  CreateClaimsEmployeesSaga,
} from './interface';

function* getClaimsParameters(): Generator<any> {
  try {
    // To call async functions, use redux-saga's `call()`.
    const data = yield call(getClaimsParametersService);
    yield put({ type: actionTypes.GET_CLAIMS_PARAMETERS_SUCCESS, data });
  } catch (err) {
    bulmaToast.toast({
      message: err.message || err.details,
      type: 'is-danger',
      position: 'top-center',
      dismissible: true,
      duration: 10000,
      closeOnClick: true,
    });
    yield put({ type: actionTypes.GET_CLAIMS_PARAMETERS_FAILED, err });
  }
}

function* validateClaimsPackage({ body }: BodySaga): Generator<any> {
  try {
    // To call async functions, use redux-saga's `call()`.
    const data = yield call(validateClaimsPackageService, body);
    yield put({ type: actionTypes.VALIDATE_CLAIMS_PACKAGE_SUCCESS, data });
  } catch (err) {
    bulmaToast.toast({
      message: err.message || err.details,
      type: 'is-danger',
      position: 'top-center',
      dismissible: true,
      duration: 10000,
      closeOnClick: true,
    });
    yield put({ type: actionTypes.VALIDATE_CLAIMS_PACKAGE_FAILED, err });
  }
}

function* createClaimsTicket({ body }: CreateClaimsTicketSaga): Generator<any> {
  try {
    // To call async functions, use redux-saga's `call()`.
    const data: any = yield call(createClaimsTicketService, body);
    yield put({
      type: actionTypes.CREATE_CLAIMS_TICKET_SUCCESS,
      data: data.claims_reference_number,
    });
    const packageBody = {
      claims_reference_number: data.claims_reference_number,
      packages: body.packages,
    };
    yield put({
      type: actionTypes.ADD_CLAIMS_PACKAGES_REQUEST,
      body: packageBody,
    });
  } catch (err) {
    bulmaToast.toast({
      message: err.message || err.details,
      type: 'is-danger',
      position: 'top-center',
      dismissible: true,
      duration: 10000,
      closeOnClick: true,
    });
    yield put({ type: actionTypes.CREATE_CLAIMS_TICKET_FAILED, err });
  }
}

function* createClaimsDocuments({
  body,
}: CreateClaimsDocumentsSaga): Generator<any> {
  try {
    // To call async functions, use redux-saga's `call()`.
    const data: any = yield call(createClaimsDocumentsService, {
      claims_reference_number: body.claims_reference_number,
      supporting_doc_list: body.supporting_doc_list,
    });
    yield all(
      data.added_claim_supporting_docs.map((f: any) => {
        const { upload_key = {} } = f;
        const { url = '' } = upload_key;
        return call(uploadDocumentsService, body.files[f.row].file, url);
      })
    );
    yield put({ type: actionTypes.CREATE_CLAIMS_DOCUMENTS_SUCCESS, data });
  } catch (err) {
    bulmaToast.toast({
      message: err.message || err.details,
      type: 'is-danger',
      position: 'top-center',
      dismissible: true,
      duration: 10000,
      closeOnClick: true,
    });
    yield put({ type: actionTypes.CREATE_CLAIMS_DOCUMENTS_FAILED, err });
  }
}

function* createClaimsEmployees({
  body,
}: CreateClaimsEmployeesSaga): Generator<any> {
  try {
    // To call async functions, use redux-saga's `call()`.
    const data: any = yield call(createClaimsEmployeesService, {
      claims_reference_number: body.claims_reference_number,
      erring_person_list: body.erring_person_list,
    });
    // upload all deduction forms here
    yield all(
      data.added_erring_persons_for_claims.map((f: any) => {
        if (f.auto_deduction_form_upload_key) {
          return call(
            uploadDocumentsService,
            body.files[f.row].auto_deduction_form_file[0],
            f.auto_deduction_form_upload_key.url
          );
        }
        return put({ type: actionTypes.UPLOAD_CLAIMS_DOCUMENTS_FAILED });
      })
    );

    // upload all waivers here
    yield all(
      data.added_erring_persons_for_claims.map((f: any) => {
        if (f.auto_deduction_waiver_upload_key) {
          return call(
            uploadDocumentsService,
            body.files[f.row].auto_deduction_waiver_file[0],
            f.auto_deduction_waiver_upload_key.url
          );
        }
        return put({ type: actionTypes.UPLOAD_CLAIMS_DOCUMENTS_FAILED });
      })
    );

    // upload all incident reports here
    yield all(
      data.added_erring_persons_for_claims.map((f: any) => {
        if (f.incident_report_upload_key) {
          return call(
            uploadDocumentsService,
            body.files[f.row].incident_report_file[0],
            f.incident_report_upload_key.url
          );
        }
        return put({ type: actionTypes.UPLOAD_CLAIMS_DOCUMENTS_FAILED });
      })
    );
    yield put({ type: actionTypes.CREATE_CLAIMS_EMPLOYEES_SUCCESS, data });
  } catch (err) {
    bulmaToast.toast({
      message: err.message || err.details,
      type: 'is-danger',
      position: 'top-center',
      dismissible: true,
      duration: 10000,
      closeOnClick: true,
    });
    yield put({ type: actionTypes.CREATE_CLAIMS_EMPLOYEES_FAILED, err });
  }
}

function* uploadDocuments({ body, url }: UploadDocumentsSaga): Generator<any> {
  try {
    const data = yield call(uploadDocumentsService, body, url);
    yield put({ type: actionTypes.UPLOAD_CLAIMS_DOCUMENTS_SUCCESS, data });
  } catch (err) {
    bulmaToast.toast({
      message: err.message || err.details,
      type: 'is-danger',
      position: 'top-center',
      dismissible: true,
      duration: 10000,
      closeOnClick: true,
    });
    yield put({ type: actionTypes.UPLOAD_CLAIMS_DOCUMENTS_FAILED, err });
  }
}

function* addClaimsPackages({ body }: BodySaga): Generator<any> {
  try {
    // To call async functions, use redux-saga's `call()`.
    const data = yield call(addClaimsPackagesService, body);
    yield put({ type: actionTypes.ADD_CLAIMS_PACKAGES_SUCCESS, data });
  } catch (err) {
    bulmaToast.toast({
      message: err.message || err.details,
      type: 'is-danger',
      position: 'top-center',
      dismissible: true,
      duration: 10000,
      closeOnClick: true,
    });
    yield put({ type: actionTypes.ADD_CLAIMS_PACKAGES_FAILED, err });
  }
}

function* updateClaimsStatus({ body }: BodySaga): Generator<any> {
  try {
    // To call async functions, use redux-saga's `call()`.
    const data = yield call(updateClaimsStatusService, body);
    yield put({ type: actionTypes.UPDATE_CLAIMS_STATUS_SUCCESS, data });
  } catch (err) {
    bulmaToast.toast({
      message: err.message || err.details,
      type: 'is-danger',
      position: 'top-center',
      dismissible: true,
      duration: 10000,
      closeOnClick: true,
    });
    yield put({ type: actionTypes.UPDATE_CLAIMS_STATUS_FAILED, err });
  }
}

function* uploadMultipleTns({ body }: BodySaga): Generator<any> {
  try {
    // To call async functions, use redux-saga's `call()`.
    const data = yield call(uploadMultipleTnsService, body);
    yield put({ type: actionTypes.UPLOAD_MULTIPLE_TNS_SUCCESS, data });
  } catch (err) {
    bulmaToast.toast({
      message: err.message || err.details,
      type: 'is-danger',
      position: 'top-center',
      dismissible: true,
      duration: 10000,
      closeOnClick: true,
    });
    yield put({ type: actionTypes.UPLOAD_MULTIPLE_TNS_FAILED, err });
  }
}

function* watchClaimsRequest() {
  yield all([
    takeLatest(actionTypes.GET_CLAIMS_PARAMETERS_REQUEST, getClaimsParameters),
    takeLatest(
      actionTypes.VALIDATE_CLAIMS_PACKAGE_REQUEST,
      validateClaimsPackage
    ),
    takeLatest(actionTypes.CREATE_CLAIMS_TICKET_REQUEST, createClaimsTicket),
    takeLatest(
      actionTypes.CREATE_CLAIMS_DOCUMENTS_REQUEST,
      createClaimsDocuments
    ),
    takeLatest(actionTypes.ADD_CLAIMS_PACKAGES_REQUEST, addClaimsPackages),
    takeLatest(actionTypes.UPLOAD_CLAIMS_DOCUMENTS_REQUEST, uploadDocuments),
    takeLatest(
      actionTypes.CREATE_CLAIMS_EMPLOYEES_REQUEST,
      createClaimsEmployees
    ),
    takeLatest(actionTypes.UPDATE_CLAIMS_STATUS_REQUEST, updateClaimsStatus),
    takeLatest(actionTypes.UPLOAD_MULTIPLE_TNS_REQUEST, uploadMultipleTns),
  ]);
}

export default [watchClaimsRequest()];
