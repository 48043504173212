import request from '../../utils/api';

export const getAllSlaData = (page: number, limit: number) =>
  request(`hubm/sla/lead-time?page=${page}&limit=${limit}`);

export const getSlaDataFiltered = (
  page: number,
  limit: number,
  owner: string,
  location: string,
  slaStatus: string,
  orderCreatedDateStart: string,
  orderCreatedDateEnd: string,
  e2eDaysLeft: number[]
) => {
  return request('hubm/sla/lead-time/filter', {
    method: 'POST',
    body: {
      page,
      limit,
      owner,
      location,
      slaStatus,
      orderCreatedDateStart,
      orderCreatedDateEnd,
      e2eDaysLeft,
    },
  });
};

export const getSlaFilters = () => request('hubm/sla/lead-time/filters');

export const generateSla = (
  orderCreatedDateStart: string,
  orderCreatedDateEnd: string,
  location: string,
  e2eDaysLeft: number[],
  slaStatus: string
) =>
  request('hubm/sla/lead-time/sla-list/generate', {
    method: 'POST',
    body: {
      orderCreatedDateStart,
      orderCreatedDateEnd,
      location,
      e2eDaysLeft,
      slaStatus,
    },
  });

export const getSlaFeedData = (id: string) =>
  request(`hubm/sla/lead-time/sla-list?id=${id}`);
