import request from '../../utils/api';

export const bulkUpload = (body: object) => {
  return request('hubm/orders/bulk-repush/validate', { method: 'POST', body });
};

export const bulkPrint = (body: object) => {
  return request('hubm/waybills/generate/bulk', { method: 'POST', body });
};

export const bulkRepush = (body: object) => {
  return request('hubm/orders/bulk-repush/', { method: 'POST', body });
};
