import React from 'react';
import loadable from '@loadable/component';
import Loading from '../../commons/loading/index';

export default [
  {
    path: '/dashboard',
    exact: true,
    authL: true,
    component: loadable(() => import('./dashboard/index'), {
      fallback: <Loading />,
    }),
  },
];
