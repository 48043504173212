import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { deleteToken } from '../../utils/token';

const Maintenance: React.FC = () => {

  const envMaintenance = process.env.REACT_APP_SITE_MAINTENANCE === 'true';
  const currentPath = useLocation().pathname;
  const history = useHistory();

  if (envMaintenance && currentPath !== '/login') {
    history.push('/login');

    if (localStorage.getItem('entregoTKN') &&
      localStorage.getItem('entregoTKNRefresh')
    ) {
      deleteToken();
    }
  }

  return (
    <div className="maintenance hero is-fullheight">
      <div className="hero-body">
        <div className="container has-text-centered">
          <img
            src="images/maintenance.jpg"
            alt="Maintenance"
            className="ent-logo"
            width="300"
          />
          <div className="title is-1 mb-6">Whoops!</div>
          <div className="subtitle-maintenance">Service Temporarily Unavailable</div>
          <p className="has-text-link ">
            The server is temporarily unable to service your request due to
            maintenance
          </p>
          <div className="sub-text">
            downtime or capacity problems. Please try again later.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Maintenance;