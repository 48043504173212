import request from '../../utils/api';
import { DocumentRequestProps, EmployeeRequestProps } from './interface';

export const getClaimsParameters = () => request('claims/parameters');

export const validateClaimsPackage = (body: object) =>
  request('claims/package/validation', { method: 'POST', body });

export const createClaimsTicket = (body: object) =>
  request('claims?claim_type=internal', { method: 'POST', body });

export const addClaimsPackages = (body: object) =>
  request('claims/packages', { method: 'POST', body });

export const createClaimsDocuments = (body: DocumentRequestProps) =>
  request('claims/documents', { method: 'POST', body });

export const uploadDocuments = (body: object, url: string) => {
  return request('', { method: 'PUT', body }, '', url);
};

export const createClaimsEmployees = (body: EmployeeRequestProps) =>
  request('claims/employees', { method: 'POST', body });

export const updateClaimsStatus = (body: object) =>
  request('claims?claim_type=internal', { method: 'PATCH', body });

export const uploadMultipleTns = (body: object) => {
  return request('claims/package/bulk/validation', { method: 'POST', body });
};
