import { Reducer } from 'redux';
import { ToolState } from './interface';

export const actionTypes = {
  BULK_UPLOAD_REQUEST: 'BULK_UPLOAD_REQUEST',
  BULK_UPLOAD_SUCCESS: 'BULK_UPLOAD_SUCCESS',
  BULK_UPLOAD_FAILED: 'BULK_UPLOAD_FAILED',

  BULK_WAYBILL_PRINT_REQUEST: 'BULK_WAYBILL_PRINT_REQUEST',
  BULK_WAYBILL_PRINT_SUCCESS: 'BULK_WAYBILL_PRINT_SUCCESS',
  BULK_WAYBILL_PRINT_FAILED: 'BULK_WAYBILL_PRINT_FAILED',

  BULK_REPUSH_REQUEST: 'BULK_REPUSH_REQUEST',
  BULK_REPUSH_SUCCESS: 'BULK_REPUSH_SUCCESS',
  BULK_REPUSH_FAILED: 'BULK_REPUSH_FAILED',

  RESET_BULK_RESPONSE: 'RESET_BULK_RESPONSE',
  RESET_WAYBILL_PRINT_RESPONSE: 'RESET_WAYBILL_PRINT_RESPONSE',
  RESET_ACTION_TYPE: 'RESET_ACTION_TYPE',
};

export const actionCreators = {
  bulkUpload: (body: object) => ({
    type: actionTypes.BULK_UPLOAD_REQUEST,
    body,
  }),
  bulkPrint: (body: object) => ({
    type: actionTypes.BULK_WAYBILL_PRINT_REQUEST,
    body,
  }),
  bulkRepush: (body: object) => ({
    type: actionTypes.BULK_REPUSH_REQUEST,
    body,
  }),
  resetResponse: () => ({ type: actionTypes.RESET_BULK_RESPONSE }),

  resetWaybillResponse: 
  () => ({ type: actionTypes.RESET_WAYBILL_PRINT_RESPONSE }),

  resetActionType: () => ({ type: actionTypes.RESET_ACTION_TYPE }),
};

const initialState: ToolState = {
  isLoading: false,
  bulkResponse: {
    invalidCount: 0,
    totalCount: 0,
    validCount: 0,
    resultFileName: '',
    invalidTrackingNumbers: [],
  },
  waybillBulkResponse: {
    totalCount: 0,
    validCount: 0,
    invalidTrackingNumbersURL: '',
    bulkWaybillURL: '',
  },
  actionType: '',
};

const reducer: Reducer<ToolState> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.BULK_UPLOAD_REQUEST:
    case actionTypes.BULK_WAYBILL_PRINT_REQUEST:
    case actionTypes.BULK_REPUSH_REQUEST:
      return { ...state, isLoading: true };

    case actionTypes.BULK_UPLOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        bulkResponse: action.data,
      };
    case actionTypes.BULK_REPUSH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        actionType: action.type,
      };
    case actionTypes.BULK_UPLOAD_FAILED:
      return {
        ...state,
        isLoading: false,
        bulkResponse: initialState.bulkResponse,
      };
    case actionTypes.BULK_REPUSH_FAILED:
      return { ...state, isLoading: false };

    case actionTypes.RESET_BULK_RESPONSE:
      return {
        ...state,
        isLoading: false,
        bulkResponse: initialState.bulkResponse,
      };
    case actionTypes.RESET_WAYBILL_PRINT_RESPONSE:
      return {
        ...state,
        isLoading: false,
        waybillBulkResponse: initialState.waybillBulkResponse,
      };
    case actionTypes.RESET_ACTION_TYPE:
      return {
        ...state,
        actionType: '',
      };
    case actionTypes.BULK_WAYBILL_PRINT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        waybillBulkResponse: action.data,
      };
    case actionTypes.BULK_WAYBILL_PRINT_FAILED:
      return {
        ...state,
        isLoading: false,
        waybillBulkResponse: initialState.waybillBulkResponse,
      };
    default:
      return state;
  }
};

export default reducer;
