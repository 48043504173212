import { Reducer } from 'redux';
import { ConfigurationState } from './interface';

export const actionTypes = {
  GET_CONFIGURATION_REQUEST: 'GET_CONFIGURATION_REQUEST',
  GET_CONFIGURATION_SUCCESS: 'GET_CONFIGURATION_SUCCESS',
  GET_CONFIGURATION_FAILED: 'GET_CONFIGURATION_FAILED',

  UPDATE_CONFIGURATION_REQUEST: 'UPDATE_CONFIGURATION_REQUEST',
  UPDATE_CONFIGURATION_SUCCESS: 'UPDATE_CONFIGURATION_SUCCESS',
  UPDATE_CONFIGURATION_FAILED: 'UPDATE_CONFIGURATION_FAILED',

  GET_ALL_HUBS_REQUEST: 'GET_ALL_HUBS_REQUEST',
  GET_ALL_HUBS_SUCCESS: 'GET_ALL_HUBS_SUCCESS',
  GET_ALL_HUBS_FAILED: 'GET_ALL_HUBS_FAILED',
};

export const actionCreators = {
  getConfiguration: (serviceName: string) => ({
    type: actionTypes.GET_CONFIGURATION_REQUEST,
    serviceName,
  }),
  updateConfiguration: (body: object) => ({
    type: actionTypes.UPDATE_CONFIGURATION_REQUEST,
    body,
  }),
  getAllHubs: () => ({ type: actionTypes.GET_ALL_HUBS_REQUEST }),
};

const initialState: ConfigurationState = {
  isLoading: false,
  enabledPortCodeValidationList: [],
  hubList: {
    orderStatus: [],
    paymentMethods: [],
    hubs: [],
    clients: [],
  },
};

const reducer: Reducer<ConfigurationState> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CONFIGURATION_REQUEST:
    case actionTypes.UPDATE_CONFIGURATION_REQUEST:
    case actionTypes.GET_ALL_HUBS_REQUEST:
      return { ...state, isLoading: true };

    case actionTypes.GET_CONFIGURATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        actionType: action.type,
        enabledPortCodeValidationList: action.data.enablePortCodeValidation,
      };
    case actionTypes.GET_CONFIGURATION_FAILED:
      return { ...state, isLoading: false };

    case actionTypes.UPDATE_CONFIGURATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        actionType: action.type,
        enabledPortCodeValidationList: action.data.enablePortCodeValidation,
      };
    case actionTypes.UPDATE_CONFIGURATION_FAILED:
      return { ...state, isLoading: false };

    case actionTypes.GET_ALL_HUBS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        actionType: action.type,
        hubList: action.data,
      };
    case actionTypes.GET_ALL_HUBS_FAILED:
      return {
        ...state,
        isLoading: false,
        hubList: initialState.hubList,
      };

    default:
      return state;
  }
};

export default reducer;
