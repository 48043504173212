import React from 'react';
import {
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Button,
  SvgIcon,
} from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';
import { TransitionProps } from '@material-ui/core/transitions';

import { ModalProps } from './interface';

const Transition = React.forwardRef(function Transition(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Modal: React.FC<ModalProps> = ({
  open,
  content,
  footer,
  title,
  onClose,
  maxWidth,
  paperClass,
  showClose,
}) => {
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        maxWidth={maxWidth}
        fullWidth
        PaperProps={{
          className: paperClass || undefined,
        }}
        onClose={onClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        {showClose ? (
          <div className="pt-3 px-3">
            <Button onClick={() => onClose(false)}>
              <SvgIcon color="disabled" fontSize="large">
                <CloseOutlined />
              </SvgIcon>
            </Button>
          </div>
        ) : null}
        <DialogTitle disableTypography id="alert-dialog-slide-title">
          <Typography
            className="has-text-weight-bold has-text-navy-blue"
            variant="h5"
          >
            {title}
          </Typography>
        </DialogTitle>
        <DialogContent>{content}</DialogContent>
        <DialogActions>{footer}</DialogActions>
      </Dialog>
    </div>
  );
};

export default Modal;
