import { all } from 'redux-saga/effects';
import authSagas from '../modules/auth/saga';
import reportSagas from '../modules/reports/saga';
import orderSagas from '../modules/orders/saga';
import userSagas from '../modules/users/saga';
import toolSagas from '../modules/tools/saga';
import baggingSagas from '../modules/bagging/saga';
import configurationSagas from '../modules/configuration/saga';
import slaSagas from '../modules/slaleadtime/saga';
import slaExtensionSagas from '../modules/slaextension/saga';
import claimsSagas from '../modules/claims/saga';
import barangayClusterSaga from '../modules/barangay-cluster/saga';
import dashboardSaga from '../modules/hmsdashboard/saga';

export default function* rootSaga() {
  yield all([
    ...authSagas,
    ...orderSagas,
    ...reportSagas,
    ...userSagas,
    ...toolSagas,
    ...baggingSagas,
    ...configurationSagas,
    ...slaSagas,
    ...slaExtensionSagas,
    ...claimsSagas,
    ...barangayClusterSaga,
    ...dashboardSaga,
  ]);
}
