/* eslint-disable @typescript-eslint/no-explicit-any */
import { all, call, takeLatest, put } from 'redux-saga/effects';
import * as bulmaToast from 'bulma-toast';
import { BodySaga } from './interface';
import { actionTypes } from './reducer';
import { actionTypes as authActionTypes } from '../auth/reducer';
import {
  bulkRepush as bulkRepushService,
  bulkUpload as bulkUploadService,
  bulkPrint as bulkPrintService
} from './service';

function* bulkUpload({ body }: BodySaga): Generator<any> {
  try {
    const data = yield call(bulkUploadService, body);
    yield put({ type: actionTypes.BULK_UPLOAD_SUCCESS, data });
  } catch (err) {
    if (err.status === 403) {
      yield put({ type: authActionTypes.LOGOUT_SUCCESS });
      bulmaToast.toast({
        message: 'Authentication Failed!',
        type: 'is-danger',
        position: 'top-center',
        dismissible: true,
        duration: 10000,
        closeOnClick: true,
      });
    }
    bulmaToast.toast({
      message: err.message,
      type: 'is-danger',
      position: 'top-center',
      dismissible: true,
      duration: 10000,
      closeOnClick: true,
    });
    yield put({ type: actionTypes.BULK_UPLOAD_FAILED, err });
  }
}

function* bulkPrint({ body }: BodySaga): Generator<any> {
  try {
    const data = yield call(bulkPrintService, body);
    yield put({ type: actionTypes.BULK_WAYBILL_PRINT_SUCCESS, data });
  } catch (err) {
    if (err.status === 403) {
      yield put({ type: authActionTypes.LOGOUT_SUCCESS });
      bulmaToast.toast({
        message: 'Authentication Failed!',
        type: 'is-danger',
        position: 'top-center',
        dismissible: true,
        duration: 10000,
        closeOnClick: true,
      });
    }
    bulmaToast.toast({
      message: err.details,
      type: 'is-danger',
      position: 'top-center',
      dismissible: true,
      duration: 10000,
      closeOnClick: true,
    });
    yield put({ type: actionTypes.BULK_WAYBILL_PRINT_FAILED, err });
  }
}

function* bulkRepush({ body }: BodySaga): Generator<any> {
  try {
    const data = yield call(bulkRepushService, body);
    bulmaToast.toast({
      message: 'Successfully Repushed',
      type: 'is-success',
      position: 'top-center',
      dismissible: true,
      duration: 10000,
      closeOnClick: true,
    });
    yield put({ type: actionTypes.BULK_REPUSH_SUCCESS, data });
  } catch (err) {
    if (err.status === 403) {
      yield put({ type: authActionTypes.LOGOUT_SUCCESS });
      bulmaToast.toast({
        message: 'Authentication Failed!',
        type: 'is-danger',
        position: 'top-center',
        dismissible: true,
        duration: 10000,
        closeOnClick: true,
      });
    }
    bulmaToast.toast({
      message: err.message,
      type: 'is-danger',
      position: 'top-center',
      dismissible: true,
      duration: 10000,
      closeOnClick: true,
    });
    yield put({ type: actionTypes.BULK_REPUSH_FAILED, err });
  }
}

function* watchToolsRequest() {
  yield all([
    takeLatest(actionTypes.BULK_UPLOAD_REQUEST, bulkUpload),
    takeLatest(actionTypes.BULK_REPUSH_REQUEST, bulkRepush),
    takeLatest(actionTypes.BULK_WAYBILL_PRINT_REQUEST, bulkPrint),
  ]);
}

export default [watchToolsRequest()];
