import { Reducer } from 'redux';
import { UserState, UpdateUserProps, DeactivateUserProps } from './interface';

export const actionTypes = {
  GET_ALL_USERS_REQUEST: 'GET_ALL_USERS_REQUEST',
  GET_ALL_USERS_SUCCESS: 'GET_ALL_USERS_SUCCESS',
  GET_ALL_USERS_FAILED: 'GET_ALL_USERS_FAILED',

  GET_USERS_SEARCH_REQUEST: 'GET_USERS_SEARCH_REQUEST',
  GET_USERS_SEARCH_SUCCESS: 'GET_USERS_SEARCH_SUCCESS',
  GET_USERS_SEARCH_FAILED: 'GET_USERS_SEARCHS_FAILED',

  UPDATE_USER_REQUEST: 'UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILED: 'UPDATE_USER_FAILED',

  DEACTIVATE_USER_REQUEST: 'DEACTIVATE_USER_REQUEST',
  DEACTIVATE_USER_SUCCESS: 'DEACTIVATE_USER_SUCCESS',
  DEACTIVATE_USER_FAILED: 'DEACTIVATE_USER_FAILED',

  GET_ALL_PORTCODES_REQUEST: 'GET_ALL_PORTCODES_REQUEST',
  GET_ALL_PORTCODES_SUCCESS: 'GET_ALL_PORTCODES_SUCCESS',
  GET_ALL_PORTCODES_FAILED: 'GET_ALL_PORTCODES_FAILED',
};

export const actionCreators = {
  getAllUsers: (page: number, limit: number) => ({
    type: actionTypes.GET_ALL_USERS_REQUEST,
    page,
    limit,
  }),
  getUsersSearch: (page: number, limit: number, searchFilter: string) => ({
    type: actionTypes.GET_USERS_SEARCH_REQUEST,
    page,
    limit,
    searchFilter,
  }),
  updateUser: (body: UpdateUserProps, limit: number) => ({
    type: actionTypes.UPDATE_USER_REQUEST,
    body,
    limit,
  }),
  deactivateUser: (body: DeactivateUserProps) => ({
    type: actionTypes.DEACTIVATE_USER_REQUEST,
    body,
  }),
  getAllPortCodes: () => ({ type: actionTypes.GET_ALL_PORTCODES_REQUEST }),
};

const initialState: UserState = {
  isLoading: false,
  users: [],
  totalCount: 0,
  portCodeList: [],
};

const reducer: Reducer<UserState> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_USERS_REQUEST:
    case actionTypes.GET_USERS_SEARCH_REQUEST:
    case actionTypes.UPDATE_USER_REQUEST:
    case actionTypes.DEACTIVATE_USER_REQUEST:
    case actionTypes.GET_ALL_PORTCODES_REQUEST:
      return { ...state, isLoading: true };

    case actionTypes.GET_ALL_USERS_SUCCESS:
    case actionTypes.GET_USERS_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        users: action.data.users,
        totalCount: action.data.total,
      };
    case actionTypes.GET_ALL_USERS_FAILED:
    case actionTypes.GET_USERS_SEARCH_FAILED:
      return { ...state, isLoading: false, users: [] };

    case actionTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.UPDATE_USER_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case actionTypes.DEACTIVATE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.DEACTIVATE_USER_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case actionTypes.GET_ALL_PORTCODES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        actionType: action.type,
        portCodeList: action.data,
      };
    case actionTypes.GET_ALL_PORTCODES_FAILED:
      return {
        ...state,
        isLoading: false,
        portCodeList: initialState.portCodeList,
      };

    default:
      return state;
  }
};

export default reducer;
