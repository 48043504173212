import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { useLocation, Link } from 'react-router-dom';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Collapse, ListItem } from '@material-ui/core';
import Icon from '@mdi/react';
import {
  mdiViewDashboard,
  mdiEmailOutline,
  mdiTimerOutline,
  mdiArchive,
  mdiPuzzle,
  mdiClipboardText,
  mdiShopping,
  // mdiImport,
  mdiAccountSupervisorCircle,
  mdiBookOpen,
  mdiWrench,
  mdiCog,
} from '@mdi/js';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';
import MomentUtils from '@date-io/moment';
import theme from '../styles/theme/customTheme';
import Header from '../commons/header';
import Loading from '../commons/loading/index';
import { validateRole } from '../utils/rolevalidation';
import { ApplicationState } from '../store/reducers';
import { RouteProps } from '../routes/interface';
import routes from '../routes/routes';
import { actionCreators as ordersActionCreators } from './orders/reducer';
import { actionCreators as reportsActionCreators } from './reports/reducer';
import { actionCreators as slaLeadtimeActionCreators } from './slaleadtime/reducer';
import { actionCreators as authActionCreators } from './auth/reducer';
import { AppProps } from './interface';
import { useCheckVersion } from '../utils/useCheckVersion';

const App: React.FunctionComponent<AppProps> = ({
  resetOrders,
  getAllFilters,
  getReportTypes,
  isLoading,
  profile,
  children,
  resetSlaData,
  navbar,
  handleNavbar,
}) => {
  const { authorities, hubmRole } = profile;
  const receiverRole = hubmRole === 'Receiver';
  const isDFA = hubmRole === 'DFA';
  const currentPath = useLocation().pathname;
  const isLogin = currentPath === '/login';
  const [openTasks, setOpenTasks] = useState(
    currentPath === '/bagging' ||
      currentPath === '/barangay' ||
      currentPath === '/claims'
  );
  const [openSla, setOpenSla] = useState(
    currentPath === '/slaleadtime' || currentPath === '/slaextension'
  );

  useCheckVersion(currentPath);

  const handleOpenTasks = () => {
    setOpenTasks(!openTasks);
  };
  const handleOpenSla = () => {
    setOpenSla(!openSla);
  };

  const getAllowedRoles = (path: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const fetchedRoute: RouteProps | any = routes.find((val) => {
      return val.path === path;
    });

    return fetchedRoute?.role;
  };

  useEffect(() => {
    if (currentPath === '/orders') {
      resetOrders();
      !isDFA && getAllFilters();
    } else if (currentPath === '/reports') {
      getReportTypes();
    } else if (currentPath === '/slaleadtime') {
      resetSlaData();
    }
    // handleNavbar(true);
  }, [
    currentPath,
    getAllFilters,
    getReportTypes,
    handleNavbar,
    resetOrders,
    resetSlaData,
    isDFA,
  ]);

  const componentList = {
    icon: 'svg-barangay-icon',
  };

  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#005196',
      color: 'white',
      boxShadow: theme.shadows[1],
      borderRadius: '10px',
      fontSize: 15,
      position: 'relative',
      left: '30px',
      top: '-80px',
    },
  }))(Tooltip);

  return (
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <div className="is-fullheight">
          {!isLogin ? <Header /> : null}
          <div className={!isLogin ? 'body-main' : ''}>
            {!isLogin ? (
              <aside
                className={`${navbar ? '' : ''} menu`}
                style={navbar ? { minWidth: '17rem' } : { minWidth: '7rem' }}
              >
                {process.env.REACT_APP_ENV === 'staging' ? (
                  <div className="mt-5 has-text-centered has-text-red has-text-weight-bold">
                    {navbar ? 'TEST ENVIRONMENT' : ''}
                  </div>
                ) : null}
                <ul
                  className="menu-list mt-5"
                  style={navbar ? { lineHeight: 3.5 } : { lineHeight: 5.0 }}
                >
                  {validateRole(authorities, getAllowedRoles('/dashboard')) ? (
                    <li>
                      <Link id="test-menu-dashboard" to="/dashboard">
                        <div
                          className={
                            currentPath === '/dashboard' ? 'is-active' : 'is-inactive'
                          }
                        >
                          <LightTooltip title={navbar ? '' : 'Dashboard'}>
                            <span>
                              <Icon
                                path={mdiViewDashboard}
                                size={navbar ? 0.8 : 1.2}
                                style={{ position: 'relative', top: '3px' }}
                              />
                            </span>
                          </LightTooltip>
                          <span className={`${navbar ? '' : 'is-hidden'}`}>
                            Dashboard
                          </span>
                        </div>
                      </Link>
                    </li>
                  ) : null}
                  {validateRole(authorities, getAllowedRoles('/orders')) ? (
                    <li>
                      <Link id="test-menu-orders" to="/orders">
                        <div
                          className={
                            currentPath === '/orders' ? 'is-active' : 'is-inactive'
                          }
                        >
                          {/* <i className="far fa-envelope" /> */}
                          <LightTooltip title={navbar ? '' : 'Orders'}>
                            <span>
                              <Icon
                                path={mdiEmailOutline}
                                size={navbar ? 0.8 : 1.2}
                                style={{ position: 'relative', top: '3px' }}
                              />
                            </span>
                          </LightTooltip>
                          <span className={`${navbar ? '' : 'is-hidden'}`}>
                            Orders
                          </span>
                        </div>
                      </Link>
                    </li>
                  ) : null}
                  {validateRole(authorities, getAllowedRoles('/slaleadtime')) ||
                  validateRole(
                    authorities,
                    getAllowedRoles('/slaextension')
                  ) ? (
                    <li>
                      <ListItem
                        id="test-menu-sla-leadtime"
                        button
                        onClick={handleOpenSla}
                      >
                        <div
                          className={
                            currentPath === '/slaleadtime' ||
                            currentPath === '/slaextension'
                              ? 'is-active'
                              : ''
                          }
                        >
                          {/* <Timer /> */}
                          <LightTooltip title={navbar ? '' : 'SLA Leadtime'}>
                            <span>
                              <Icon
                                path={mdiTimerOutline}
                                size={navbar ? 0.8 : 1.2}
                                style={{ position: 'relative', top: '3px' }}
                              />
                            </span>
                          </LightTooltip>
                          <span className={`${navbar ? '' : 'is-hidden'}`}>
                            SLA Leadtime
                          </span>
                          {openSla ? (
                            <ArrowDropUp className="expand-icon" />
                          ) : (
                            <ArrowDropDown className="expand-icon" />
                          )}
                        </div>
                      </ListItem>
                      <Collapse in={openSla} timeout="auto" unmountOnExit>
                        <ul>
                          {validateRole(
                            authorities,
                            getAllowedRoles('/slaleadtime')
                          ) ? (
                            <li>
                              <Link
                                id="test-menu-sla-packages"
                                to="/slaleadtime"
                              >
                                <div
                                  className={
                                    currentPath === '/slaleadtime'
                                      ? 'is-active'
                                      : ''
                                  }
                                >
                                  {/* <i className="fas fa-archive"
                                   style={{}} /> */}
                                  <LightTooltip
                                    title={navbar ? '' : 'SLA Packages'}
                                  >
                                    <span>
                                      <Icon
                                        path={mdiArchive}
                                        size={navbar ? 0.8 : 1.0}
                                        style={{
                                          position: 'relative',
                                          top: '3px',
                                        }}
                                      />
                                    </span>
                                  </LightTooltip>
                                  <span
                                    className={`${navbar ? '' : 'is-hidden'}`}
                                  >
                                    SLA Packages
                                  </span>
                                </div>
                              </Link>
                            </li>
                            ) : null}
                          {validateRole(
                            authorities,
                            getAllowedRoles('/slaextension')
                          ) ? (
                            <li>
                              <Link id="test-menu-sla-ext" to="/slaextension">
                                <div
                                  className={
                                    currentPath === '/slaextension'
                                      ? 'is-active'
                                      : ''
                                  }
                                >
                                  {/* <Extension /> */}
                                  <LightTooltip
                                    title={navbar ? '' : 'SLA Extensions'}
                                  >
                                    <span>
                                      <Icon
                                        path={mdiPuzzle}
                                        size={navbar ? 0.8 : 1.0}
                                        style={{
                                          position: 'relative',
                                          top: '3px',
                                        }}
                                      />
                                    </span>
                                  </LightTooltip>
                                  <span
                                    className={`${navbar ? '' : 'is-hidden'}`}
                                  >
                                    SLA Extensions
                                  </span>
                                </div>
                              </Link>
                            </li>
                            ) : null}
                        </ul>
                      </Collapse>
                    </li>
                    ) : null}
                  {validateRole(authorities, getAllowedRoles('/bagging')) ? (
                    <li>
                      <ListItem
                        id="test-menu-my-task"
                        button
                        onClick={handleOpenTasks}
                      >
                        <div
                          className={
                            currentPath === '/bagging' ||
                            currentPath === '/claims' ||
                            currentPath === '/barangay'
                              ? 'is-active'
                              : ''
                          }
                        >
                          {/* <Assignment /> */}
                          <LightTooltip title={navbar ? '' : 'My Task'}>
                            <span>
                              <Icon
                                path={mdiClipboardText}
                                size={navbar ? 0.8 : 1.2}
                                style={{ position: 'relative', top: '3px' }}
                              />
                            </span>
                          </LightTooltip>
                          <span className={`${navbar ? '' : 'is-hidden'}`}>
                            My Task
                          </span>
                          {openTasks ? (
                            <ArrowDropUp className="expand-icon" />
                          ) : (
                            <ArrowDropDown className="expand-icon" />
                          )}
                        </div>
                      </ListItem>
                      <Collapse in={openTasks} timeout="auto" unmountOnExit>
                        <ul>
                          {validateRole(
                            authorities,
                            getAllowedRoles('/bagging')
                          ) ? (
                            <li
                              className={`${receiverRole ? 'is-hidden' : ''}`}
                            >
                              <Link
                                id="test-menu-barangay-cluster"
                                to="/barangay"
                              >
                                <div
                                  className={
                                    currentPath === '/barangay'
                                      ? 'is-active'
                                      : 'pl-0 ml-0'
                                  }
                                >
                                  <LightTooltip
                                    title={navbar ? '' : 'Barangay Cluster'}
                                  >
                                    <span className={`${componentList.icon}`}>
                                      <span style={{ paddingLeft: '30px' }}>
                                        {navbar ? 'Barangay Cluster' : ''}
                                      </span>
                                    </span>
                                  </LightTooltip>
                                </div>
                              </Link>
                            </li>
                            ) : null}
                          {validateRole(
                            authorities,
                            getAllowedRoles('/bagging')
                          ) ? (
                            <li>
                              <Link id="test-menu-bagging" to="/bagging">
                                <div
                                  className={
                                    currentPath === '/bagging'
                                      ? 'is-active'
                                      : ''
                                  }
                                >
                                  {/* <LocalMall /> */}
                                  <LightTooltip title={navbar ? '' : 'Bagging'}>
                                    <span>
                                      <Icon
                                        path={mdiShopping}
                                        size={navbar ? 0.8 : 1.0}
                                        style={{
                                          position: 'relative',
                                          top: '3px',
                                        }}
                                      />
                                    </span>
                                  </LightTooltip>
                                  <span
                                    className={`${navbar ? '' : 'is-hidden'}`}
                                  >
                                    <span>Bagging</span>
                                  </span>
                                </div>
                              </Link>
                            </li>
                            ) : null}
                        </ul>
                      </Collapse>
                    </li>
                  ) : null}
                  {validateRole(authorities, getAllowedRoles('/users')) ? (
                    <li>
                      <Link id="test-menu-users" to="/users">
                        <div
                          className={
                            currentPath === '/users' ? 'is-active' : 'is-inactive'
                          }
                        >
                          {/* <i className="far fa-user" /> */}
                          <LightTooltip title={navbar ? '' : 'Users'}>
                            <span>
                              <Icon
                                path={mdiAccountSupervisorCircle}
                                size={navbar ? 0.8 : 1.2}
                                style={{ position: 'relative', top: '3px' }}
                              />
                            </span>
                          </LightTooltip>
                          <span className={`${navbar ? '' : 'is-hidden'}`}>
                            Users
                          </span>
                        </div>
                      </Link>
                    </li>
                  ) : null}
                  {validateRole(authorities, getAllowedRoles('/reports')) ? (
                    <li>
                      <Link id="test-menu-reports" to="/reports">
                        <div
                          className={
                            currentPath === '/reports' ? 'is-active' : 'is-inactive'
                          }
                        >
                          {/* <i className="fas fa-file-alt" /> */}
                          <LightTooltip title={navbar ? '' : 'Reports'}>
                            <span>
                              <Icon
                                path={mdiBookOpen}
                                size={navbar ? 0.8 : 1.2}
                                style={{ position: 'relative', top: '3px' }}
                              />
                            </span>
                          </LightTooltip>
                          <span className={`${navbar ? '' : 'is-hidden'}`}>
                            Reports
                          </span>
                        </div>
                      </Link>
                    </li>
                  ) : null}
                  {validateRole(
                    authorities,
                    getAllowedRoles('/configuration')
                  ) ? (
                    <li>
                      <Link id="test-menu-config" to="/configuration">
                        <div
                          className={
                            currentPath === '/configuration' ? 'is-active' : 'is-inactive'
                          }
                        >
                          {/* <Build /> */}
                          <LightTooltip title={navbar ? '' : 'Configuration'}>
                            <span>
                              <Icon
                                path={mdiWrench}
                                size={navbar ? 0.8 : 1.2}
                                style={{ position: 'relative', top: '3px' }}
                              />
                            </span>
                          </LightTooltip>
                          <span className={`${navbar ? '' : 'is-hidden'}`}>
                            Configuration
                          </span>
                        </div>
                      </Link>
                    </li>
                    ) : null}
                  {validateRole(authorities, getAllowedRoles('/tools')) ? (
                    <li>
                      <Link id="test-menu-tools" to="/tools">
                        <div
                          className={
                            currentPath === '/tools' ? 'is-active' : 'is-inactive'
                          }
                        >
                          {/* <i className="fas fa-cog" /> */}
                          <LightTooltip title={navbar ? '' : 'Tools'}>
                            <span>
                              <Icon
                                path={mdiCog}
                                size={navbar ? 0.8 : 1.2}
                                style={{ position: 'relative', top: '3px' }}
                              />
                            </span>
                          </LightTooltip>
                          <span className={`${navbar ? '' : 'is-hidden'}`}>
                            Tools
                          </span>
                        </div>
                      </Link>
                    </li>
                  ) : null}
                </ul>
              </aside>
            ) : null}
            {children}
            {isLoading ? <Loading /> : null}
          </div>
        </div>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  isLoading:
    state.orders.isLoading || state.reports.isLoading || state.users.isLoading,
  profile: state.auth.profile,
  navbar: state.auth.navbar,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators(ordersActionCreators, dispatch),
  ...bindActionCreators(reportsActionCreators, dispatch),
  ...bindActionCreators(slaLeadtimeActionCreators, dispatch),
  ...bindActionCreators(authActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
