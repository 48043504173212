export const MuiTextField = {
  root: {
    '&.mui-text-field-autocomplete': {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: '#FFFFFF',
      fontSize: 16,
      padding: '4px 15px 4px 12px',
      boxShadow: '0px 3px 6px #00000024',
    },
    '&.mui-text-field-autocomplete .MuiInput-underline': {
      '&::before': {
        border: '0px',
      },
      '&::after': {
        border: '0px',
      },
    },
    '&.mui-text-field-autocomplete .MuiInput-underline .MuiChip-root': {
      color: '#FFFFFF',
      backgroundColor: '#1D4982',
    },
    '&.mui-text-field-autocomplete .MuiInput-underline .MuiChip-root .MuiChip-deleteIcon': {
      color: '#FFFFFF',
      backgroundColor: '#1D4982',
    },
    '&.mui-text-field-autocomplete .MuiAutocomplete-endAdornment .MuiIconButton-root': {
      color: '#1D4982',
    },
  },
};
