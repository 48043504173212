/* eslint-disable @typescript-eslint/no-explicit-any */
import { all, call, takeLatest, put, delay } from 'redux-saga/effects';
import * as bulmaToast from 'bulma-toast';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { actionTypes } from './reducer';
import {
  getReportTypes as getReportTypesService,
  getReportFilters as getReportFiltersService,
  generateReport as generateReportService,
  pollReport as pollReportService,
  generateWaybillReport as generateWaybillReportService,
} from './service';
import {
  ReportFilterParams,
  GenerateReportParams,
  PollReportParams,
} from './interface';
import { dateFormat } from '../../utils/constants';

function* getReportTypes(): Generator<any> {
  try {
    const data = yield call(getReportTypesService);
    yield put({ type: actionTypes.GET_REPORT_TYPES_SUCCESS, data });
  } catch (err) {
    yield put({ type: actionTypes.GET_REPORT_TYPES_FAILED, err });
  }
}

function* getReportFilters({ reportType }: ReportFilterParams): Generator<any> {
  try {
    const data = yield call(getReportFiltersService, reportType);
    yield put({ type: actionTypes.GET_REPORT_FILTERS_SUCCESS, data });
  } catch (err) {
    yield put({ type: actionTypes.GET_REPORT_FILTERS_FAILED, err });
  }
}

function* generateWaybillReport({
  filterData,
}: GenerateReportParams): Generator<any> {
  try {
    const data: any = yield call(generateWaybillReportService, filterData);
    const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, `Report_${moment().format(dateFormat.YYYYMMDDHHmmss)}.csv`);
    yield put({ type: actionTypes.GENERATE_WAYBILL_REPORT_SUCCESS, data });
  } catch (err) {
    yield put({ type: actionTypes.GENERATE_WAYBILL_REPORT_FAILED, err });
  }
}

function* generateReport({ filterData }: GenerateReportParams): Generator<any> {
  try {
    const data: any = yield call(generateReportService, filterData);
    const removeDoubleQuotes = data.replace(/['"]+/g, '');
    bulmaToast.toast({
      message: 'Generating report please wait for a moment.',
      type: 'is-warning',
      position: 'bottom-right',
      duration: 5000,
      dismissible: true,
      closeOnClick: true,
    });
    yield put({
      type: actionTypes.POLL_REPORT_REQUEST,
      id: removeDoubleQuotes,
    });
    yield put({ type: actionTypes.GENERATE_REPORT_SUCCESS, data });
  } catch (err) {
    yield put({ type: actionTypes.GENERATE_REPORT_FAILED, err });
  }
}

function* pollReport({ id }: PollReportParams): Generator<any> {
  try {
    const data: any = yield call(pollReportService, id);
    const { status = '', downloadURL = '' } = data;
    if (status === 'IN_PROGRESS') {
      yield delay(60000);
      bulmaToast.toast({
        message: 'Generating report please wait for a moment.',
        type: 'is-warning',
        position: 'bottom-right',
        duration: 5000,
        dismissible: true,
        closeOnClick: true,
      });
      yield put({ type: actionTypes.POLL_REPORT_REQUEST, id });
    } else {
      window.open(downloadURL, '_blank');
      yield put({ type: actionTypes.POLL_REPORT_SUCCESS, data });
    }
  } catch (err) {
    yield put({ type: actionTypes.POLL_REPORT_FAILED, err });
  }
}

function* watchReportsRequest() {
  yield all([
    takeLatest(actionTypes.GET_REPORT_TYPES_REQUEST, getReportTypes),
    takeLatest(actionTypes.GET_REPORT_FILTERS_REQUEST, getReportFilters),
    takeLatest(actionTypes.GENERATE_REPORT_REQUEST, generateReport),
    takeLatest(
      actionTypes.GENERATE_WAYBILL_REPORT_REQUEST,
      generateWaybillReport
    ),
    takeLatest(actionTypes.POLL_REPORT_REQUEST, pollReport),
  ]);
}

export default [watchReportsRequest()];
