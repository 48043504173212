/* eslint-disable @typescript-eslint/indent */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import {
  makeStyles,
  Tooltip,
  Divider,
  Typography,
  Avatar,
  InputBase,
  Theme,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import SearchIcon from '@material-ui/icons/Search';
import { mdiTextLong } from '@mdi/js';
import Icon from '@mdi/react';
// import { MenuOutlined } from '@material-ui/icons';
import { actionCreators as authActionCreators } from '../../modules/auth/reducer';
import { actionCreators as ordersActionCreators } from '../../modules/orders/reducer';
import { ApplicationState } from '../../store/reducers';
import { HeaderMainProps } from './interface';
import { dateFormat, system } from '../../utils/constants';
import {
  isTrackingNumber,
  isValidRefNumber,
  isWaybillTrackingNumber,
  isValidSearchChar,
} from '../../utils/trackingNumber';
import Modal from '../modal';
import './burgerMenu';
import Loading from '../loading';
import { ButtonWithCheckVersion } from '../buttonWithCheckVersion';

const HeaderMain: React.FC<HeaderMainProps> = ({
  isLoading,
  isAuthenticated,
  logout,
  profile,
  setSearchReference,
  searchRefList,
  keyRefValue,
  handleNavbar,
  navbar,
  getProfile,
}) => {
  const errorTooltipClasses = errorTooltip();
  const infoTooltipClasses = infoTooltip();
  const menuItemClasses = menuItemStyle();
  const currentPath = useLocation().pathname;

  const {
    lastLogin,
    firstName,
    email,
    lastName,
    profilePicture,
    hubmRole,
    assignedLocations,
    userId,
  } = profile;
  const [openModal, setOpenModal] = useState(false);
  const [openSearchGuide, setOpenSearchGuide] = useState(false);
  const [refNumberFilter, setRefNumberFilter] = useState(
    !searchRefList || searchRefList[0] === system.EMPTY_CODE
      ? ''
      : searchRefList.join('    ')
  );
  const refNumberFilterRef = useRef(refNumberFilter);
  const error = !isValidSearchChar(refNumberFilterRef.current);
  const [key, setKey] = React.useState(
    keyRefValue === '' ? 'Search Tracking Number' : keyRefValue
  );
  const keyRef = useRef(key);
  const handleChange = (event: any) => {    // eslint-disable-line
    keyRef.current = event.target.value;
    setKey(keyRef.current);
    keyRefValue = keyRef.current;
  };
  const handleTrackingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    refNumberFilterRef.current = event.target.value;
    setRefNumberFilter(refNumberFilterRef.current);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleEnter = () => {
    const fieldValue = refNumberFilterRef.current;
    const selectedKey = keyRef.current;
    
    if (isValidSearchChar(fieldValue)) {
      const searchTempList = fieldValue.split(/\s+/);
      let searchList: string[] = [];
      if (selectedKey === 'Search Booking Number') {
        if (searchTempList.length === 1) {
          if (isValidRefNumber(searchTempList[0])) {
            searchList.push(searchTempList[0]);
          }
        } else {
          searchList = searchTempList.filter((val): val is string => {
            return isTrackingNumber(val) || isWaybillTrackingNumber(val);
          });
        }
      }
      else if (selectedKey === 'Search Tracking Number') {
        if (searchTempList.length === 1) {
          if (isValidRefNumber(searchTempList[0])) {
            searchList.push(searchTempList[0]);
          }
        } else {
          searchList = searchTempList.filter((val): val is string => {
            return isTrackingNumber(val) || isWaybillTrackingNumber(val);
          });
        }
      } else {
        searchList = searchTempList;
      }
      
      setRefNumberFilter(searchList.join('    '));
      setSearchReference(searchList, selectedKey);
      // setKeyReference(key);
    } else if (fieldValue === '') {
      // this is to force empty search or reset to view
      setSearchReference([system.EMPTY_CODE], selectedKey);
      // setKeyReference(key);
    }
  };

  useEffect(() => {
    if (searchRefList?.length === 0) setRefNumberFilter('');
  }, [searchRefList, keyRefValue]);

  // handle Enter key press
  const keyFunction = (event: { keyCode: number }) => {
    if (event.keyCode === 13) {
      handleEnter();
    }
  };
  useEffect(() => {
    // on mount
    document.addEventListener('keydown', keyFunction, false);
    // on unmount
    return () => {
      document.removeEventListener('keydown', keyFunction, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchTooltipText = () => {
    return keyRef.current === 'Search Booking Number' ||  keyRef.current === 'Search Tracking Number' ? (
      <div>
        <span style={{ marginRight: '10px' }}>
          - Single Booking Tracking Number
        </span>
        <span>
          - Single Partial Booking/Tracking Number: at least 1 character after
          prefix (e.g., 0001-A) -Multiple search: whole Tracking Numbers
        </span>
      </div>
    ) : (
      <div>
        <span style={{ marginRight: '14px' }}>
          - Single Client Reference Number
        </span>
        <span>
          - Multiple Search: whole Tracking Numbers and Client Reference Number
        </span>
        
      </div>
    );
  };

  return (
    <div className="header">
      {isLoading ? <Loading /> : null}
      <nav
        className="navbar is-primary"
        role="navigation"
        aria-label="main navigation"
      >
        <div
          className={`${navbar ? '' : 'reverse-color'} shadow navbar-brand`}
          style={navbar ? { minWidth: '17rem' } : { minWidth: '7rem' }}
        >
          <span className={`${navbar ? '' : 'is-hidden'} brand-logo`}>
            <img
              src="images/AHUBM.jpeg"
              alt="Air21 HMS logo"
              className="ent-logo"
            />
          </span>
          <span className={`${navbar ? 'is-hidden' : ''} brand-logo`}>
            <img
              src="images/air21-logo.svg"
              alt="Air21 HMS logo"
              className="ent-logo-collapsed"
            />
          </span>
        </div>
        <div id="navbarBasicExample" className="navbar-menu">
          <div className="navbar-start" style={{ marginLeft: '1em' }}>
            <ButtonWithCheckVersion id="test-menu-button" onClick={() => handleNavbar(!navbar)}>
              {/* <MenuOutlined /> */}
              <Icon path={mdiTextLong} size={1.2} />
            </ButtonWithCheckVersion>
            <div className="navbar-item">
              {currentPath === '/orders' ? (
                <div id="test-orders-search-field" className="textfield-search">
                  <Select
                    variant="outlined"
                    style={{ fontSize: 'small', height: '47px' }}
                    className={menuItemClasses.itemSelect}
                    value={key}
                    onChange={handleChange}
                    startAdornment={
                      <SearchIcon style={{ paddingLeft: '0px' }} />
                    }
                  >
                    <MenuItem
                      value="Search Tracking Number"
                      className={menuItemClasses.item}
                    >
                      Tracking Number
                    </MenuItem>
                    <MenuItem
                      value="Search Booking Number"
                      className={menuItemClasses.item}
                    >
                      Booking Number
                    </MenuItem>
                  </Select>
                  
                  <Tooltip
                    open={!!refNumberFilter && error}
                    title={system.INVALID_CHAR}
                    classes={errorTooltipClasses}
                    arrow
                  >
                    <TextField
                      style={{ marginLeft: '-5px', width: '340px' }}
                      variant="outlined"
                      placeholder="Ex. AA-ONNR7RL2T8JOAP0"
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        handleTrackingChange(event);
                      }}
                      onSelect={() => {
                        if (refNumberFilter === '') setOpenSearchGuide(true);
                        else setOpenSearchGuide(false);
                      }}
                      onBlur={() => {
                        setOpenSearchGuide(false);
                      }}
                      value={refNumberFilter}
                      InputProps={{
                        style: { fontSize: 'small', height: '47px' },
                        startAdornment: (
                          <Tooltip
                            open={openSearchGuide}
                            title={
                              <div className="has-text-navy-blue">
                                <div className="has-text-orange">
                                  <InfoIcon
                                    fontSize="small"
                                    style={{
                                      marginRight: '7px',
                                      verticalAlign: 'middle',
                                    }}
                                  />
                                  <span>
                                    The search function can handle the
                                    following:
                                  </span>
                                </div>
                                <div style={{ marginLeft: '10px' }}>
                                  {searchTooltipText()}
                                </div>
                              </div>
                            }
                            classes={infoTooltipClasses}
                            placement="bottom-start"
                            arrow
                          >
                            <InputAdornment
                              position="start"
                              style={{ marginLeft: 0, marginRight: 0 }}
                            >
                              {openSearchGuide || !!refNumberFilter ? (
                                <div className="is-hidden" /> // InputAdornment requires children component
                              ) : (
                                ''
                              )}
                            </InputAdornment>
                          </Tooltip>
                        ),
                      }}
                    />
                  </Tooltip>
                </div>
              ) : null}
            </div>
          </div>
          <div className="navbar-end">
            <div className="navbar-item">
              <div
                id="test-nav-baseloc"
                className="textfield-location is-size-7"
              >
                <span className="has-text-navy-blue has-text-weight-bold mr-2">
                  Base Location
                </span>
                {assignedLocations?.baseLocation
                  ? assignedLocations.baseLocation
                  : 'N/A'}
              </div>
            </div>
            <div className="navbar-item has-dropdown is-hoverable">
              <a id="test-hello-dropdown" className="navbar-link">
                <span>{isAuthenticated ? `Hello ${firstName}!` : null}</span>
                <i className="fas fa-chevron-down ml-2 mr-1" />
                <Avatar
                  style={{ height: '34px', width: '34px' }}
                  src={profilePicture}
                />
              </a>
              <div className="navbar-dropdown">
                <a
                  id="test-nav-profile"
                  role="button"
                  className="navbar-item profile"
                  tabIndex={0}
                  onClick={() => {
                    setOpenModal(true);
                    getProfile(userId || '');
                  }}
                >
                  <i className="fas fa-user" />
                  <span>Profile</span>
                </a>
                <a
                  id="test-nav-logout"
                  role="button"
                  className="navbar-item"
                  tabIndex={0}
                  onClick={() => {
                    logout();
                  }}
                >
                  <i className="fas fa-power-off" />
                  <span>Logout</span>
                </a>
                <div className="last-login">
                  Last logged in{' '}
                  {moment(lastLogin).format(dateFormat.DMMMMYYYY_HmmssZ)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <Modal
        open={openModal}
        maxWidth="sm"
        paperClass="h-80"
        onClose={handleCloseModal}
        title="User Profile"
        content={
          <div className="mx-5 my-5 user-profile-modal">
            <div className="columns is-multiline">
              <div className="column is-5">
                <Avatar
                  style={{ height: '150px', width: '150px' }}
                  src={profilePicture}
                />
              </div>
              <div className="column is-7">
                <Typography
                  variant="h6"
                  className="pt-5 has-text-weight-bold has-text-navy-blue is-size-5"
                  id="test-nav-usr-pro-full"
                >
                  {`${firstName} ${lastName}`}
                </Typography>
                <Typography
                  variant="h6"
                  className="has-text-weight-bold has-text-dark-gray is-size-6"
                  id="test-nav-usr-pro-role-sub"
                >
                  {`${hubmRole || 'N/A'}`}
                </Typography>
                <Typography
                  variant="body1"
                  className="has-text-navy-blue is-size-7"
                >
                  {`Last logged in ${moment(lastLogin).format(
                    dateFormat.DMMMMYYYY_HmmssZ
                  )}`}
                </Typography>
              </div>
            </div>
            <div className="columns is-multiline">
              <div className="column is-12">
                <Typography
                  variant="subtitle1"
                  className="has-text-weight-bold has-text-navy-blue"
                >
                  Account Information
                </Typography>
              </div>
              <div className="column is-4">
                <Typography
                  variant="body1"
                  className="py-2 has-text-weight-bold has-text-navy-blue"
                >
                  First Name
                </Typography>
              </div>
              <div className="column is-8">
                <InputBase
                  value={firstName || 'N/A'}
                  fullWidth
                  disabled
                  className="has-text-navy-blue"
                  id="test-nav-usr-pro-fn"
                />
              </div>
            </div>
            <div className="columns is-multiline">
              <div className="column is-4">
                <Typography
                  variant="body1"
                  className="py-2 has-text-weight-bold has-text-navy-blue"
                >
                  Last Name
                </Typography>
              </div>
              <div className="column is-8">
                <InputBase
                  value={lastName || 'N/A'}
                  fullWidth
                  disabled
                  className="has-text-navy-blue"
                  id="test-nav-usr-pro-ln"
                />
              </div>
            </div>
            <div className="columns is-multiline">
              <div className="column is-4">
                <Typography
                  variant="body1"
                  className="py-2 has-text-weight-bold has-text-navy-blue"
                >
                  Email
                </Typography>
              </div>
              <div className="column is-8">
                <InputBase
                  value={email || 'N/A'}
                  fullWidth
                  disabled
                  className="has-text-navy-blue"
                  id="test-nav-usr-pro-email"
                />
              </div>
            </div>
            <div className="columns is-multiline">
              <div className="column is-4">
                <Typography
                  variant="body1"
                  className="py-2 has-text-weight-bold has-text-navy-blue"
                >
                  Role
                </Typography>
              </div>
              <div className="column is-8">
                <InputBase
                  value={hubmRole || 'N/A'}
                  fullWidth
                  disabled
                  className="has-text-navy-blue"
                  id="test-nav-usr-pro-role"
                />
              </div>
            </div>
            <div className="columns is-multiline">
              <div className="column is-4">
                <Typography
                  variant="body1"
                  className="py-2 has-text-weight-bold has-text-navy-blue"
                >
                  Base Location
                </Typography>
              </div>
              <div className="column is-8">
                <InputBase
                  value={assignedLocations?.baseLocation || 'N/A'}
                  fullWidth
                  disabled
                  className="has-text-navy-blue"
                  id="test-nav-usr-pro-base-loc"
                />
              </div>
            </div>
            <div className="columns is-multiline">
              <div className="column is-4" />
              <div className="column is-8">
                <Divider className="mb-4" />
                <ButtonWithCheckVersion
                  onClick={handleCloseModal}
                  variant="outlined"
                  fullWidth
                  color="primary"
                  id="test-nav-usr-pro-close"
                >
                  CLOSE
                </ButtonWithCheckVersion>
              </div>
            </div>
          </div>
        }
        footer={<></>}
      />
    </div>
  );
};

const errorTooltip = makeStyles(() => ({
  arrow: {
    color: 'red',
  },
  tooltip: {
    backgroundColor: 'red',
    fontSize: 'small',
  },
}));
const infoTooltip = makeStyles((theme: Theme) => ({
  arrow: {
    color: 'white',
  },
  tooltip: {
    backgroundColor: 'white',
    fontSize: 'small',
    boxShadow: theme.shadows[5],
    borderRadius: '0',
    padding: '1em',
    marginTop: '2.5em',
    marginLeft: '1em',
    maxWidth: 800,
    fontWeight: 600,
    lineHeight: 2,
  },
}));

const menuItemStyle = makeStyles((theme: Theme) => ({
  item: {
    '& span, & svg, & div': {
      fontSize: 'small',
    },
  },
  itemSelect: {
    '& .MuiSelect-select': {
      boxShadow: 'none',
      fontWeight: 'bold',
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: '#FFFFFF',
      color: '#1D4982',
    },
  },
}));

const mapStateToProps = (state: ApplicationState) => ({
  isLoading: state.auth.isLoading,
  isAuthenticated: state.auth.isAuthenticated,
  profile: state.auth.profile,
  error: state.auth.error,
  searchRefList: state.orders.searchRefList,
  keyRefValue: state.orders.keyRefValue,
  navbar: state.auth.navbar,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators(authActionCreators, dispatch),
  ...bindActionCreators(ordersActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderMain);
