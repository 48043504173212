import request from '../../utils/api';

export const createBag = (body: object) =>
  request('mbagging/bags/', { method: 'POST', body });

export const closeBag = (body: object) =>
  request('mbagging/bag-op/', { method: 'POST', body });

export const getBagNumber = (bagNumber: string) =>
  request(`mbagging/bags/${bagNumber}`);

export const removeBagPackages = (body: object) =>
  request('mbagging/package/remove', { method: 'PATCH', body });

export const addBagPackages = (body: object) =>
  request('mbagging/package', { method: 'POST', body });

// This service is used for the waybill sticker push to the fareye
/* export const getBagSticker = (bagNumber: string) =>
  request(`mbagging/waybills/${bagNumber}`); */

export const regenerateBagSticker = (body: object) =>
  request('mbagging/waybills', { method: 'POST', body });

export const getAllPortCodes = () => request('locations/port-codes/');

export const updateBaggingStatus = (body: object) =>
  request('mbagging/bag-op', { method: 'POST', body });

export const validatePackage = (body: object) =>
  request('mbagging/package/validate', { method: 'POST', body });

export const downloadTnRequest = (bagNumber: string) =>
  request(`mbagging/bags/${bagNumber}/report`, { method: 'GET' }, 'blob');
