export const MuiButton = {
  containedSecondary: {
    '&:hover': {
      opacity: 0.8,
      backgroundColor: '#1D4982',
    },
  },
  outlined: {
    border: '1px solid rgba(0, 0, 0, 0.26)',
    color: '#727272',
    '&:hover': {
      backgroundColor: '#00000024',
    },
  },
  outlinedPrimary: {
    '&:hover': {
      backgroundColor: '#1D4982',
      color: '#FFFFFF',
    },
  },
};
