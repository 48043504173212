import { Reducer } from 'redux';
import { BaggingState } from './interface';

export const actionTypes = {
  CREATE_BAG_REQUEST: 'CREATE_BAG_REQUEST',
  CREATE_BAG_SUCCESS: 'CREATE_BAG_SUCCESS',
  CREATE_BAG_FAILED: 'CREATE_BAG_FAILED',

  CLOSE_BAG_REQUEST: 'CLOSE_BAG_REQUEST',
  CLOSE_BAG_SUCCESS: 'CLOSE_BAG_SUCCESS',
  CLOSE_BAG_FAILED: 'CLOSE_BAG_FAILED',

  GET_BAG_NUMBER_REQUEST: 'GET_BAG_NUMBER_REQUEST',
  GET_BAG_NUMBER_SUCCESS: 'GET_BAG_NUMBER_SUCCESS',
  GET_BAG_NUMBER_FAILED: 'GET_BAG_NUMBER_FAILED',

  REMOVE_BAG_PACKAGES_REQUEST: 'REMOVE_BAG_PACKAGES_REQUEST',
  REMOVE_BAG_PACKAGES_SUCCESS: 'REMOVE_BAG_PACKAGES_SUCCESS',
  REMOVE_BAG_PACKAGES_FAILED: 'REMOVE_BAG_PACKAGES_FAILED',

  ADD_BAG_PACKAGES_REQUEST: 'ADD_BAG_PACKAGES_REQUEST',
  ADD_BAG_PACKAGES_SUCCESS: 'ADD_BAG_PACKAGES_SUCCESS',
  ADD_BAG_PACKAGES_FAILED: 'ADD_BAG_PACKAGES_FAILED',

  VALIDATE_PACKAGE_REQUEST: 'VALIDATE_PACKAGE_REQUEST',
  VALIDATE_PACKAGE_SUCCESS: 'VALIDATE_PACKAGE_SUCCESS',
  VALIDATE_PACKAGE_FAILED: 'VALIDATE_PACKAGE_FAILED',

  UPDATE_BAGGING_STATUS_REQUEST: 'UPDATE_BAGGING_STATUS_REQUEST',
  UPDATE_BAGGING_STATUS_SUCCESS: 'UPDATE_BAGGING_STATUS_SUCCESS',
  UPDATE_BAGGING_STATUS_FAILED: 'UPDATE_BAGGING_STATUS_FAILED',

  GET_BAG_STICKER_REQUEST: 'GET_BAG_STICKER_REQUEST',
  GET_BAG_STICKER_SUCCESS: 'GET_BAG_STICKER_SUCCESS',
  GET_BAG_STICKER_FAILED: 'GET_BAG_STICKER_FAILED',

  REGENERATE_BAG_STICKER_REQUEST: 'REGENERATE_BAG_STICKER_REQUEST',
  REGENERATE_BAG_STICKER_SUCCESS: 'REGENERATE_BAG_STICKER_SUCCESS',
  REGENERATE_BAG_STICKER_FAILED: 'REGENERATE_BAG_STICKER_FAILED',

  GET_ALL_PORTCODES_REQUEST: 'GET_ALL_PORTCODES_REQUEST',
  GET_ALL_PORTCODES_SUCCESS: 'GET_ALL_PORTCODES_SUCCESS',
  GET_ALL_PORTCODES_FAILED: 'GET_ALL_PORTCODES_FAILED',

  RESET_BAG: 'RESET_BAG',
  RESET_ADD_TO_BAG: 'RESET_ADD_TO_BAG',
  RESET_ACTION_TYPE: 'RESET_ACTION_TYPE',

  DOWNLOAD_TN_REQUEST: 'DOWNLOAD_TN_REQUEST',
  DOWNLOAD_TN_SUCCESS: 'DOWNLOAD_TN_SUCCESS',
  DOWNLOAD_TN_FAILED: 'DOWNLOAD_TN_FAILED',
};

export const actionCreators = {
  createBag: (body: object) => ({ type: actionTypes.CREATE_BAG_REQUEST, body }),
  closeBag: (body: object) => ({ type: actionTypes.CLOSE_BAG_REQUEST, body }),
  updateBaggingStatus: (body: object) => ({
    type: actionTypes.UPDATE_BAGGING_STATUS_REQUEST,
    body,
  }),
  removeBagPackages: (body: object) => ({
    type: actionTypes.REMOVE_BAG_PACKAGES_REQUEST,
    body,
  }),
  addBagPackages: (body: object) => ({
    type: actionTypes.ADD_BAG_PACKAGES_REQUEST,
    body,
  }),
  validatePackage: (body: object) => ({
    type: actionTypes.VALIDATE_PACKAGE_REQUEST,
    body,
  }),
  getBagNumber: (bagNumber: string, displayError: boolean) => ({
    type: actionTypes.GET_BAG_NUMBER_REQUEST,
    bagNumber,
    displayError,
  }),
  getBagSticker: (bagNumber: string) => ({
    type: actionTypes.GET_BAG_STICKER_REQUEST,
    bagNumber,
  }),
  regenerateBagSticker: (body: object) => ({
    type: actionTypes.REGENERATE_BAG_STICKER_REQUEST,
    body,
  }),
  getAllPortCodes: () => ({ type: actionTypes.GET_ALL_PORTCODES_REQUEST }),
  resetBag: () => ({ type: actionTypes.RESET_BAG }),
  resetAddToBag: () => ({ type: actionTypes.RESET_ADD_TO_BAG }),
  resetActionType: () => ({ type: actionTypes.RESET_ACTION_TYPE }),

  downloadTn: (bagNumber: string) => ({
    type: actionTypes.DOWNLOAD_TN_REQUEST,
    bagNumber,
  }),
};

const initialState: BaggingState = {
  actionType: '',
  isLoading: false,
  bag: {
    id: -1,
    bagNumber: '',
    portCodeOrigin: '',
    portCodeDestination: '',
    status: '',
    createdBy: '',
    processedBy: '',
    packages: [],
    inFareye: null,
  },
  waybill: {
    url: '',
  },
  portCodeList: [],
  errorDetails: {},
  message: '',
  bagNumber: '',
  successfulPackages: [],
  validatedData: {},
};

const reducer: Reducer<BaggingState> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_BAG_REQUEST:
    case actionTypes.CLOSE_BAG_REQUEST:
    case actionTypes.GET_BAG_NUMBER_REQUEST:
    case actionTypes.REMOVE_BAG_PACKAGES_REQUEST:
    case actionTypes.ADD_BAG_PACKAGES_REQUEST:
    case actionTypes.GET_BAG_STICKER_REQUEST:
    case actionTypes.REGENERATE_BAG_STICKER_REQUEST:
    case actionTypes.GET_ALL_PORTCODES_REQUEST:
    case actionTypes.UPDATE_BAGGING_STATUS_REQUEST:
    case actionTypes.VALIDATE_PACKAGE_REQUEST:
      return { ...state, isLoading: true };

    case actionTypes.CREATE_BAG_SUCCESS:
      return {
        ...state,
        isLoading: false,
        actionType: action.type,
        bag: action.data.bag,
      };
    case actionTypes.CREATE_BAG_FAILED:
      return { ...state, isLoading: false, bag: initialState.bag };

    case actionTypes.CLOSE_BAG_SUCCESS:
      return {
        ...state,
        isLoading: false,
        actionType: action.type,
        bag: action.data.bag,
      };
    case actionTypes.CLOSE_BAG_FAILED:
      return { ...state, isLoading: false, bag: initialState.bag };

    case actionTypes.GET_BAG_NUMBER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        actionType: action.type,
        bag: action.data.bag,
      };
    case actionTypes.GET_BAG_NUMBER_FAILED:
      return {
        ...state,
        isLoading: false,
        actionType: action.type,
        bag: initialState.bag,
      };

    case actionTypes.REMOVE_BAG_PACKAGES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        actionType: action.type,
      };
    case actionTypes.REMOVE_BAG_PACKAGES_FAILED:
      return { ...state, isLoading: false };

    case actionTypes.ADD_BAG_PACKAGES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        actionType: action.type,
        message: action.data.message,
        errorDetails: action.data.errorDetails,
        bagNumber: action.data.bagNumber,
        successfulPackages: action.data.successfulPackages,
      };
    case actionTypes.ADD_BAG_PACKAGES_FAILED:
      return {
        ...state,
        isLoading: false,
        message: action.err.message,
        errorDetails: action.err.errorDetails,
      };

    case actionTypes.VALIDATE_PACKAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        actionType: action.type,
        validatedData: action.data.status,
      };
    case actionTypes.VALIDATE_PACKAGE_FAILED:
      return {
        ...state,
        isLoading: false,
        message: action.err.message,
        validatedData: {},
      };

    case actionTypes.UPDATE_BAGGING_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        actionType: action.type,
        bag: action.data.bag,
      };
    case actionTypes.UPDATE_BAGGING_STATUS_FAILED:
      return { ...state, isLoading: false, actionType: action.type };

    case actionTypes.GET_BAG_STICKER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        actionType: action.type,
        waybill: action.data.waybill,
      };
    case actionTypes.GET_BAG_STICKER_FAILED:
      return { ...state, isLoading: false, waybill: initialState.waybill };

    case actionTypes.REGENERATE_BAG_STICKER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        actionType: action.type,
        waybill: action.data.waybill,
      };
    case actionTypes.REGENERATE_BAG_STICKER_FAILED:
      return { ...state, isLoading: false, waybill: initialState.waybill };

    case actionTypes.GET_ALL_PORTCODES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        actionType: action.type,
        portCodeList: action.data,
      };
    case actionTypes.GET_ALL_PORTCODES_FAILED:
      return {
        ...state,
        isLoading: false,
        portCodeList: initialState.portCodeList,
      };

    case actionTypes.RESET_BAG:
      return {
        ...state,
        isLoading: false,
        bag: initialState.bag,
      };
    case actionTypes.RESET_ADD_TO_BAG:
      return {
        ...state,
        isLoading: false,
        errorDetails: initialState.errorDetails,
        message: initialState.message,
        bagNumber: initialState.bagNumber,
        successfulPackages: initialState.successfulPackages,
      };
    case actionTypes.RESET_ACTION_TYPE:
      return {
        ...state,
        isLoading: false,
        actionType: '',
      };
    case actionTypes.DOWNLOAD_TN_REQUEST:
      return {
        ...state,
        isLoading: false,
      };

    case actionTypes.DOWNLOAD_TN_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case actionTypes.DOWNLOAD_TN_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default reducer;
