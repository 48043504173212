/* eslint-disable @typescript-eslint/no-explicit-any */
import { all, call, takeLatest, put, select, delay } from 'redux-saga/effects';
import * as bulmaToast from 'bulma-toast';
import { actionTypes } from './reducer';
import { actionTypes as authActionTypes } from '../auth/reducer';
import {
  getAllOrders as getAllOrdersService,
  getOrdersFiltered as getOrdersFilteredService,
  // getOrdersFilteredClientRef as getOrdersFilteredClientRefService,
  getOrdersFilteredMultiRef as getOrdersFilteredMultiRefService,
  getAllFilters as geteAllFiltersService,
  getOrder as getOrderService,
  getOrderHistory as getOrderHistoryService,
  pushOrdersToFareye as pushOrdersToFareyeService,
  generateWaybill as generateWaybillService,
  getWaybillHistory as getWaybillHistoryService,
  getFiltersDownloadReport as getFiltersDownloadReportService,
  pollFiltersDownloadReport as pollFiltersDownloadReportService,
  getDispositionHistory as getDispositionHistoryService,
  addDisposition as addDispositionService,
  validateOtpUser as validateOtpUserRequest,
  resendOtpRequest,
} from './service';
import {
  OrderFilters,
  OverviewBody,
  PushToFareyeProps,
  GenerateWaybillSaga,
  WaybillHistorySaga,
  OrderFiltersMulti,
  PollReportSaga,
  DispositionHistorySaga,
  AddDispositionSaga,
  OrderFiltersDownload,
  ValidateOtpUser,
  ResendOtp,
  GetDeliverySign,
  GetDeliveryImage,
  // OrderFiltersClientRef
} from './interface';

function* getAllOrders({
  page,
  limit,
  date,
  lastDate,
}: OverviewBody): Generator<any> {
  try {
    const state: any = yield select();
    // To call async functions, use redux-saga's `call()`.
    const data: any = yield call(
      getAllOrdersService,
      page,
      limit,
      date,
      lastDate
    );
    data.cachedOrders = [];
    // if meta data next page has value
    if (data.metadata.next_page === -1) {
      const newCachedOrders = state.orders.cachedOrders;
      newCachedOrders[page] = data.salesOrderList;
      data.cachedOrders = newCachedOrders;
      const finalPages = newCachedOrders.length - 1;
      data.pageCount = finalPages;
    } else {
      // set page count to next page
      data.pageCount = data.metadata.next_page;
      if (!state.orders.cachedOrders[page]) {
        // concat cached orders
        const newCachedOrders = state.orders.cachedOrders;
        newCachedOrders[page] = data.salesOrderList;
        data.cachedOrders = newCachedOrders;
      }
    }
    yield put({ type: actionTypes.GET_ALL_ORDERS_SUCCESS, data });
  } catch (err) {
    if (err.status === 403) {
      yield put({ type: authActionTypes.LOGOUT_SUCCESS });
      bulmaToast.toast({
        message: 'Authentication Failed!',
        type: 'is-danger',
        position: 'top-center',
        dismissible: true,
        duration: 10000,
        closeOnClick: true,
      });
    } else {
      bulmaToast.toast({
        message: err.message || err.details,
        type: 'is-danger',
        position: 'top-center',
        dismissible: true,
        duration: 10000,
        closeOnClick: true,
      });
    }
    yield put({ type: actionTypes.GET_ALL_ORDERS_FAILED, err });
  }
}

function* getAllOrdersFiltered({
  page,
  limit,
  trackingNumber,
  bookingNumber,
  orderStatus,
  paymentMethod,
  destinationHub,
  pickupHub,
  client,
  startDate,
  endDate,
  lastDate,
}: OrderFilters): Generator<any> {
  try {
    const state: any = yield select();
    const data: any = yield call(
      getOrdersFilteredService,
      page,
      limit,
      trackingNumber,
      bookingNumber,
      orderStatus,
      paymentMethod,
      destinationHub,
      pickupHub,
      client,
      startDate,
      endDate,
      lastDate
    );
    data.cachedOrders = [];
    // if meta data next page has value
    if (data.metadata.next_page === -1) {
      const newCachedOrders = state.orders.cachedOrders;
      newCachedOrders[page] = data.salesOrderList;
      data.cachedOrders = newCachedOrders;
      const finalPages = newCachedOrders.length - 1;
      data.pageCount = finalPages;
    } else {
      // set page count to next page
      data.pageCount = data.metadata.next_page;
      if (!state.orders.cachedOrders[page]) {
        // concat cached orders
        const newCachedOrders = state.orders.cachedOrders;
        newCachedOrders[page] = data.salesOrderList;
        data.cachedOrders = newCachedOrders;
      }
    }
    yield put({ type: actionTypes.GET_ORDERS_FILTERED_SUCCESS, data });
  } catch (err) {
    bulmaToast.toast({
      message: "Invalid Booking Number" ,
      type: 'is-danger',
      position: 'top-center',
      dismissible: true,
      duration: 10000,
      closeOnClick: true,
    });
    yield put({ type: actionTypes.GET_ORDERS_FILTERED_FAILED });
  }
}
// function* getAllOrdersFilteredClientRef({
//   page,
//   limit,
//   clientReferenceNumbers,
// }: OrderFiltersClientRef): Generator<any> {
//   try {
//     const state: any = yield select();
//     const data: any = yield call(
//       getOrdersFilteredClientRefService,
//       page,
//       limit,
//       clientReferenceNumbers,
//     );
//     data.cachedOrders = [];
//     // if meta data next page has value
//     if (data.metadata.next_page === -1) {
//       const newCachedOrders = state.orders.cachedOrders;
//       newCachedOrders[page] = data.salesOrderList;
//       data.cachedOrders = newCachedOrders;
//       const finalPages = newCachedOrders.length - 1;
//       data.pageCount = finalPages;
//     } else {
//       // set page count to next page
//       data.pageCount = data.metadata.next_page;
//       if (!state.orders.cachedOrders[page]) {
//         // concat cached orders
//         const newCachedOrders = state.orders.cachedOrders;
//         newCachedOrders[page] = data.salesOrderList;
//         data.cachedOrders = newCachedOrders;
//       }
//     }
//     yield put({ type: actionTypes.GET_ORDERS_FILTERED_CLIENTREF_SUCCESS, data });
//   } catch (err) {
//     bulmaToast.toast({
//       message: err.message,
//       type: 'is-danger',
//       position: 'top-center',
//       dismissible: true,
//       duration: 10000,
//       closeOnClick: true,
//     });
//     yield put({ type: actionTypes.GET_ORDERS_FILTERED_CLIENTREF_FAILED });
//   }
// }
function* getAllOrdersFilteredMultiRef({
  page,
  limit,
  trackingNumbers,
  lastDate,
}: OrderFiltersMulti): Generator<any> {
  try {
    const state: any = yield select();
    const data: any = yield call(
      getOrdersFilteredMultiRefService,
      page,
      limit,
      trackingNumbers,
      lastDate
    );
    data.cachedOrders = [];
    // if meta data next page has value
    if (data.metadata.next_page === -1) {
      const newCachedOrders = state.orders.cachedOrders;
      newCachedOrders[page] = data.salesOrderList;
      data.cachedOrders = newCachedOrders;
      const finalPages = newCachedOrders.length - 1;
      data.pageCount = finalPages;
    } else {
      // set page count to next page
      data.pageCount = data.metadata.next_page;
      if (!state.orders.cachedOrders[page]) {
        // concat cached orders
        const newCachedOrders = state.orders.cachedOrders;
        newCachedOrders[page] = data.salesOrderList;
        data.cachedOrders = newCachedOrders;
      }
    }
    yield put({ type: actionTypes.GET_ORDERS_FILTERED_MULTI_SUCCESS, data });
  } catch (err) {
    bulmaToast.toast({
      message: err.message,
      type: 'is-danger',
      position: 'top-center',
      dismissible: true,
      duration: 10000,
      closeOnClick: true,
    });
    yield put({ type: actionTypes.GET_ORDERS_FILTERED_MULTI_FAILED });
  }
}

function* getFiltersDownloadReport({
  trackingNumber,
  bookingNumber,
  orderStatus,
  paymentMethod,
  destinationHub,
  pickupHub,
  client,
  startDate,
  endDate,
  lastDate,
}: OrderFiltersDownload): Generator<any> {
  try {
    const data: any = yield call(
      getFiltersDownloadReportService,
      trackingNumber,
      bookingNumber,
      orderStatus,
      paymentMethod,
      destinationHub,
      pickupHub,
      client,
      startDate,
      endDate,
      lastDate
    );
    const removeDoubleQuotes = data.replace(/['"]+/g, '');
    bulmaToast.toast({
      message: 'Generating report please wait for a moment.',
      type: 'is-warning',
      position: 'bottom-right',
      duration: 5000,
      dismissible: true,
      closeOnClick: true,
    });
    yield put({
      type: actionTypes.POLL_FILTERS_DOWNLOAD_REPORT_REQUEST,
      id: removeDoubleQuotes,
    });
    yield put({ type: actionTypes.GET_FILTERS_DOWNLOAD_REPORT_SUCCESS, data });
  } catch (err) {
    bulmaToast.toast({
      message: err.message,
      type: 'is-danger',
      position: 'center',
      dismissible: true,
      duration: 10000,
      closeOnClick: true,
    });
    yield put({ type: actionTypes.GET_FILTERS_DOWNLOAD_REPORT_FAILED });
  }
}

function* getOrder({ trackingNumber }: OrderFilters): Generator<any> {
  try {
    // To call async functions, use redux-saga's `call()`.
    const data = yield call(getOrderService, trackingNumber);
    yield put({ type: actionTypes.GET_ORDER_SUCCESS, data });
  } catch (err) {
    yield put({ type: actionTypes.GET_ORDER_FAILED, err });
  }
}

function* getOrderHistory({ trackingNumber }: OrderFilters): Generator<any> {
  try {
    // To call async functions, use redux-saga's `call()`.
    const data = yield call(getOrderHistoryService, trackingNumber);
    yield put({ type: actionTypes.GET_ORDER_HISTORY_SUCCESS, data });
  } catch (err) {
    yield put({ type: actionTypes.GET_ORDER_HISTORY_FAILED, err });
  }
}

function* getAllFilters(): Generator<any> {
  try {
    // To call async functions, use redux-saga's `call()`.
    const data = yield call(geteAllFiltersService);
    yield put({ type: actionTypes.GET_ALL_FILTERS_SUCCESS, data });
  } catch (err) {
    yield put({ type: actionTypes.GET_ALL_FILTERS_FAILED, err });
  }
}

function* pushOrdersToFareye({
  trackingNumbers,
}: PushToFareyeProps): Generator<any> {
  try {
    const data = yield call(pushOrdersToFareyeService, trackingNumbers);
    bulmaToast.toast({
      message: 'Repush successful!',
      type: 'is-success',
      position: 'top-center',
      dismissible: true,
      duration: 10000,
      closeOnClick: true,
    });
    yield put({ type: actionTypes.PUSH_ORDERS_TO_FAREYE_SUCCESS, data });
  } catch (err) {
    bulmaToast.toast({
      message: err.message,
      type: 'is-danger',
      position: 'center',
      dismissible: true,
      duration: 10000,
      closeOnClick: true,
    });
    yield put({ type: actionTypes.PUSH_ORDERS_TO_FAREYE_FAILED, err });
  }
}

function* generateWaybill({ body }: GenerateWaybillSaga): Generator<any> {
  try {
    const data: any = yield call(generateWaybillService, body);
    bulmaToast.toast({
      message: 'Generated Successfully!',
      type: 'is-success',
      position: 'top-center',
      dismissible: true,
      duration: 10000,
      closeOnClick: true,
    });
    window.open(data.downloadURL, '_blank');
    yield put({ type: actionTypes.GENERATE_WAYBILL_SUCCESS, data });
    yield put({
      type: actionTypes.GET_WAYBILL_HISTORY_REQUEST,
      trackingNumber: body.trackingNumber,
    });
  } catch (err) {
    bulmaToast.toast({
      message: err.message,
      type: 'is-warning',
      position: 'top-center',
      dismissible: true,
      duration: 10000,
      closeOnClick: true,
    });
    yield put({ type: actionTypes.GENERATE_WAYBILL_FAILED, err });
  }
}

function* pollFiltersDownloadReport({ id }: PollReportSaga): Generator<any> {
  try {
    const data: any = yield call(pollFiltersDownloadReportService, id);
    const { status = '', downloadURL = '' } = data;
    if (status === 'IN_PROGRESS') {
      yield delay(60000);
      bulmaToast.toast({
        message: 'Generating report please wait for a moment.',
        type: 'is-warning',
        position: 'bottom-right',
        duration: 5000,
        dismissible: true,
        closeOnClick: true,
      });
      yield put({ type: actionTypes.POLL_FILTERS_DOWNLOAD_REPORT_REQUEST, id });
    } else {
      window.open(downloadURL, '_blank');
      yield put({
        type: actionTypes.POLL_FILTERS_DOWNLOAD_REPORT_SUCCESS,
        data,
      });
    }
  } catch (err) {
    yield put({ type: actionTypes.POLL_FILTERS_DOWNLOAD_REPORT_FAILED, err });
  }
}

function* getWaybillHistory({
  trackingNumber,
}: WaybillHistorySaga): Generator<any> {
  try {
    const data = yield call(getWaybillHistoryService, trackingNumber);
    yield put({ type: actionTypes.GET_WAYBILL_HISTORY_SUCCESS, data });
  } catch (err) {
    yield put({ type: actionTypes.GET_WAYBILL_HISTORY_FAILED, err });
  }
}

function* addDisposition({ body }: AddDispositionSaga): Generator<any> {
  try {
    const data = yield call(addDispositionService, body);
    bulmaToast.toast({
      message: 'Added Successfully!',
      type: 'is-success',
      position: 'top-center',
      dismissible: true,
      duration: 10000,
      closeOnClick: true,
    });
    yield put({ type: actionTypes.ADD_DISPOSITION_SUCCESS, data });
    yield put({
      type: actionTypes.GET_DISPOSITION_HISTORY_REQUEST,
      trackingNumber: body.trackingNumber,
    });
  } catch (err) {
    bulmaToast.toast({
      message: `${err.message}: ${err.details}`,
      type: 'is-warning',
      position: 'top-center',
      dismissible: true,
      duration: 10000,
      closeOnClick: true,
    });
    yield put({ type: actionTypes.ADD_DISPOSITION_FAILED, err });
  }
}

function* getDispositionHistory({
  trackingNumber,
}: DispositionHistorySaga): Generator<any> {
  try {
    const data = yield call(getDispositionHistoryService, trackingNumber);
    yield put({ type: actionTypes.GET_DISPOSITION_HISTORY_SUCCESS, data });
  } catch (err) {
    yield put({ type: actionTypes.GET_DISPOSITION_HISTORY_FAILED, err });
  }
}

function* validateOtpUser({ trackingNumber }: ValidateOtpUser): Generator<any> {
  try {
    const data = yield call(validateOtpUserRequest, trackingNumber);
    yield put({ type: actionTypes.VALIDATE_OTP_USER_SUCCESS, data });
  } catch (err) {
    yield put({ type: actionTypes.VALIDATE_OTP_USER_FAILED, err });
  }
}

function* resendOtp({ trackingNumber }: ResendOtp): Generator<any> {
  try {
    const data: any = yield call(resendOtpRequest, trackingNumber);
    if (data.result === 'OTP Sent!')
      bulmaToast.toast({
        message: 'OTP Sent!',
        type: 'is-success',
        position: 'bottom-right',
        dismissible: true,
        duration: 7000,
        closeOnClick: true,
        animate: { in: 'slideInRight', out: 'slideOutRight' },
      });
    yield put({ type: actionTypes.RESEND_OTP_REQUEST_SUCCESS, data });
  } catch (err) {
    if (err.status === 429) {
      bulmaToast.toast({
        message: 'Too many request. Please wait for 30 seconds and try again.',
        type: 'is-danger',
        position: 'bottom-right',
        dismissible: true,
        duration: 10000,
        closeOnClick: true,
      });
    }

    if (err.status === 502) {
      bulmaToast.toast({
        message: 'There is an error in the system. Please inform the HMS Admin',
        type: 'is-danger',
        position: 'bottom-right',
        dismissible: true,
        duration: 10000,
        closeOnClick: true,
      });
    }
    yield put({ type: actionTypes.RESEND_OTP_REQUEST_FAILED, err });
  }
}



function* getDeliverySign({ url }: GetDeliverySign): Generator<any> {
  const data: any = yield call(fetch, url);
  if (data.status === 200) {
    window.open(data.url);
  } else if (data.status === 403) {
    window.open(`/epod`); // eslint-disable-line
  } else {
    window.open('/epod');
  }
}

function* getDeliveryImage({ url }: GetDeliveryImage): Generator<any> {
  const data: any = yield call(fetch, url);
  if (data.status === 200) {
    window.open(data.url);
  } else if (data.status === 403) {
    window.open(`/epod`); // eslint-disable-line
  } else {
    window.open('/epod');
  }
}

function* watchOrdersRequests() {
  yield all([
    takeLatest(actionTypes.GET_ALL_ORDERS_REQUEST, getAllOrders),
    takeLatest(actionTypes.GET_ALL_FILTERS_REQUEST, getAllFilters),
    takeLatest(actionTypes.GET_ORDERS_FILTERED_REQUEST, getAllOrdersFiltered),
    // takeLatest(
    //   actionTypes.GET_ORDERS_FILTERED_CLIENTREF_REQUEST,
    //   getAllOrdersFilteredClientRef
    // ),
    takeLatest(
      actionTypes.GET_ORDERS_FILTERED_MULTI_REQUEST,
      getAllOrdersFilteredMultiRef
    ),
    takeLatest(actionTypes.GET_ORDER_REQUEST, getOrder),
    takeLatest(actionTypes.GET_ORDER_HISTORY_REQUEST, getOrderHistory),
    takeLatest(actionTypes.PUSH_ORDERS_TO_FAREYE_REQUEST, pushOrdersToFareye),
    takeLatest(actionTypes.GENERATE_WAYBILL_REQUEST, generateWaybill),
    takeLatest(actionTypes.GET_WAYBILL_HISTORY_REQUEST, getWaybillHistory),
    takeLatest(
      actionTypes.GET_FILTERS_DOWNLOAD_REPORT_REQUEST,
      getFiltersDownloadReport
    ),
    takeLatest(
      actionTypes.POLL_FILTERS_DOWNLOAD_REPORT_REQUEST,
      pollFiltersDownloadReport
    ),
    takeLatest(
      actionTypes.GET_DISPOSITION_HISTORY_REQUEST,
      getDispositionHistory
    ),
    takeLatest(actionTypes.ADD_DISPOSITION_REQUEST, addDisposition),
    takeLatest(actionTypes.VALIDATE_OTP_USER_REQUEST, validateOtpUser),
    takeLatest(actionTypes.RESEND_OTP_REQUEST, resendOtp),
    takeLatest(actionTypes.GET_DELIVERY_SIGNATURE, getDeliverySign),
    takeLatest(actionTypes.GET_DELIVERY_IMAGE, getDeliveryImage),
  ]);
}

export default [watchOrdersRequests()];
