export const MuiInputBase = {
  input: {
    '&:disabled': {
      borderRadius: 4,
      position: 'relative',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      boxShadow: '0px 3px 6px #00000024',
    },
    // '&:focus-within': {
    //   color: '#1D4982',
    // },
    // transition: theme.transitions.create(['border-color', 'box-shadow']),
  },
  root: {
    fontWeight: 'inherit',
    color: '#1D4982',
    // '&:focus-within': {
    //   color: '#FFFFFF',
    // },
  },
  inputMultiline: {
    padding: '10px 26px 10px 12px',
    fontSize: 16,
    borderRadius: 4,
    boxShadow: '0px 3px 6px #00000024',
    backgroundColor: '#FFFFFF',
  },
};
