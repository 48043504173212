import request from '../../utils/api';
import { UpdateUserProps, DeactivateUserProps } from './interface';

export const getAllUsers = (page: number, limit: number) => {
  return request(`hubm/users?page=${page}&limit=${limit}`);
};

export const getUsersSearch = (
  page: number,
  limit: number,
  searchFilter: string
) => {
  return request(
    `hubm/users/search?searchFilter=${searchFilter}&page=${page}&limit=${limit}`
  );
};

export const updateUser = (body: UpdateUserProps) => {
  return request('hubm/users/update', { method: 'PATCH', body });
};

export const deactivateUser = (body: DeactivateUserProps) => {
  return request('hubm/users', { method: 'PATCH', body });
};

export const getAllPortCodes = () => request('locations/port-codes/');
