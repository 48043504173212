import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import Main from '../modules/App';
import { ApplicationState } from '../store/reducers';
import { validateRole } from '../utils/rolevalidation';
import { PrivateRouteProps } from './interface';

const PrivateRoutes: React.FC<PrivateRouteProps> = ({
  component: Component,
  isAuthenticated,
  profile,
  ...rest
}) => {
  const { authorities } = profile;
  const { role } = rest;
  const isRouteValidated = validateRole(authorities, role);

  return (
    <Route
      {...rest}
      render={
        (props) =>
          isAuthenticated && isRouteValidated ? (
            <Main>
              <Component {...props} />
            </Main>
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location },
              }}
            />
          ) // eslint-disable-next-line react/jsx-curly-newline
      }
    />
  );
};

const mapStateToProps = (store: ApplicationState) => ({
  isAuthenticated: store.auth.isAuthenticated,
  profile: store.auth.profile,
});

export default connect(mapStateToProps)(PrivateRoutes);
