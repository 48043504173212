import React from 'react';
import loadable from '@loadable/component';
import Loading from '../../commons/loading/index';
import { role } from '../../utils/rolevalidation';

export default [
  {
    path: '/slaextension',
    role: [
      role.ROLE_HUBM_ADMIN,
      role.ROLE_HUBM_MANAGER,
      role.ROLE_HUBM_SUPERVISOR,
      role.ROLE_HUBM_SORT,
    ],
    exact: true,
    authL: true,
    component: loadable(() => import('./index'), {
      fallback: <Loading />,
    }),
  },
];
