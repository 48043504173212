import { Reducer } from 'redux';
import { SlaLeadtimeState } from './interface';

export const actionTypes = {
  GET_ALL_SLA_REQUEST: 'GET_ALL_SLA_REQUEST',
  GET_ALL_SLA_SUCCESS: 'GET_ALL_SLA_SUCCESS',
  GET_ALL_SLA_FAILED: 'GET_ALL_SLA_FAILED',
  SET_CACHED_SLA: 'SET_CACHED_SLA',

  RESET_SLA: 'RESET_SLA',
  RESET_SLA_FEED: 'RESET_SLA_FEED',

  GET_SLA_FILTERS_REQUEST: 'GET_SLA_FILTERS_REQUEST',
  GET_SLA_FILTERS_SUCCESS: 'GET_SLA_FILTERS_SUCCESS',
  GET_SLA_FILTERS_FAILED: 'GET_SLA_FILTERS_FAILED',

  GENERATE_SLA_REQUEST: 'GENERATE_SLA_REQUEST',
  GENERATE_SLA_SUCCESS: 'GENERATE_SLA_SUCCESS',
  GENERATE_SLA_FAILED: 'GENERATE_SLA_FAILED',

  GET_SLA_FEED_REQUEST: 'GET_SLA_FEED_REQUEST',
  GET_SLA_FEED_SUCCESS: 'GET_SLA_FEED_SUCCESS',
  GET_SLA_FEED_FAILED: 'GET_SLA_FEED_FAILED',

  GET_SLA_FILTERED_REQUEST: 'GET_SLA_FILTERED_REQUEST',
  GET_SLA_FILTERED_SUCCESS: 'GET_SLA_FILTERED_SUCCESS',
  GET_SLA_FILTERED_FAILED: 'GET_SLA_FILTERED_FAILED',
};

export const actionCreators = {
  getAllSlaData: (page: number, limit: number) => ({
    type: actionTypes.GET_ALL_SLA_REQUEST,
    page,
    limit,
  }),
  getSlaFilters: () => ({ type: actionTypes.GET_SLA_FILTERS_REQUEST }),
  getSlaDataFiltered: (
    page: number,
    limit: number,
    owner: string,
    location: string,
    slaStatus: string,
    orderCreatedDateStart: string,
    orderCreatedDateEnd: string,
    e2eDaysLeft: number[]
  ) => ({
    type: actionTypes.GET_SLA_FILTERED_REQUEST,
    page,
    limit,
    owner,
    location,
    slaStatus,
    orderCreatedDateStart,
    orderCreatedDateEnd,
    e2eDaysLeft,
  }),
  setCachedSlaData: (slaData: object[]) => ({
    type: actionTypes.SET_CACHED_SLA,
    slaData,
  }),
  resetSlaData: () => ({
    type: actionTypes.RESET_SLA,
  }),
  resetSlaFeed: () => ({
    type: actionTypes.RESET_SLA_FEED,
  }),
  getSlaFeedData: (id: string) => ({
    type: actionTypes.GET_SLA_FEED_REQUEST,
    id,
  }),
  generateSla: (
    orderCreatedDateStart: string,
    orderCreatedDateEnd: string,
    location: string,
    e2eDaysLeft: number[],
    slaStatus: string
  ) => ({
    type: actionTypes.GENERATE_SLA_REQUEST,
    orderCreatedDateStart,
    orderCreatedDateEnd,
    location,
    slaStatus,
    e2eDaysLeft,
  }),
};

const initialState: SlaLeadtimeState = {
  isLoading: false,
  filters: {
    locations: [],
    slaStatus: [],
  },
  slaData: [],
  cachedSlaData: [],
  pageCount: 0,
  meta: {
    total: 0,
    hasNext: false,
    hasPrevious: false,
    nextPage: 0,
    currentPage: 0,
    previousPage: 0,
    limit: 0,
  },
  slaFeedData: {
    status: '',
    downloadURL: '',
  },
};

const reducer: Reducer<SlaLeadtimeState> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_SLA_REQUEST:
    case actionTypes.GET_SLA_FILTERS_REQUEST:
    case actionTypes.GET_SLA_FILTERED_REQUEST:
    case actionTypes.GENERATE_SLA_REQUEST:
      return { ...state, isLoading: true };

    case actionTypes.GET_ALL_SLA_SUCCESS:
      return {
        ...state,
        slaData: action.data.data,
        meta: action.data.metadata,
        cachedSlaData: action.data.cachedSlaData,
        pageCount: action.data.pageCount,
        isLoading: false,
      };
    case actionTypes.GET_ALL_SLA_FAILED:
      return {
        ...state,
        slaData: initialState.slaData,
        meta: initialState.meta,
        cachedSlaData: initialState.cachedSlaData,
        pageCount: initialState.pageCount,
        isLoading: false,
      };

    case actionTypes.GET_SLA_FILTERED_SUCCESS:
      return {
        ...state,
        slaData: action.data.data,
        meta: action.data.metadata,
        cachedSlaData: action.data.cachedSlaData,
        pageCount: action.data.pageCount,
        isLoading: false,
      };
    case actionTypes.GET_SLA_FILTERED_FAILED:
      return {
        ...state,
        slaData: initialState.slaData,
        meta: initialState.meta,
        cachedSlaData: initialState.cachedSlaData,
        pageCount: initialState.pageCount,
        isLoading: false,
      };
    case actionTypes.SET_CACHED_SLA:
      return {
        ...state,
        slaData: action.slaData,
        isLoading: false,
      };
    case actionTypes.RESET_SLA:
      return {
        ...state,
        cachedSlaData: [],
        slaData: [],
      };
    case actionTypes.GET_SLA_FILTERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        filters: action.data,
      };
    case actionTypes.GET_SLA_FILTERS_FAILED:
      return {
        ...state,
        filters: initialState.filters,
        isLoading: false,
      };
    case actionTypes.GENERATE_SLA_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.GENERATE_SLA_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.GET_SLA_FEED_REQUEST:
      return {
        ...state,
        slaFeedData: {
          downloadURL: null,
          status: 'IN_PROGRESS',
        },
      };
    case actionTypes.GET_SLA_FEED_SUCCESS:
      return {
        ...state,
        isLoading: false,
        slaFeedData: action.data,
      };
    case actionTypes.GET_SLA_FEED_FAILED:
      return {
        ...state,
        isLoading: false,
        slaFeedData: initialState.slaFeedData,
      };
    case actionTypes.RESET_SLA_FEED:
      return {
        ...state,
        slaFeedData: initialState.slaFeedData,
      };
    default:
      return state;
  }
};

export default reducer;
