// all available roles
export const role = {
  ROLE_HUBM_ADMIN: 'ROLE_HUBM_ADMIN',
  ROLE_HUBM_MANAGER: 'ROLE_HUBM_MANAGER',
  ROLE_HUBM_USER: 'ROLE_HUBM_USER',
  ROLE_HUBM_SUPERVISOR: 'ROLE_HUBM_SUPERVISOR',
  ROLE_HUBM_CUSTOMER_SERVICE: 'ROLE_HUBM_CUSTOMER_SERVICE',
  ROLE_HUBM_SORT: 'ROLE_HUBM_SORT',
  ROLE_HUBM_DFA:  'ROLE_HUBM_DFA',
  // ROLE_HUBM_RECEIVER: 'ROLE_HUBM_RECEIVER',
};

export const roleName = {
  ADMIN: 'Admin',
  MANAGER: 'Manager',
  USER: 'Regular',
  SUPERVISOR: 'Supervisor',
  CUSTOMER_SERVICE: 'Customer Service',
  SORT: 'Sort',
  DFA: 'DFA',
  // RECEIVER: 'Receiver',
};

// validate roles here (only accepts arrays)
export const validateRole = (roles, allowedRoles) => {
  const availableRoles = roles.map((val) => {
    return val.name;
  });

  // NOTE: if allowed role has an undefined value,
  // it will be available to all users
  if (allowedRoles === undefined) {
    return true;
  }

  // check if associated roles are in allowable roles
  const allowableRoles = allowedRoles || [];
  for (const allowableRole of allowableRoles) {
    if (availableRoles.includes(allowableRole)) {
      return true;
    }
  }
  return false;
};
