import { createMuiTheme } from '@material-ui/core';
import { MuiSelect } from './select';
import { MuiInputBase } from './input';
import { MuiButton } from './button';
import { MuiTabs } from './tabs';
import { MuiDialogActions } from './dialog';
import { MuiTextField } from './text-field';
import { MuiPaper } from './paper';

const theme = createMuiTheme({
  palette: {
    primary: {
      // main f26822 of theme
      main: '#005096',
      // change font color
      contrastText: '#ffff',
    },
    secondary: {
      main: '#1D4982',
      contrastText: '#ffff',
    },
  },
  typography: {
    fontFamily: ['"Open Sans",sans-serif'].join(','),
  },
  overrides: {
    MuiInputBase,
    MuiSelect,
    MuiButton,
    MuiTabs,
    MuiDialogActions,
    MuiTextField,
    MuiPaper,
  },
  spacing: (factor) => `${0.5 * factor}rem`,
});

export default theme;
