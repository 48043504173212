import React from 'react';
import { Route } from 'react-router-dom';
import Main from '../modules/App';
import { PublicRouteProps } from './interface';

const PublicRoutes: React.FC<PublicRouteProps> = ({
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => (
      <Main>
        <Component {...props} />
      </Main>
    )}
  />
);

export default PublicRoutes;
