import auth from '../modules/auth/routes';
import orders from '../modules/orders/routes';
import users from '../modules/users/routes';
import reports from '../modules/reports/routes';
import tools from '../modules/tools/routes';
import bagging from '../modules/bagging/routes';
import configuration from '../modules/configuration/routes';
import slaleadtime from '../modules/slaleadtime/routes';
import slaextension from '../modules/slaextension/routes';
import barangayCluster from '../modules/barangay-cluster/routes';
import hmsdashboard from '../modules/hmsdashboard/routes';
// import claims from '../modules/claims/routes';

export default [
  ...auth,
  ...orders,
  ...users,
  ...reports,
  ...tools,
  ...bagging,
  ...configuration,
  ...slaleadtime,
  // ...claims,
  ...slaextension,
  ...barangayCluster,
  ...hmsdashboard,
];
