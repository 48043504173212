import { Reducer } from 'redux';
import { ClaimsState } from './interface';

export const actionTypes = {
  GET_CLAIMS_PARAMETERS_REQUEST: 'GET_CLAIMS_PARAMETERS_REQUEST',
  GET_CLAIMS_PARAMETERS_SUCCESS: 'GET_CLAIMS_PARAMETERS_SUCCESS',
  GET_CLAIMS_PARAMETERS_FAILED: 'GET_CLAIMS_PARAMETERS_FAILED',

  VALIDATE_CLAIMS_PACKAGE_REQUEST: 'VALIDATE_CLAIMS_PACKAGE_REQUEST',
  VALIDATE_CLAIMS_PACKAGE_SUCCESS: 'VALIDATE_CLAIMS_PACKAGE_SUCCESS',
  VALIDATE_CLAIMS_PACKAGE_FAILED: 'VALIDATE_CLAIMS_PACKAGE_FAILED',

  CREATE_CLAIMS_TICKET_REQUEST: 'CREATE_CLAIMS_TICKET_REQUEST',
  CREATE_CLAIMS_TICKET_SUCCESS: 'CREATE_CLAIMS_TICKET_SUCCESS',
  CREATE_CLAIMS_TICKET_FAILED: 'CREATE_CLAIMS_TICKET_FAILED',

  CREATE_CLAIMS_DOCUMENTS_REQUEST: 'CREATE_CLAIMS_DOCUMENTS_REQUEST',
  CREATE_CLAIMS_DOCUMENTS_SUCCESS: 'CREATE_CLAIMS_DOCUMENTS_SUCCESS',
  CREATE_CLAIMS_DOCUMENTS_FAILED: 'CREATE_CLAIMS_DOCUMENTS_FAILED',

  CREATE_CLAIMS_EMPLOYEES_REQUEST: 'CREATE_CLAIMS_EMPLOYEES_REQUEST',
  CREATE_CLAIMS_EMPLOYEES_SUCCESS: 'CREATE_CLAIMS_EMPLOYEES_SUCCESS',
  CREATE_CLAIMS_EMPLOYEES_FAILED: 'CREATE_CLAIMS_EMPLOYEES_FAILED',

  UPLOAD_CLAIMS_DOCUMENTS_REQUEST: 'UPLOAD_CLAIMS_DOCUMENTS_REQUEST',
  UPLOAD_CLAIMS_DOCUMENTS_SUCCESS: 'UPLOAD_CLAIMS_DOCUMENTS_SUCCESS',
  UPLOAD_CLAIMS_DOCUMENTS_FAILED: 'UPLOAD_CLAIMS_DOCUMENTS_FAILED',

  ADD_CLAIMS_PACKAGES_REQUEST: 'ADD_CLAIMS_PACKAGES_REQUEST',
  ADD_CLAIMS_PACKAGES_SUCCESS: 'ADD_CLAIMS_PACKAGES_SUCCESS',
  ADD_CLAIMS_PACKAGES_FAILED: 'ADD_CLAIMS_PACKAGES_FAILED',

  UPDATE_CLAIMS_STATUS_REQUEST: 'UPDATE_CLAIMS_STATUS_REQUEST',
  UPDATE_CLAIMS_STATUS_SUCCESS: 'UPDATE_CLAIMS_STATUS_SUCCESS',
  UPDATE_CLAIMS_STATUS_FAILED: 'UPDATE_CLAIMS_STATUS_FAILED',

  UPLOAD_MULTIPLE_TNS_REQUEST: 'UPLOAD_MULTIPLE_TNS_REQUEST',
  UPLOAD_MULTIPLE_TNS_SUCCESS: 'UPLOAD_MULTIPLE_TNS_SUCCESS',
  UPLOAD_MULTIPLE_TNS_FAILED: 'UPLOAD_MULTIPLE_TNS_FAILED',

  RESET_ACTION_TYPE: 'RESET_ACTION_TYPE',
  RESET_INITIAL_STATE: 'RESET_INITIAL_STATE',
};

export const actionCreators = {
  getClaimsParameters: () => ({
    type: actionTypes.GET_CLAIMS_PARAMETERS_REQUEST,
  }),
  validateClaimsPackage: (body: object) => ({
    type: actionTypes.VALIDATE_CLAIMS_PACKAGE_REQUEST,
    body,
  }),
  resetActionType: () => ({
    type: actionTypes.RESET_ACTION_TYPE,
  }),
  createClaimsTicket: (body: object) => ({
    type: actionTypes.CREATE_CLAIMS_TICKET_REQUEST,
    body,
  }),
  createClaimsDocuments: (body: object) => ({
    type: actionTypes.CREATE_CLAIMS_DOCUMENTS_REQUEST,
    body,
  }),
  addClaimsPackages: (body: object) => ({
    type: actionTypes.ADD_CLAIMS_PACKAGES_REQUEST,
    body,
  }),
  createClaimsEmployees: (body: object) => ({
    type: actionTypes.CREATE_CLAIMS_EMPLOYEES_REQUEST,
    body,
  }),
  updateClaimsStatus: (body: object) => ({
    type: actionTypes.UPDATE_CLAIMS_STATUS_REQUEST,
    body,
  }),
  uploadMultipleTns: (body: object) => ({
    type: actionTypes.UPLOAD_MULTIPLE_TNS_REQUEST,
    body,
  }),
  resetInitialState: () => ({
    type: actionTypes.RESET_INITIAL_STATE,
  }),
};

const initialState: ClaimsState = {
  isLoading: false,
  claimsParameters: {
    hubs: [],
    claims_reasons: [],
    employee_type: [],
    employee_department: [],
    package_location: [],
    incident_occurrence: [],
    supporting_documents: [],
  },
  actionType: '',
  validationMessage: {
    message: '',
    invalid_tracking_numbers: [],
    last_tracking_number: '',
  },
  multipleTnValidation: {
    valid_tracking_numbers: [],
    last_tracking_number: '',
    invalid_tracking_numbers: [],
  },
  claimsReferenceNumber: '',
  claimsDocuments: {
    claims_reference_number: '',
    added_claim_supporting_docs: [],
  },
};

const reducer: Reducer<ClaimsState> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CLAIMS_PARAMETERS_REQUEST:
    case actionTypes.VALIDATE_CLAIMS_PACKAGE_REQUEST:
    case actionTypes.ADD_CLAIMS_PACKAGES_REQUEST:
    case actionTypes.CREATE_CLAIMS_TICKET_REQUEST:
    case actionTypes.CREATE_CLAIMS_DOCUMENTS_REQUEST:
    case actionTypes.CREATE_CLAIMS_EMPLOYEES_REQUEST:
    case actionTypes.UPLOAD_MULTIPLE_TNS_REQUEST:
      return { ...state, isLoading: true };

    case actionTypes.GET_CLAIMS_PARAMETERS_SUCCESS:
      return { ...state, isLoading: false, claimsParameters: action.data };

    case actionTypes.GET_CLAIMS_PARAMETERS_FAILED:
      return {
        ...state,
        isLoading: false,
        claimsParameters: initialState.claimsParameters,
      };

    case actionTypes.VALIDATE_CLAIMS_PACKAGE_SUCCESS:
      return {
        ...state,
        actionType: action.type,
        validationMessage: action.data,
        isLoading: false,
      };

    case actionTypes.VALIDATE_CLAIMS_PACKAGE_FAILED:
      return {
        ...state,
        actionType: action.type,
        validationMessage: initialState.validationMessage,
        isLoading: false,
      };

    case actionTypes.CREATE_CLAIMS_TICKET_SUCCESS:
      return {
        ...state,
        actionType: action.type,
        isLoading: false,
        claimsReferenceNumber: action.data,
      };

    case actionTypes.CREATE_CLAIMS_TICKET_FAILED:
      return {
        ...state,
        actionType: action.type,
        isLoading: false,
        claimsReferenceNumber: initialState.claimsReferenceNumber,
      };

    case actionTypes.CREATE_CLAIMS_DOCUMENTS_SUCCESS:
      return {
        ...state,
        actionType: action.type,
        isLoading: false,
        claimsDocuments: action.data,
      };

    case actionTypes.CREATE_CLAIMS_DOCUMENTS_FAILED:
      return {
        ...state,
        actionType: action.type,
        isLoading: false,
        claimsDocuments: initialState.claimsDocuments,
      };

    case actionTypes.CREATE_CLAIMS_EMPLOYEES_SUCCESS:
      return { ...state, actionType: action.type, isLoading: false };

    case actionTypes.CREATE_CLAIMS_EMPLOYEES_FAILED:
      return { ...state, actionType: action.type, isLoading: false };

    case actionTypes.UPLOAD_MULTIPLE_TNS_SUCCESS:
      return {
        ...state,
        actionType: action.type,
        isLoading: false,
        multipleTnValidation: action.data,
      };

    case actionTypes.UPLOAD_MULTIPLE_TNS_FAILED:
      return {
        ...state,
        actionType: action.type,
        isLoading: false,
        multipleTnValidation: initialState.multipleTnValidation,
      };

    case actionTypes.ADD_CLAIMS_PACKAGES_SUCCESS:
      return { ...state, actionType: action.type, isLoading: false };

    case actionTypes.ADD_CLAIMS_PACKAGES_FAILED:
      return { ...state, actionType: action.type, isLoading: false };

    case actionTypes.RESET_INITIAL_STATE:
      return { ...initialState };

    case actionTypes.RESET_ACTION_TYPE:
      return { ...state, actionType: '' };
    default:
      return state;
  }
};

export default reducer;
