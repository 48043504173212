// checks for both booking number and tracking number syntax
const trackingNumberRegex = /^[a-zA-Z0-9]{4}-[a-zA-Z0-9-]+$/;
const bookingNumberRegex = /^[a-zA-Z0-9-]+$/;
const waybillTrackingRegex = /^[a-zA-Z0-9]{4}[Ee]-[a-zA-Z0-9-]+$/;
const searchCharRegex = /^[a-zA-Z0-9\s-]+$/;

export const isBookingNumber = (bookingNumber) => {
  if (bookingNumber && bookingNumber.match(bookingNumberRegex)) {
    return true;
  }
  return false;
};

export const isTrackingNumber = (trackingNumber) => {
  if (trackingNumber && trackingNumber.match(trackingNumberRegex)) {
    return true;
  }
  return false;
};

export const isWaybillTrackingNumber = (waybillTracking) => {
  if (waybillTracking && waybillTracking.match(waybillTrackingRegex)) {
    return true;
  }
  return false;
};

export const isValidRefNumber = (refNumber) => {
  if (!refNumber) return true; // allow blank
  if (
    refNumber &&
    (isBookingNumber(refNumber) ||
      isTrackingNumber(refNumber) ||
      isWaybillTrackingNumber(refNumber))
  ) {
    return true;
  }
  return false;
};

export const isValidSearchChar = (keyword) => {
  if (keyword && keyword.match(searchCharRegex)) {
    return true;
  }
  return false;
};
