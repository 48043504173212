/* eslint-disable @typescript-eslint/no-explicit-any */
import { all, call, takeLatest, put } from 'redux-saga/effects';
import * as bulmaToast from 'bulma-toast';
import { GetConfigurationParams, UpdateConfigurationParams } from './interface';
import { actionTypes } from './reducer';
import {
  getConfiguration as getConfigurationService,
  updateConfiguration as updateConfigurationService,
  getAllHubs as getAllHubsService,
} from './service';

function* getConfiguration({
  serviceName,
}: GetConfigurationParams): Generator<any> {
  try {
    const data = yield call(getConfigurationService, serviceName);
    yield put({ type: actionTypes.GET_CONFIGURATION_SUCCESS, data });
  } catch (err) {
    yield put({ type: actionTypes.GET_CONFIGURATION_FAILED, err });
  }
}

function* updateConfiguration({
  body,
}: UpdateConfigurationParams): Generator<any> {
  try {
    const data = yield call(updateConfigurationService, body);
    yield put({ type: actionTypes.UPDATE_CONFIGURATION_SUCCESS, data });
    bulmaToast.toast({
      message: 'Update Successful',
      type: 'is-success',
      position: 'bottom-right',
      dismissible: true,
      duration: 10000,
      closeOnClick: true,
    });
  } catch (err) {
    bulmaToast.toast({
      message: `${err.message}: ${err.details}`,
      type: 'is-danger',
      position: 'bottom-right',
      dismissible: true,
      duration: 10000,
      closeOnClick: true,
    });
    yield put({ type: actionTypes.UPDATE_CONFIGURATION_FAILED, err });
  }
}

function* getAllHubs(): Generator<any> {
  try {
    const data = yield call(getAllHubsService);
    yield put({ type: actionTypes.GET_ALL_HUBS_SUCCESS, data });
  } catch (err) {
    yield put({ type: actionTypes.GET_ALL_HUBS_FAILED, err });
  }
}

function* watchBagsRequest() {
  yield all([
    takeLatest(actionTypes.GET_CONFIGURATION_REQUEST, getConfiguration),
  ]);
  yield all([
    takeLatest(actionTypes.UPDATE_CONFIGURATION_REQUEST, updateConfiguration),
  ]);

  yield all([takeLatest(actionTypes.GET_ALL_HUBS_REQUEST, getAllHubs)]);
}

export default [watchBagsRequest()];
