import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import routes from './routes';
import NotFound from '../commons/error';
import PublicRoute from './Public';
import PrivateRoute from './Private';
import CacheBuster from '../commons/cachebuster';
import { RouteProps, CacheProps } from './interface';
import epod from '../commons/epod';
import Maintenance from '../commons/maintenance';

const Routes = () => {
  const envMaintenance = process.env.REACT_APP_SITE_MAINTENANCE === 'true';

  return (
    <Router>
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }: CacheProps) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
          }
          return (
            <>
              {envMaintenance ? (
                <Maintenance />
              ) : (
                <Switch>
                  {routes.map((route: RouteProps, i) => {
                    if (route.authL) {
                      return <PrivateRoute key={route.path} {...route} />;
                    }
                    return <PublicRoute key={route.path} {...route} />;
                  })}
                  <Redirect from="/" to="/login" exact />
                  <Route path="/epod" component={epod} exact />
                  <Route path="/maintenance" component={Maintenance} exact />
                  <Route path="*" component={NotFound} />
                </Switch>
              )}
            </>
          );
        }}
      </CacheBuster>
    </Router>
  );
};

export default Routes;