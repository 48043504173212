/* eslint-disable @typescript-eslint/no-explicit-any */
import { all, call, takeLatest, put } from 'redux-saga/effects';
import * as bulmaToast from 'bulma-toast';
import { actionTypes } from './reducer';
import {
  getAllUsers as getAllUsersService,
  getUsersSearch as getUsersSearchService,
  updateUser as updateUserService,
  deactivateUser as deactivateUserService,
  getAllPortCodes as getAllPortCodesService,
} from './service';
import {
  UsersParams,
  UsersSearchParams,
  UpdateUserSaga,
  DeactivateUserSaga,
} from './interface';

function* getAllUsers({ page, limit }: UsersParams): Generator<any> {
  try {
    const data = yield call(getAllUsersService, page, limit);
    yield put({ type: actionTypes.GET_ALL_USERS_SUCCESS, data });
  } catch (err) {
    yield put({ type: actionTypes.GET_ALL_USERS_FAILED, err });
  }
}

function* getUsersSearch({
  page,
  limit,
  searchFilter,
}: UsersSearchParams): Generator<any> {
  try {
    const data = yield call(getUsersSearchService, page, limit, searchFilter);
    yield put({ type: actionTypes.GET_USERS_SEARCH_SUCCESS, data });
  } catch (err) {
    bulmaToast.toast({
      message: `${err.message}: ${err.details}`,
      type: 'is-danger',
      position: 'top-center',
      dismissible: true,
      duration: 10000,
      closeOnClick: true,
    });
    yield put({ type: actionTypes.GET_USERS_SEARCH_FAILED, err });
  }
}

function* updateUser({ body, limit }: UpdateUserSaga): Generator<any> {
  try {
    const data = yield call(updateUserService, body);
    yield put({ type: actionTypes.UPDATE_USER_SUCCESS, data });
    yield put({ type: actionTypes.GET_ALL_USERS_REQUEST, page: 1, limit });
    bulmaToast.toast({
      message: 'Update Successful',
      type: 'is-success',
      position: 'top-center',
      dismissible: true,
      duration: 10000,
      closeOnClick: true,
    });
  } catch (err) {
    bulmaToast.toast({
      message: `${err.message}: ${err.details}`,
      type: 'is-danger',
      position: 'top-center',
      dismissible: true,
      duration: 10000,
      closeOnClick: true,
    });
    yield put({ type: actionTypes.UPDATE_USER_FAILED, err });
  }
}

function* deactivateUser({ body }: DeactivateUserSaga): Generator<any> {
  try {
    const data = yield call(deactivateUserService, body);
    yield put({ type: actionTypes.DEACTIVATE_USER_SUCCESS, data });
    yield put({ type: actionTypes.GET_ALL_USERS_REQUEST, page: 1, limit: 10 });
    bulmaToast.toast({
      message: 'Deactivated Successfully',
      type: 'is-success',
      position: 'top-center',
      dismissible: true,
      duration: 10000,
      closeOnClick: true,
    });
  } catch (err) {
    bulmaToast.toast({
      message: `${err.message}: ${err.details}`,
      type: 'is-danger',
      position: 'top-center',
      dismissible: true,
      duration: 10000,
      closeOnClick: true,
    });
    yield put({ type: actionTypes.DEACTIVATE_USER_FAILED, err });
  }
}

function* getAllPortCodes(): Generator<any> {
  try {
    const data = yield call(getAllPortCodesService);
    yield put({ type: actionTypes.GET_ALL_PORTCODES_SUCCESS, data });
  } catch (err) {
    yield put({ type: actionTypes.GET_ALL_PORTCODES_FAILED, err });
  }
}

function* watchUsersRequest() {
  yield all([
    takeLatest(actionTypes.GET_ALL_USERS_REQUEST, getAllUsers),
    takeLatest(actionTypes.GET_USERS_SEARCH_REQUEST, getUsersSearch),
    takeLatest(actionTypes.UPDATE_USER_REQUEST, updateUser),
    takeLatest(actionTypes.DEACTIVATE_USER_REQUEST, deactivateUser),
    takeLatest(actionTypes.GET_ALL_PORTCODES_REQUEST, getAllPortCodes),
  ]);
}

export default [watchUsersRequest()];
