/* eslint-disable import/no-mutable-exports */
/* eslint-disable @typescript-eslint/no-explicit-any */
import DevStore from './store.dev';
import ProdStore from './store.prod';

interface Store {
  store: any;
  persistor: any;
}

let store: Store;

if (process.env.NODE_ENV !== 'production') {
  store = DevStore();
} else {
  store = ProdStore();
}

export default store;
