import { Reducer } from 'redux';
import { extendSlaStatus } from '../../utils/constants';
import { SingleExtensionObject, SlaExtensionState } from './interface';

export const actionTypes = {
  GET_SINGLE_SLA_EXTENSION_REQUEST: 'GET_SINGLE_SLA_EXTENSION_REQUEST',
  GET_SINGLE_SLA_EXTENSION_SUCCESS: 'GET_SINGLE_SLA_EXTENSION_SUCCESS',
  GET_SINGLE_SLA_EXTENSION_FAILED: 'GET_SINGLE_SLA_EXTENSION_FAILED',

  EXTEND_SINGLE_SLA_REQUEST: 'EXTEND_SINGLE_SLA_REQUEST',
  EXTEND_SINGLE_SLA_SUCCESS: 'EXTEND_SINGLE_SLA_SUCCESS',
  EXTEND_SINGLE_SLA_FAILED: 'EXTEND_SINGLE_SLA_FAILED',

  EXTEND_BULK_SLA_REQUEST: 'EXTEND_BULK_SLA_REQUEST',
  EXTEND_BULK_SLA_SUCCESS: 'EXTEND_BULK_SLA_SUCCESS',
  EXTEND_BULK_SLA_FAILED: 'EXTEND_BULK_SLA_FAILED',

  GET_SLA_EXTENSION_FILTERED_REQUEST: 'GET_SLA_EXTENSION_FILTERED_REQUEST',
  GET_SLA_EXTENSION_FILTERED_SUCCESS: 'GET_SLA_EXTENSION_FILTERED_SUCCESS',
  GET_SLA_EXTENSION_FILTERED_FAILED: 'GET_SLA_EXTENSION_FILTERED_FAILED',

  SET_CACHED_SLA_EXTENSION: 'SET_CACHED_SLA_EXTENSION',

  DELETE_SLA_EXTENSION: 'DELETE_SLA_EXTENSION',
  DELETE_SLA_EXTENSION_SUCCESS: 'DELETE_SLA_EXTENSION_SUCCESS',
  DELETE_SLA_EXTENSION_FAILED: 'DELETE_SLA_EXTENSION_FAILED',
};

export const actionCreators = {
  getSingleSlaExtension: (trackingNumber: string) => ({
    type: actionTypes.GET_SINGLE_SLA_EXTENSION_REQUEST,
    trackingNumber,
  }),
  extendSingleSla: (body: SingleExtensionObject) => ({
    type: actionTypes.EXTEND_SINGLE_SLA_REQUEST,
    body,
  }),
  extendBulkSla: (body: FormData) => ({
    type: actionTypes.EXTEND_BULK_SLA_REQUEST,
    body,
  }),
  getSlaExtensionDataFiltered: (
    page: number,
    limit: number,
    createdDateStart: string,
    createdDateEnd: string
  ) => ({
    type: actionTypes.GET_SLA_EXTENSION_FILTERED_REQUEST,
    page,
    limit,
    createdDateStart,
    createdDateEnd,
  }),
  setCachedSlaExtensionData: (slaExtensionData: object[]) => ({
    type: actionTypes.SET_CACHED_SLA_EXTENSION,
    slaExtensionData,
  }),
  deleteSlaExtension: (id: number) => ({
    type: actionTypes.DELETE_SLA_EXTENSION,
    id,
  }),
};

const initialState: SlaExtensionState = {
  isLoading: false,
  extendSingleSlaStatus: '',
  singleExtension: {
    trackingNumber: '',
    daysAdded: -1,
    reason: '',
    direction: '',
    addedBy: '',
  },
  bulkExtensionData: {
    totalProcessed: -1,
    success: -1,
    invalid: {
      count: -1,
      details: {},
    },
  },
  filters: { startDate: '', endDate: '' },
  slaExtensionData: [],
  cachedSlaExtensionData: [],
  pageCount: 0,
  meta: {
    total: 0,
    hasNext: false,
    hasPrevious: false,
    nextPage: 0,
    currentPage: 0,
    previousPage: 0,
    limit: 0,
  },
};

const reducer: Reducer<SlaExtensionState> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_SLA_EXTENSION_FILTERED_REQUEST:
    case actionTypes.EXTEND_SINGLE_SLA_REQUEST:
      return {
        ...state,
        extendSingleSlaStatus: extendSlaStatus.PROCESSING,
        isLoading: true,
      };
    case actionTypes.EXTEND_BULK_SLA_REQUEST:
      return {
        ...state,
        bulkExtensionData: initialState.bulkExtensionData,
        isLoading: true,
      };
    case actionTypes.GET_SINGLE_SLA_EXTENSION_REQUEST:
      return { ...state, isLoading: true };

    case actionTypes.GET_SINGLE_SLA_EXTENSION_SUCCESS:
      return {
        ...state,
        singleExtension: action.data,
        isLoading: false,
      };
    case actionTypes.GET_SINGLE_SLA_EXTENSION_FAILED:
      return {
        ...state,
        singleExtension: state.singleExtension,
        isLoading: false,
      };

    case actionTypes.EXTEND_SINGLE_SLA_SUCCESS:
      return {
        ...state,
        extendSingleSlaStatus: extendSlaStatus.SUCCESS,
        isLoading: false,
      };
    case actionTypes.EXTEND_SINGLE_SLA_FAILED:
      return {
        ...state,
        extendSingleSlaStatus: extendSlaStatus.FAILED,
        isLoading: false,
      };

    case actionTypes.EXTEND_BULK_SLA_SUCCESS:
      return { ...state, bulkExtensionData: action.data, isLoading: false };
    case actionTypes.EXTEND_BULK_SLA_FAILED:
      return {
        ...state,
        bulkExtensionData: initialState.bulkExtensionData,
        isLoading: false,
      };

    case actionTypes.GET_SLA_EXTENSION_FILTERED_SUCCESS:
      return {
        ...state,
        slaExtensionData: action.data.slaExtensionList,
        meta: action.data.metadata,
        cachedSlaExtensionData: action.data.cachedSlaExtensionData,
        pageCount: action.data.pageCount,
        isLoading: false,
      };

    case actionTypes.GET_SLA_EXTENSION_FILTERED_FAILED:
      return {
        ...state,
        slaExtensionData: initialState.slaExtensionData,
        meta: initialState.meta,
        cachedSlaExtensionData: initialState.cachedSlaExtensionData,
        pageCount: initialState.pageCount,
        isLoading: false,
      };
    case actionTypes.SET_CACHED_SLA_EXTENSION:
      return {
        ...state,
        slaExtensionData: action.slaExtensionData,
        isLoading: false,
      };

    case actionTypes.DELETE_SLA_EXTENSION:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.DELETE_SLA_EXTENSION_SUCCESS:
      return {
        ...state,
        slaExtensionData: action.slaExtensionData,
        isLoading: false,
      };

    case actionTypes.DELETE_SLA_EXTENSION_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default reducer;
