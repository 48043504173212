import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { semverGreaterThan } from '../commons/cachebuster';
import { actionCreators as authActionCreators } from '../modules/auth/reducer';
import { deleteToken } from './token';

export const useCheckVersion = (props: string = '') => {
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (props !== '/maintenance') {
      fetch(`/meta.json?${new Date().getTime()}`, { cache: 'no-cache' })
        .then((response) => response.json())
        .then((meta) => {
          const latestVersion = meta.version;
          const currentVersion = global.appVersion;
          const shouldForceRefresh = semverGreaterThan(
            latestVersion,
            currentVersion
          );

          if (shouldForceRefresh) {
            dispatch(authActionCreators.logout());

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            caches.keys().then((names: any) => {
              for (const name of names) caches.delete(name);
            });

            //! If for some reason the tokens aren't deleted after
            //! the clearing cache, run the code below to delete
            //! the tokens
            if (localStorage.getItem('entregoTKN') &&
                  localStorage.getItem('entregoTKNRefresh')
            ) {
              deleteToken();
            }

            window.location.reload();
          }
        });
    }
  }, [dispatch, props]);
};


