import { combineReducers } from 'redux';

import auth from '../modules/auth/reducer';
import reports from '../modules/reports/reducer';
import orders from '../modules/orders/reducer';
import users from '../modules/users/reducer';
import tools from '../modules/tools/reducer';
import bagging from '../modules/bagging/reducer';
import configuration from '../modules/configuration/reducer';
import slaleadtime from '../modules/slaleadtime/reducer';
import claims from '../modules/claims/reducer';
import slaextension from '../modules/slaextension/reducer';
import barangayCluster from '../modules/barangay-cluster/reducer';
import hmsdashboard from '../modules/hmsdashboard/reducer';

const rootReducer = combineReducers({
  auth,
  orders,
  reports,
  users,
  tools,
  bagging,
  configuration,
  slaleadtime,
  claims,
  slaextension,
  barangayCluster,
  hmsdashboard,
});

export type ApplicationState = ReturnType<typeof rootReducer>;

export default rootReducer;
