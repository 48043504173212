import { Reducer } from 'redux';
import { DashboardState } from './interface';

export const actionTypes = {
  GET_DASHBOARD_MVP_DATA_REQUEST: 'GET_DASHBOARD_MVP_DATA_REQUEST',
  GET_DASHBOARD_MVP_DATA_REQUEST_SUCCESS:
    'GET_DASHBOARD_MVP_DATA_REQUEST_SUCCESS',
  GET_DASHBOARD_MVP_DATA_REQUEST_FAILED:
    'GET_DASHBOARD_MVP_DATA_REQUEST_FAILED',
};
export const actionCreators = {
  getDashboardMvpData: () => ({
    type: actionTypes.GET_DASHBOARD_MVP_DATA_REQUEST,
  }),
};

const initialState: DashboardState = {
  dashboardData: {
    dashboardBodyData: [],
    dashboardHeaderData: [],
  },
  isLoading: false,
};

const reducer: Reducer<DashboardState> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_DASHBOARD_MVP_DATA_REQUEST:
      return { ...state, isLoading: true };
    case actionTypes.GET_DASHBOARD_MVP_DATA_REQUEST_SUCCESS:
      return { ...state, isLoading: false, dashboardData: action.payload };
    case actionTypes.GET_DASHBOARD_MVP_DATA_REQUEST_FAILED:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

export default reducer;
