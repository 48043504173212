import request from '../../utils/api';

// Orders View

export const getAllOrders = (
  page: number,
  limit: number,
  date: string,
  lastDate?: string
) =>
  request(`hubm/orders/overview?page=${page}
  &limit=${limit}&date=${date}${lastDate ? `&lastDate=${lastDate}` : ''}`);

export const getAllFilters = () => request('hubm/orders/filters');

export const getOrdersFiltered = (
  page: number,
  limit: number,
  trackingNumber: string,
  bookingNumber: string,
  orderStatus: string[],
  paymentMethod: string[],
  destinationHub: string[],
  pickupHub: string[],
  client: string[],
  startDate?: Date | string | null,
  endDate?: Date | string | null,
  lastDate?: string
) => {
  return request('hubm/orders/filter', {
    method: 'POST',
    body: {
      page,
      limit,
      trackingNumber,
      bookingNumber,
      orderStatus,
      paymentMethod,
      destinationHub,
      pickupHub,
      client,
      startDate,
      endDate,
      lastDate,
    },
  });
};

// export const getOrdersFilteredClientRef = (
//   page: number,
//   limit: number,
//   clientReferenceNumbers: string[],
// ) => {
//   return request('hubm/orders/filter/clientReferenceNumbers', {
//     method: 'POST',
//     body: {
//       page,
//       limit,
//       clientReferenceNumbers,
//     },
//   });
// };

export const getOrdersFilteredMultiRef = (
  page: number,
  limit: number,
  trackingNumbers: string[],
  lastDate?: string
) => {
  return request('hubm/orders/filter/trackingNumbers', {
    method: 'POST',
    body: {
      page,
      limit,
      trackingNumbers,
      lastDate,
    },
  });
};

// Order Details View

export const getOrder = (trackingNumber: string) =>
  request(`hubm/orders/${trackingNumber}/details`);

export const getOrderHistory = (trackingNumber: string) =>
  request(`hubm/orders/${trackingNumber}/history`);

export const pushOrdersToFareye = (trackingNumbers: string[]) =>
  request('hubm/orders/repush', {
    method: 'POST',
    body: { trackingNumbers },
  });

export const generateWaybill = (body: object) =>
  request('hubm/waybills/generate', {
    method: 'POST',
    body,
  });

export const getWaybillHistory = (trackingNumber: string) =>
  request(`hubm/waybills/generate/${trackingNumber}/history`);

export const addDisposition = (body: object) =>
  request('hubm/disposition/', {
    method: 'POST',
    body,
  });

export const getDispositionHistory = (trackingNumber: string) =>
  request(`hubm/disposition/${trackingNumber}`);

export const getFiltersDownloadReport = (
  trackingNumber: string[],
  bookingNumber: string,
  orderStatus: string[],
  paymentMethod: string[],
  destinationHub: string[],
  pickupHub: string[],
  client: string[],
  startDate?: Date | string | null,
  endDate?: Date | string | null,
  lastDate?: string
) => {
  return request(
    'hubm/orders/report',
    {
      method: 'POST',
      body: {
        reportType: 'ORDER_FILTERED_REPORT',
        filters: {
          trackingNumber,
          bookingNumber,
          orderStatus,
          paymentMethod,
          destinationHub,
          pickupHub,
          client,
          startDate,
          endDate,
          lastDate,
        },
      },
    },
    'blob'
  );
};

export const pollFiltersDownloadReport = (id: string) =>
  request(`hubm/orders/report?reportId=${id}`);

export const validateOtpUser = (trackingNumber: string) =>
  request(`hubm/otp/validate?trackingNumber=${trackingNumber}`, {
    method: 'POST',
  });

export const resendOtpRequest = (trackingNumber: string) =>
  request(`hubm/otp?trackingNumber=${trackingNumber}`, {
    method: 'POST',
  });
