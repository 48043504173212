import { Reducer } from 'redux';
import { OrderState } from './interface';

export const actionTypes = {
  GET_ALL_ORDERS_REQUEST: 'GET_ORDERS_ALL_REQUEST',
  GET_ALL_ORDERS_SUCCESS: 'GET_ORDERS_ALL_SUCCESS',
  GET_ALL_ORDERS_FAILED: 'GET_ORDERS_ALL_FAILED',
  SET_CACHED_ORDERS: 'SET_CACHED_ORDERS',

  RESET_ORDERS: 'RESET_ORDERS',

  GET_ALL_FILTERS_REQUEST: 'GET_ALL_FILTERS_REQUEST',
  GET_ALL_FILTERS_SUCCESS: 'GET_ALL_FILTERS_SUCCESS',
  GET_ALL_FILTERS_FAILED: 'GET_ALL_FILTERS_FAILED',

  GET_ORDERS_FILTERED_REQUEST: 'GET_ORDERS_FILTERED_REQUEST',
  GET_ORDERS_FILTERED_SUCCESS: 'GET_ORDERS_FILTERED_SUCCESS',
  GET_ORDERS_FILTERED_FAILED: 'GET_ORDERS_FILTERED_FAILED',

  GET_ORDERS_FILTERED_CLIENTREF_REQUEST:
    'GET_ORDERS_FILTERED_CLIENTREF_REQUEST',
  GET_ORDERS_FILTERED_CLIENTREF_SUCCESS:
    'GET_ORDERS_FILTERED_CLIENTREF_SUCCESS',
  GET_ORDERS_FILTERED_CLIENTREF_FAILED: 'GET_ORDERS_FILTERED_CLIENTREF_FAILED',

  GET_ORDERS_FILTERED_MULTI_REQUEST: 'GET_ORDERS_FILTERED_MULTI_REQUEST',
  GET_ORDERS_FILTERED_MULTI_SUCCESS: 'GET_ORDERS_FILTERED_MULTI_SUCCESS',
  GET_ORDERS_FILTERED_MULTI_FAILED: 'GET_ORDERS_FILTERED_MULTI_FAILED',

  GET_ORDER_REQUEST: 'GET_ORDER_REQUEST',
  GET_ORDER_SUCCESS: 'GET_ORDER_SUCCESS',
  GET_ORDER_FAILED: 'GET_ORDER_FAILED',

  GET_FILTERS_DOWNLOAD_REPORT_REQUEST: 'GET_FILTERS_DOWNLOAD_REPORT_REQUEST',
  GET_FILTERS_DOWNLOAD_REPORT_SUCCESS: 'GET_FILTERS_DOWNLOAD_REPORT_SUCCESS',
  GET_FILTERS_DOWNLOAD_REPORT_FAILED: 'GET_FILTERS_DOWNLOAD_REPORT_FAILED',

  POLL_FILTERS_DOWNLOAD_REPORT_REQUEST: 'POLL_FILTERS_DOWNLOAD_REPORT_REQUEST',
  POLL_FILTERS_DOWNLOAD_REPORT_SUCCESS: 'POLL_FILTERS_DOWNLOAD_REPORT_SUCCESS',
  POLL_FILTERS_DOWNLOAD_REPORT_FAILED: 'POLL_FILTERS_DOWNLOAD_REPORT_FAILED',

  GET_ORDER_HISTORY_REQUEST: 'GET_ORDER_HISTORY_REQUEST',
  GET_ORDER_HISTORY_SUCCESS: 'GET_ORDER_HISTORY_SUCCESS',
  GET_ORDER_HISTORY_FAILED: 'GET_ORDER_HISTORY_FAILED',

  GET_WAYBILL_HISTORY_REQUEST: 'GET_WAYBILL_HISTORY_REQUEST',
  GET_WAYBILL_HISTORY_SUCCESS: 'GET_WAYBILL_HISTORY_SUCCESS',
  GET_WAYBILL_HISTORY_FAILED: 'GET_WAYBILL_HISTORY_FAILED',

  PUSH_ORDERS_TO_FAREYE_REQUEST: 'PUSH_ORDERS_TO_FAREYE_REQUEST',
  PUSH_ORDERS_TO_FAREYE_SUCCESS: 'PUSH_ORDERS_TO_FAREYE_SUCCESS',
  PUSH_ORDERS_TO_FAREYE_FAILED: 'PUSH_ORDERS_TO_FAREYE_FAILED',

  GENERATE_WAYBILL_REQUEST: 'GENERATE_WAYBILL_REQUEST',
  GENERATE_WAYBILL_SUCCESS: 'GENERATE_WAYBILL_SUCCESS',
  GENERATE_WAYBILL_FAILED: 'GENERATE_WAYBILL_FAILED',

  ADD_DISPOSITION_REQUEST: 'ADD_DISPOSITION_REQUEST',
  ADD_DISPOSITION_SUCCESS: 'ADD_DISPOSITION_SUCCESS',
  ADD_DISPOSITION_FAILED: 'ADD_DISPOSITION_FAILED',

  GET_DISPOSITION_HISTORY_REQUEST: 'GET_DISPOSITION_HISTORY_REQUEST',
  GET_DISPOSITION_HISTORY_SUCCESS: 'GET_DISPOSITION_HISTORY_SUCCESS',
  GET_DISPOSITION_HISTORY_FAILED: 'GET_DISPOSITION_HISTORY_FAILED',

  SET_SEARCH_REFERENCE: 'SET_SEARCH_REFERENCE',

  RESET_ACTION_TYPE: 'RESET_ACTION_TYPE',

  VALIDATE_OTP_USER_REQUEST: 'VALIDATE_OTP_USER_REQUEST',
  VALIDATE_OTP_USER_SUCCESS: 'VALIDATE_OTP_USER_SUCCESS',
  VALIDATE_OTP_USER_FAILED: 'VALIDATE_OTP_USER_FAILED',

  RESEND_OTP_REQUEST: 'RESEND_OTP_REQUEST',
  RESEND_OTP_REQUEST_SUCCESS: 'RESEND_OTP_REQUEST_SUCCESS',
  RESEND_OTP_REQUEST_FAILED: 'RESEND_OTP_REQUEST_FAILED',
  RESET_OTP_ATTEMPT: 'RESET_OTP_ATTEMPT',

  GET_DELIVERY_SIGNATURE: 'GET_DELIVERY_SIGNATURE',
  GET_DELIVERY_SIGNATURE_SUCCESS: 'GET_DELIVERY_SIGNATURE_SUCCESS',

  GET_DELIVERY_IMAGE: 'GET_DELIVERY_IMAGE',
  GET_DELIVERY_IMAGE_SUCCESS: 'GET_DELIVERY_IMAGE_SUCCESS',
};

export const actionCreators = {
  getAllOrders: (
    page: number,
    limit: number,
    date: string,
    lastDate?: string
  ) => ({
    type: actionTypes.GET_ALL_ORDERS_REQUEST,
    page,
    limit,
    date,
    lastDate,
  }),
  getAllFilters: () => ({ type: actionTypes.GET_ALL_FILTERS_REQUEST }),
  getAllOrdersFiltered: (
    page: number,
    limit: number,
    trackingNumber: string,
    bookingNumber: string,
    orderStatus: string[],
    paymentMethod: string[],
    destinationHub: string[],
    pickupHub: string[],
    client: string[],
    date?: [Date | null, Date | null],
    startDate?: Date | string | null,
    endDate?: Date | string | null,
    lastDate?: string
  ) => ({
    type: actionTypes.GET_ORDERS_FILTERED_REQUEST,
    page,
    limit,
    trackingNumber,
    bookingNumber,
    orderStatus,
    paymentMethod,
    destinationHub,
    pickupHub,
    client,
    date,
    startDate,
    endDate,
    lastDate,
  }),
  getAllOrdersFilteredClientRef: (
    page: number,
    limit: number,
    clientReferenceNumbers: string[]
  ) => ({
    type: actionTypes.GET_ORDERS_FILTERED_CLIENTREF_REQUEST,
    page,
    limit,
    clientReferenceNumbers,
  }),
  getAllOrdersFilteredMultiRef: (
    page: number,
    limit: number,
    trackingNumbers: string[],
    lastDate?: string
  ) => ({
    type: actionTypes.GET_ORDERS_FILTERED_MULTI_REQUEST,
    page,
    limit,
    trackingNumbers,
    lastDate,
  }),
  getFiltersDownloadReport: (
    trackingNumber: string[],
    bookingNumber: string,
    orderStatus: string[],
    paymentMethod: string[],
    destinationHub: string[],
    pickupHub: string[],
    client: string[],
    date?: [Date | null, Date | null],
    startDate?: Date | string | null,
    endDate?: Date | string | null,
    lastDate?: string
  ) => ({
    type: actionTypes.GET_FILTERS_DOWNLOAD_REPORT_REQUEST,
    trackingNumber,
    bookingNumber,
    orderStatus,
    paymentMethod,
    destinationHub,
    pickupHub,
    client,
    date,
    startDate,
    endDate,
    lastDate,
  }),
  pollFiltersDownloadReport: (id: string) => ({
    type: actionTypes.POLL_FILTERS_DOWNLOAD_REPORT_REQUEST,
    id,
  }),
  setCachedOrders: (orders: object[]) => ({
    type: actionTypes.SET_CACHED_ORDERS,
    orders,
  }),
  resetOrders: () => ({
    type: actionTypes.RESET_ORDERS,
  }),
  getOrder: (trackingNumber: string) => ({
    type: actionTypes.GET_ORDER_REQUEST,
    trackingNumber,
  }),
  getOrderHistory: (trackingNumber: string) => ({
    type: actionTypes.GET_ORDER_HISTORY_REQUEST,
    trackingNumber,
  }),
  pushOrdersToFareye: (trackingNumbers: string[]) => ({
    type: actionTypes.PUSH_ORDERS_TO_FAREYE_REQUEST,
    trackingNumbers,
  }),
  generateWaybill: (body: object) => ({
    type: actionTypes.GENERATE_WAYBILL_REQUEST,
    body,
  }),
  getWaybillHistory: (trackingNumber: string) => ({
    type: actionTypes.GET_WAYBILL_HISTORY_REQUEST,
    trackingNumber,
  }),
  getDispositionHistory: (trackingNumber: string) => ({
    type: actionTypes.GET_DISPOSITION_HISTORY_REQUEST,
    trackingNumber,
  }),
  addDisposition: (body: object) => ({
    type: actionTypes.ADD_DISPOSITION_REQUEST,
    body,
  }),
  setSearchReference: (searchList: string[], keyValue: string) => ({
    type: actionTypes.SET_SEARCH_REFERENCE,
    searchList,
    keyValue,
  }),
  resetActionType: () => ({
    type: actionTypes.RESET_ACTION_TYPE,
  }),
  validateOtpUser: (trackingNumber: string) => ({
    type: actionTypes.VALIDATE_OTP_USER_REQUEST,
    trackingNumber,
  }),
  resendOtp: (trackingNumber: string) => ({
    type: actionTypes.RESEND_OTP_REQUEST,
    trackingNumber,
  }),
  resetOtpAttempt: () => ({
    type: actionTypes.RESET_OTP_ATTEMPT,
  }),
  getDeliverySignature: (url: string ) => ({
    type: actionTypes.GET_DELIVERY_SIGNATURE,
    url,
  }),
  getDeliveryImage: (url: string ) => ({
    type: actionTypes.GET_DELIVERY_IMAGE,
    url,
  }),
};

const initialState: OrderState = {
  isLoading: false,
  orders: [],
  actionType: '',
  cachedOrders: [],
  meta: {
    previous_page: 0,
    current_page: 0,
    next_page: 0,
    result_count: 0,
    limit: 0,
  },
  filters: {
    orderStatus: [],
    paymentMethods: [],
    hubs: [],
    clients: [],
  },
  pageCount: 0,
  order: {
    client: '',
    createdAt: '',
    status: '',
    trackingNumber: '',
    bookingNumber: '',
    clientReference: '',
    serviceType: '',
    recipient: {
      fullname: '',
      phone: '',
      email: '',
      requiredDeliveryDate: null,
      actualDeliveryDate: null,
      destinationHub: '',
      destinationHubName: '',
      address: {
        address: '',
        barangay: '',
        city: '',
        province: '',
        zipcode: '',
      },
    },
    pickup: {
      fullname: '',
      phone: '',
      email: '',
      pickupHub: '',
      bookingNumber: null,
      pickupHubName: null,
      address: {
        address: '',
        barangay: '',
        city: '',
        province: '',
        zipcode: '',
      },
    },
    businessUnit: '',
    lastScannedHub: '',
    bagNumbers: [],
    handoverDate: '',
    deliveryImage: '',
    deliverySignature: '',
    slaStatus: '',
    paymentMethod: '',
    height: 0,
    length: 0,
    width: 0,
    weightActual: 0,
    weightVolumetric: 0,
    packageValue: 0,
    deliveryInstructions: '',
    deliveryType: '',
    collectAmount: 0,
    productName: null,
    statusHistoryDetails: [],
    hubHistoryDetails: [],
    slaDaysRemaining: '',
  },
  waybillHistory: {
    generationHistoryDetails: [],
  },
  dispositionHistory: {
    trackingNumber: '',
    dispositions: [],
  },
  isFareyeRequestProcessing: false,
  searchRefList: [],
  validateOtpSuccess: {},
  validateOtpRespFailed: {},
  otpSuccess: {},
  otpFailed: {},
  keyRefValue: '',
  getDeliverySign: {},
  getDeliveryImage: {},
};
const reducer: Reducer<OrderState> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_ORDERS_REQUEST:
    case actionTypes.GET_ORDERS_FILTERED_REQUEST:
    case actionTypes.GET_ORDERS_FILTERED_CLIENTREF_REQUEST:
    case actionTypes.GET_ORDERS_FILTERED_MULTI_REQUEST:
    case actionTypes.GET_ORDER_REQUEST:
    case actionTypes.GET_ALL_FILTERS_REQUEST:
    case actionTypes.GET_FILTERS_DOWNLOAD_REPORT_REQUEST:
    case actionTypes.GET_ORDER_HISTORY_REQUEST:
    case actionTypes.GENERATE_WAYBILL_REQUEST:
    case actionTypes.GET_WAYBILL_HISTORY_REQUEST:
    case actionTypes.GET_DISPOSITION_HISTORY_REQUEST:
    case actionTypes.ADD_DISPOSITION_REQUEST:
      return { ...state, isLoading: true };

    case actionTypes.GET_ALL_ORDERS_SUCCESS:
    case actionTypes.GET_ORDERS_FILTERED_MULTI_SUCCESS:
      return {
        ...state,
        orders: action.data.salesOrderList,
        meta: action.data.metadata,
        cachedOrders: action.data.cachedOrders,
        pageCount: action.data.pageCount,
        isLoading: false,
      };
    case actionTypes.GET_ALL_ORDERS_FAILED:
    case actionTypes.GET_ORDERS_FILTERED_MULTI_FAILED:
      return {
        ...state,
        orders: [],
        meta: initialState.meta,
        cachedOrders: ['', []],
        pageCount: 0,
        isLoading: false,
      };
    case actionTypes.GET_ORDERS_FILTERED_CLIENTREF_SUCCESS:
      return {
        ...state,
        orders: action.data.salesOrderList,
        meta: action.data.metadata,
        cachedOrders: action.data.cachedOrders,
        pageCount: action.data.pageCount,
        isLoading: false,
      };
    case actionTypes.GET_ORDERS_FILTERED_CLIENTREF_FAILED:
      return {
        ...state,
        cachedOrders: [],
        orders: [],
        actionType: action.type as string,
        isLoading: false,
      };
    case actionTypes.GET_ORDERS_FILTERED_SUCCESS:
      return {
        ...state,
        orders: action.data.salesOrderList,
        meta: action.data.metadata,
        cachedOrders: action.data.cachedOrders,
        pageCount: action.data.pageCount,
        isLoading: false,
      };
    case actionTypes.GET_ORDERS_FILTERED_FAILED:
      return {
        ...state,
        cachedOrders: [],
        orders: [],
        actionType: action.type as string,
        isLoading: false,
      };

    case actionTypes.GET_ALL_FILTERS_SUCCESS:
      return {
        ...state,
        filters: action.data,
        isLoading: false,
      };
    case actionTypes.GET_ALL_FILTERS_FAILED:
      return {
        ...state,
        filters: {
          orderStatus: [],
          paymentMethods: [],
          hubs: [],
          clients: [],
        },
        isLoading: false,
      };

    case actionTypes.SET_CACHED_ORDERS:
      return {
        ...state,
        orders: action.orders,
        isLoading: false,
      };
    case actionTypes.RESET_ORDERS:
      return {
        ...state,
        cachedOrders: [],
        orders: [],
      };

    case actionTypes.GET_FILTERS_DOWNLOAD_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.GET_FILTERS_DOWNLOAD_REPORT_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case actionTypes.POLL_FILTERS_DOWNLOAD_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.POLL_FILTERS_DOWNLOAD_REPORT_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case actionTypes.GET_ORDER_SUCCESS:
      return { ...state, order: action.data.salesOrder, isLoading: false };
    case actionTypes.GET_ORDER_FAILED:
      return { ...state, order: initialState.order, isLoading: false };

    case actionTypes.GET_ORDER_HISTORY_SUCCESS:
      return {
        ...state,
        orderHistory: action.data.salesOrder,
        isLoading: false,
      };
    case actionTypes.GET_ORDER_HISTORY_FAILED:
      return {
        ...state,
        orderHistory: {
          trackingNumber: '',
          statusHistoryDetails: [],
        },
        isLoading: false,
      };

    case actionTypes.GET_WAYBILL_HISTORY_SUCCESS:
      return {
        ...state,
        waybillHistory: action.data,
        isLoading: false,
      };
    case actionTypes.GET_WAYBILL_HISTORY_FAILED:
      return {
        ...state,
        waybillHistory: {
          generationHistoryDetails: [],
        },
        isLoading: false,
      };

    case actionTypes.PUSH_ORDERS_TO_FAREYE_REQUEST:
      return { ...state, isLoading: true, isFareyeRequestProcessing: true };
    case actionTypes.PUSH_ORDERS_TO_FAREYE_SUCCESS:
      return { ...state, isLoading: false, isFareyeRequestProcessing: false };
    case actionTypes.PUSH_ORDERS_TO_FAREYE_FAILED:
      return { ...state, isLoading: false, isFareyeRequestProcessing: false };

    case actionTypes.GENERATE_WAYBILL_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.GENERATE_WAYBILL_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case actionTypes.ADD_DISPOSITION_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.ADD_DISPOSITION_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case actionTypes.GET_DISPOSITION_HISTORY_SUCCESS:
      return {
        ...state,
        dispositionHistory: action.data,
        isLoading: false,
      };
    case actionTypes.GET_DISPOSITION_HISTORY_FAILED:
      return {
        ...state,
        dispositionHistory: {
          dispositions: [],
        },
        isLoading: false,
      };

    case actionTypes.SET_SEARCH_REFERENCE:
      return {
        ...state,
        searchRefList: action.searchList,
        keyRefValue: action.keyValue,
        isLoading: false,
      };

    case actionTypes.RESET_ACTION_TYPE:
      return {
        ...state,
        actionType: '',
      };

    case actionTypes.VALIDATE_OTP_USER_REQUEST:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.VALIDATE_OTP_USER_SUCCESS:
      return {
        ...state,
        validateOtpSuccess: action.data,
        validateOtpRespFailed: {},
        isLoading: false,
      };
    case actionTypes.VALIDATE_OTP_USER_FAILED:
      return {
        ...state,
        validateOtpRespFailed: action.err,
        validateOtpSuccess: {},
        isLoading: false,
      };

    case actionTypes.RESEND_OTP_REQUEST:
      return {
        ...state,
        isLoading: false,
      };

    case actionTypes.RESEND_OTP_REQUEST_SUCCESS:
      return {
        ...state,
        otpSuccess: action.data,
        isLoading: false,
      };

    case actionTypes.RESEND_OTP_REQUEST_FAILED:
      return {
        ...state,
        otpFailed: action.err,
        isLoading: false,
      };

    case actionTypes.RESET_OTP_ATTEMPT:
      return {
        ...state,
        otpSuccess: {},
        isLoading: false,
      };

    case actionTypes.GET_DELIVERY_SIGNATURE:
      return {
        ...state,
        isLoading: false,
      };

    case actionTypes.GET_DELIVERY_IMAGE:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default reducer;
