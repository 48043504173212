import { all, call, takeLatest, put } from 'redux-saga/effects';
import { actionTypes } from './reducer';
import { getDashboardData } from './service';
import { DashboardDataItems } from './interface';

function* getDashboardMvpData(): Generator<any> {     // eslint-disable-line
  try {
    const data: any = yield call(getDashboardData);    // eslint-disable-line
    const dashboardBodyData: DashboardDataItems = [];
    const dashboardHeaderData = [
      {
        Header: 'Port Code',
        accessor: 'portcode',
        isHoliday: false,
        isSunday: false,
      },
    ];

    let portKey: string = '';
    data.dashboardDetails.forEach((val: any) => {    // eslint-disable-line
      let values: any = {};   // eslint-disable-line
      const portCodeKey = val[Object.keys(val)[0]]['portCodes'];  // eslint-disable-line
      portKey = Object.keys(portCodeKey)[0];  // eslint-disable-line
      for (let key in val) {    // eslint-disable-line
        let value = Object.values(val[key]['portCodes'])[0];  // eslint-disable-line
        values[key] = value;   
        let date = '';
        if (new Date(key).toDateString() === new Date().toDateString()) {
          date = 'Today';
        } else {
          date = `${new Date(key).getMonth() + 1}/${new Date(key).getDate()}`;
        }
        if (val[key].isHoliday && val[key].isSunday) {
          date = `${date}
(Sunday)`;
        } else if (val[key].isHoliday) {
          date = `${date}
(Holiday)`;
        } else if (val[key].isSunday) {
          date = `${date} 
(Sunday)`;
        }
        dashboardHeaderData.push({
          Header: date,
          accessor: key,
          isHoliday: val[key].isHoliday,
          isSunday: val[key].isSunday,
        });
      }
      dashboardBodyData.push({
        portcode: portKey,
        ...values,
      });
    });

    const dashboardData = {
      dashboardHeaderData,
      dashboardBodyData,
    };

    yield put({
      type: actionTypes.GET_DASHBOARD_MVP_DATA_REQUEST_SUCCESS,
      payload: dashboardData,
    });
  } catch (err) {
    yield put({ type: actionTypes.GET_DASHBOARD_MVP_DATA_REQUEST_FAILED, err });
  }
}

function* watchDashboardRequests() {
  yield all([
    takeLatest(actionTypes.GET_DASHBOARD_MVP_DATA_REQUEST, getDashboardMvpData),
  ]);
}

export default [watchDashboardRequests()];
