// SLA Extension View

import request from '../../utils/api';
import { SingleExtensionObject } from './interface';

export const getSingleSlaExtension = (trackingNumber: string) =>
  request(`hubm/sla/extension/${trackingNumber}`);

export const extendSingleSla = (body: SingleExtensionObject) =>
  request('hubm/sla/extension', {
    method: 'POST',
    body,
  });

export const extendBulkSla = (body: FormData) =>
  request('hubm/sla/extension/bulk', { method: 'POST', body });

// SLA Extension Data Changes

export const getSlaExtensionDataFiltered = (
  page: number,
  limit: number,
  createdDateStart: string,
  createdDateEnd: string
) => {
  return request('hubm/sla/extension/filter', {
    method: 'POST',
    body: {
      page,
      limit,
      createdDateStart,
      createdDateEnd,
    },
  });
};

export const removeSlaExtensionId = (id: number) => {
  return request(`hubm/sla/extension/${id}`, {
    method: 'DELETE',
  });
};
