import { Reducer } from 'redux';
import { BarangayClusterState } from './interface';

export const actionTypes = {
  GET_BARANGAY_CLUSTER_REQUEST: 'GET_BARANGAY_CLUSTER_REQUEST',
  GET_BARANGAY_CLUSTER_SUCCESS: 'GET_BARANGAY_CLUSTER_SUCCESS',
  GET_BARANGAY_CLUSTER_FAILED: 'GET_BARANGAY_CLUSTER_FAILED',

  RESET_ACTION_TYPE: 'RESET_ACTION_TYPE',
};

export const actionCreators = {
  getBarangayCluster: (trackingNumber: string) => ({
    type: actionTypes.GET_BARANGAY_CLUSTER_REQUEST,
    trackingNumber,
  }),
  resetAction: () => ({
    type: actionTypes.RESET_ACTION_TYPE,
  }),
};

const initialState: BarangayClusterState = {
  isLoading: false,
  actionTypes: '',
  barangayFailed: {
    timestamp: '',
    status: '',
    message: '',
    details: '',
  },
  barangaySuccess: {
    province: '',
    city: '',
    barangay: '',
    portCode: '',
    barangayCluster: '',
    cluster: '',
  },
};

const reducer: Reducer<BarangayClusterState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case actionTypes.GET_BARANGAY_CLUSTER_REQUEST:
      return { ...state, isLoading: true };

    case actionTypes.GET_BARANGAY_CLUSTER_SUCCESS:
      return {
        ...state,
        actionTypes: action.type,
        barangaySuccess: action.data,
        isLoading: false,
      };

    case actionTypes.GET_BARANGAY_CLUSTER_FAILED:
      return {
        ...state,
        actionTypes: action.type,
        barangayFailed: action.err,
        isLoading: false,
      };

    case actionTypes.RESET_ACTION_TYPE:
      return { ...state, actionType: '' };
    default:
      return state;
  }
};

export default reducer;
