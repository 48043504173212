export const dateFormat = {
  DMMMMYYYY_HmmssZ: 'D MMMM YYYY, H:mm:ssZ',
  DMMMMYYYY: 'D MMMM YYYY',
  YYYYMMDD: 'YYYYMMDD',
  MM_DD_YYYY: 'MM/DD/YYYY',
  YYYY_MM_DD: 'YYYY-MM-DD',
  YYYYMMDDHHmmss: 'YYYYMMDDHHmmss',
};

export const system = {
  APPLICATION_NAME: 'Hub Management System',
  INVALID_CHAR: 'Invalid character(s)',
  ORDERS_DATE_FILTER_TOOLTIP:
    'Date filter is only limited to 6 months. For more than 6 months, use Transaction Dump Report.',
  LOGIN_EMAIL_DISCLAIMER:
    'Please use your Air21, AC Logistics, AHI, Reliant, or Vendor Partners Email to login',
  EMPTY_CODE: 'EMPTY',
};

export const orderStatus = {
  BAGGED: 'Bagged',
  CANCELLED: 'Cancelled',
  CLAIMED: 'Claimed',
  CLOSED: 'Closed',
  DELIVERED: 'Delivered',
  DELIVERY_FAILED: 'Delivery Failed',
  DELIVERY_PENDING_RTC: 'Enroute to Client',
  FOR_CLAIMS: 'For Claims',
  HANDOVER_TO_3PL: 'Handover to 3PL',
  HANDOVER_TO_TRANSPORT: 'Handover to Transport',
  IN_TRANSIT: 'In Transit',
  IN_TRANSIT_SORT: 'In Transit from Sort',
  INITIATE_SECONDARY_DELIVERY: 'Initiate Secondary Delivery',
  LOST: 'Lost',
  NOT_CLAIMED: 'Not Claimed',
  NOT_RECEIVED: 'Not Received',
  ON_HOLD: 'On Hold',
  NEW: 'Created',
  PICKED_UP: 'Picked Up',
  PICKUP_FAILED: 'Pickup Failed',
  PICKUP_PENDING: 'Pickup Pending',
  RECEIVED_AT_HUB_RTC: 'RTC at the Hub',
  DELIVERY_PENDING: 'Ready for Delivery',
  REATTEMPT_DELIVERY: 'Reattempt Delivery',
  REATTEMPT_SECONDARY_DELIVERY: 'Reattempt Secondary Delivery',
  RECEIVED_AT_HUB: 'Received',
  // RECEIVED_AT_HUB_DC_SORT: 'Received at DC / Received at Sort',
  REJECTED: 'Rejected',
  REJECTED_RETURNED: 'Rejected and Returned',
  DELIVERY_FAILED_RTC: 'Return Failed',
  RETURNED_TO_SENDER: 'Returned to Customer',
  RECEIVED_AT_HUB_SCANNED: 'Scanned at Hub',
  READY_FOR_RTC: 'Scanned at Hub - RTC',
  SECONDARY_DELIVERED: 'Secondary Delivered',
  SECONDARY_DELIVERY_FAILED: 'Secondary Delivery Failed',
  SECONDARY_DELIVERY_PENDING: 'Secondary Delivery Pending',
};

export const bagStatus = {
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
  PROCESSING: 'PROCESSING',
};

export const dispositionOption = {
  REATTEMPT_OR_REDELIVER: 'REATTEMPT_OR_REDELIVER',
  RESCHEDULE: 'RESCHEDULE',
  RTS: 'RTS',
  CHANGE_ADDRESS: 'CHANGE_ADDRESS',
  OTHERS: 'OTHERS',
};

export const extendSlaStatus = {
  SUCCESS: 'SUCCESS',
  PROCESSING: 'PROCESSING',
  FAILED: 'FAILED',
};

export const status = {
  NOT_RJCTD: 'This package is not yet Rejected.',
  N_EXT: 'The tracking number of the package is not in our system.',
  FDY: 'This package is not FDY. This action is not allowed',
  RECV_HB:
    'This package is in RECEIVED_AT_HUB status. This action is not allowed',
  BGD: 'This package is in BAGGED status. This action is not allowed',
  BGD_RTC: 'This package is in BAGGED_RTC status. This action is not allowed',
  CREATED: 'This package is in NEW status. This action is not allowed',
  CND: 'This package is in CANCELLED status. This action is not allowed',
  CLD: 'This package is in CLAIMED status. This action is not allowed',
  CLSD: 'This package is in CLOSED status. This action is not allowed',
  DVRD: 'This package is in DELIVERED status. This action is not allowed',
  DVR_FLD:
    'This package is in DELIVERY_FAILED status. This action is not allowed',
  DVR_PD_R:
    'This package is in DELIVERY_PENDING_RTC status. This action is not allowed',
  F_CLM: 'This package is in FOR_CLAIMS status. This action is not allowed',
  RDLVRY: 'This package is in REDILIVERY status. This action is not allowed',
  HNDR_3PL:
    'This package is in HANDOVER_TO_3PL status. This action is not allowed',
  HNDR_TPRT:
    'This package is in HANDOVER_TO_TRANSPORT status. This action is not allowed',
  IN_TRNT: 'This package is in IN_TRANSIT status. This action is not allowed',
  IN_TRT_S:
    'This package is in IN_TRANSIT_SORT status. This action is not allowed',
  IN_S_DVR:
    'This package is in INITIATE_SECONDARY_DELIVERY status. This action is not allowed',
  LOST: 'This package is in LOST status. This action is not allowed',
  NT_CLMD: 'This package is in NOT_CLAIMED status. This action is not allowed',
  NT_RCVD: 'This package is in NOT_RECEIVED status. This action is not allowed',
  ON_HLD: 'This package is in ON_HOLD status. This action is not allowed',
  PCKD_UP: 'This package is in PICKED_UP status. This action is not allowed',
  PCKP_FLD:
    'This package is in PICKUP_FAILED status. This action is not allowed',
  PCKP_PND:
    'This package is in PICKUP_PENDING status. This action is not allowed',
  RCD_HB_R:
    'This package is in RECEIVED_AT_HUB_RTC status. This action is not allowed',
  DLVR_PND:
    'This package is in DELIVERY_PENDING status. This action is not allowed',
  RTMP_DLVR:
    'This package is in REATTEMPT_DELIVERY status. This action is not allowed',
  RTMP_DVR:
    'This package is in REATTEMPT_SECONDARY_DELIVERY status. This action is not allowed',
  REC: 'This package is in RECEIVED status. This action is not allowed',
  RJCTD: 'This package is in REJECTED status. This action is not allowed',
  RJC_RTR:
    'This package is in REJECTED_AND_RETURNED status. This action is not allowed',
  DVR_FD_R:
    'This package is in DELIVERY_FAILED_RTC status. This action is not allowed',
  RTN_SNR:
    'This package is in RETURNED_TO_SENDER status. This action is not allowed',
  RCV_H_SCN:
    'This package is in RECEIVED_AT_HUB_SCANNED status. This action is not allowed',
  RDY_RTC:
    'This package is in READY_FOR_RTC status. This action is not allowed',
  SEC_DVLR:
    'This package is in SECONDARY_DELIVERED status. This action is not allowed',
  SEC_DV_FD:
    'This package is in SECONDARY_DELIVERY_FAILED status. This action is not allowed',
  SEC_DV_PN:
    'This package is in SECONDARY_DELIVERY_PENDING status. This action is not allowed',
};
