import { Reducer } from 'redux';
import { ReportState } from './interface';

export const actionTypes = {
  GET_REPORT_TYPES_REQUEST: 'GET_REPORT_TYPES_REQUEST',
  GET_REPORT_TYPES_SUCCESS: 'GET_REPORT_TYPES_SUCCESS',
  GET_REPORT_TYPES_FAILED: 'GET_REPORT_TYPES_FAILED',

  GET_REPORT_FILTERS_REQUEST: 'GET_REPORT_FILTERS_REQUEST',
  GET_REPORT_FILTERS_SUCCESS: 'GET_REPORT_FILTERS_SUCCESS',
  GET_REPORT_FILTERS_FAILED: 'GET_REPORT_FILTERS_FAILED',

  GENERATE_REPORT_REQUEST: 'GENERATE_REPORT_REQUEST',
  GENERATE_REPORT_SUCCESS: 'GENERATE_REPORT_SUCCESS',
  GENERATE_REPORT_FAILED: 'GENERATE_REPORT_FAILED',

  GENERATE_WAYBILL_REPORT_REQUEST: 'GENERATE_WAYBILL_REPORT_REQUEST',
  GENERATE_WAYBILL_REPORT_SUCCESS: 'GENERATE_WAYBILL_REPORT_SUCCESS',
  GENERATE_WAYBILL_REPORT_FAILED: 'GENERATE_WAYBILL_REPORT_FAILED',

  POLL_REPORT_REQUEST: 'POLL_REPORT_REQUEST',
  POLL_REPORT_SUCCESS: 'POLL_REPORT_SUCCESS',
  POLL_REPORT_FAILED: 'POLL_REPORT_FAILED',
};

export const actionCreators = {
  getReportTypes: () => ({ type: actionTypes.GET_REPORT_TYPES_REQUEST }),
  getReportFilters: (reportType: string) => ({
    type: actionTypes.GET_REPORT_FILTERS_REQUEST,
    reportType,
  }),
  generateReport: (filterData: object) => ({
    type: actionTypes.GENERATE_REPORT_REQUEST,
    filterData,
  }),
  generateWaybillReport: (filterData: object) => ({
    type: actionTypes.GENERATE_WAYBILL_REPORT_REQUEST,
    filterData,
  }),
  pollReport: (id: string) => ({
    type: actionTypes.POLL_REPORT_REQUEST,
    id,
  }),
};

const initialState: ReportState = {
  isLoading: false,
  reportTypes: [],
  reportFilters: {
    clients: [],
    pickupHub: [],
    cutoffTimeInPHT: [],
    orderCreatedDateInPHT: '',
    startDate: '',
    endDate: '',
  },
};

const reducer: Reducer<ReportState> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_REPORT_TYPES_REQUEST:
    case actionTypes.GET_REPORT_FILTERS_REQUEST:
    case actionTypes.GENERATE_REPORT_REQUEST:
    case actionTypes.GENERATE_WAYBILL_REPORT_REQUEST:
      return { ...state, isLoading: true };

    case actionTypes.GET_REPORT_TYPES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        reportTypes: action.data.reportTypes,
      };
    case actionTypes.GET_REPORT_TYPES_FAILED:
      return { ...state, isLoading: false, reportTypes: [] };

    case actionTypes.GET_REPORT_FILTERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        reportFilters: action.data.filterMap,
      };
    case actionTypes.GET_REPORT_FILTERS_FAILED:
      return { ...state, isLoading: false, reportFilters: {} };

    case actionTypes.GENERATE_REPORT_SUCCESS:
      return { ...state, isLoading: false };
    case actionTypes.GENERATE_REPORT_FAILED:
      return { ...state, isLoading: false };
    case actionTypes.GENERATE_WAYBILL_REPORT_SUCCESS:
      return { ...state, isLoading: false };
    case actionTypes.GENERATE_WAYBILL_REPORT_FAILED:
      return { ...state, isLoading: false };
    case actionTypes.POLL_REPORT_SUCCESS:
      return { ...state, isLoading: false };
    case actionTypes.POLL_REPORT_FAILED:
      return { ...state, isLoading: false };

    default:
      return state;
  }
};

export default reducer;
