/* eslint-disable @typescript-eslint/no-explicit-any */
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import sagas from './sagas';
import rootReducer from './reducers';

const sagaMiddleware = createSagaMiddleware();

export default function (initialState = {}) {
  // Middleware and Enhancers
  const enhancers = [applyMiddleware(sagaMiddleware)];

  // Persisting state
  const persistConfig = {
    key: 'entrego',
    storage,
    blacklist: [],
  };

  const appReducer = (state, action) => {
    let newState = state;

    if (action.type === 'LOGOUT_SUCCESS') {
      newState = undefined;
    }
    return rootReducer(newState, action);
  };
  const persistedReducer = persistReducer(persistConfig, appReducer);

  // window.devToolsExtension && enhancers.push(window.devToolsExtension());

  const store = createStore(
    persistedReducer,
    initialState,
    compose(...enhancers)
  );
  const persistor = persistStore(store);

  // hot reloading reducers
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducers', () => {
      const nextReducer = require('./reducers').default; // eslint-disable-line global-require
      store.replaceReducer(nextReducer);
    });
  }

  sagaMiddleware.run(sagas);

  return { store, persistor };
}
