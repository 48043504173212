/* eslint-disable @typescript-eslint/no-explicit-any */
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './reducers';
import sagas from './sagas';

const sagaMiddleware = createSagaMiddleware();

export default function (initialState = {}) {
  // Middleware and Enhancers
  const enhancers = [applyMiddleware(sagaMiddleware)];

  // Persisting state
  const persistConfig = {
    key: 'entrego',
    storage,
    blacklist: [],
  };
  const appReducer = (state, action) => {
    let newState = state;

    if (action.type === 'LOGOUT_SUCCESS') {
      newState = undefined;
    }
    return rootReducer(newState, action);
  };
  const persistedReducer = persistReducer(persistConfig, appReducer);

  const store = createStore(
    persistedReducer,
    initialState,
    compose(...enhancers)
  );
  const persistor = persistStore(store);

  sagaMiddleware.run(sagas);

  return { store, persistor };
}
