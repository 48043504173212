/* eslint-disable @typescript-eslint/no-explicit-any */
import { all, call, takeLatest, put } from 'redux-saga/effects';
import { GetBarangaySaga } from './interface';
import { getBarangayCluster as getBarangayClusterService } from './service';
import { actionTypes } from './reducer';

function* getBarangayCluster({
  trackingNumber,
}: GetBarangaySaga): Generator<any> {
  try {
    const data = yield call(getBarangayClusterService, trackingNumber);
    yield put({ type: actionTypes.GET_BARANGAY_CLUSTER_SUCCESS, data });
  } catch (err) {
    yield put({ type: actionTypes.GET_BARANGAY_CLUSTER_FAILED, err });
  }
}

function* watchBarangayRequest() {
  yield all([
    takeLatest(actionTypes.GET_BARANGAY_CLUSTER_REQUEST, getBarangayCluster),
  ]);
}

export default [watchBarangayRequest()];
