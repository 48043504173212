// Reports View
import request from '../../utils/api';

export const getReportTypes = () => request('hubm/reports');

export const getReportFilters = (reportType: string) =>
  request(`hubm/reports/filters?reportType=${reportType}`);

export const generateWaybillReport = (filterData: object) =>
  request(
    'hubm/reports',
    {
      method: 'POST',
      body: filterData,
    },
    'blob'
  );
export const generateReport = (filterData: object) =>
  request(
    'hubm/orders/report',
    {
      method: 'POST',
      body: filterData,
    },
    'blob'
  );

export const pollReport = (id: string) =>
  request(`hubm/orders/report?reportId=${id}`);
