/* eslint-disable @typescript-eslint/no-explicit-any */
import { all, call, put, select } from 'redux-saga/effects';
import * as bulmaToast from 'bulma-toast';
import * as Eff from 'redux-saga/effects';
import { actionTypes } from './reducer';
import { actionTypes as authActionTypes } from '../auth/reducer';
import {
  getSingleSlaExtension as getSingleSlaExtensionService,
  extendSingleSla as extendSingleSlaService,
  extendBulkSla as extendBulkSlaService,
  getSlaExtensionDataFiltered as getSlaExtensionDataFilteredService,
  removeSlaExtensionId,
} from './service';
import {
  ExtendSingleSlaParams,
  GetSingleSlaExtensionParams,
  ExtendBulkSlaParams,
  SlaExtensionDataFilteredParams,
  RemoveSlaExtension,
  SlaExtensionObject,
} from './interface';

const takeLatest: any = Eff.takeLatest;            // eslint-disable-line

function* getSingleSlaExtension({
  trackingNumber,
}: GetSingleSlaExtensionParams): Generator<any> {
  try {
    const data = yield call(getSingleSlaExtensionService, trackingNumber);
    yield put({ type: actionTypes.GET_SINGLE_SLA_EXTENSION_SUCCESS, data });
  } catch (err) {
    yield put({ type: actionTypes.GET_SINGLE_SLA_EXTENSION_FAILED, err });
  }
}

function* extendSingleSla({ body }: ExtendSingleSlaParams): Generator<any> {
  try {
    const data: any = yield call(extendSingleSlaService, body);
    yield put({ type: actionTypes.EXTEND_SINGLE_SLA_SUCCESS, data });
    bulmaToast.toast({
      message: 'SLA Extension has been processed',
      type: 'is-success',
      position: 'bottom-right',
      dismissible: true,
      duration: 7000,
      closeOnClick: true,
      animate: { in: 'slideInRight', out: 'slideOutRight' },
    });
  } catch (err) {
    if (err.status === 403) {
      yield put({ type: authActionTypes.LOGOUT_SUCCESS });
      bulmaToast.toast({
        message: 'Authentication Failed!',
        type: 'is-danger',
        position: 'top-center',
        dismissible: true,
        duration: 10000,
        closeOnClick: true,
      });
    }
    let { details } = err;
    if (details && Array.isArray(details)) {
      details = err.details.join(', ');
    }
    bulmaToast.toast({
      message: details || err.message,
      type: 'is-danger',
      position: 'top-center',
      dismissible: true,
      duration: 10000,
      closeOnClick: true,
    });
    yield put({ type: actionTypes.EXTEND_SINGLE_SLA_FAILED, err });
  }
}

function* extendBulkSla({ body }: ExtendBulkSlaParams): Generator<any> {
  try {
    const data: any = yield call(extendBulkSlaService, body);
    yield put({ type: actionTypes.EXTEND_BULK_SLA_SUCCESS, data });
    if (
      data.totalProcessed + data.success > 0 &&
      data.totalProcessed === data.success
    ) {
      bulmaToast.toast({
        message: 'All extensions have been processed',
        type: 'is-success',
        position: 'bottom-right',
        dismissible: true,
        duration: 7000,
        closeOnClick: true,
        animate: { in: 'slideInRight', out: 'slideOutRight' },
      });
    }
  } catch (err) {
    if (err.status === 403) {
      yield put({ type: authActionTypes.LOGOUT_SUCCESS });
      bulmaToast.toast({
        message: 'Authentication Failed!',
        type: 'is-danger',
        position: 'top-center',
        dismissible: true,
        duration: 10000,
        closeOnClick: true,
      });
    }
    let { details } = err;
    if (details && Array.isArray(details)) {
      details = err.details.join(', ');
    }
    bulmaToast.toast({
      message: details || err.message,
      type: 'is-danger',
      position: 'top-center',
      dismissible: true,
      duration: 10000,
      closeOnClick: true,
    });
    yield put({ type: actionTypes.EXTEND_BULK_SLA_FAILED, err });
  }
}

function* getSlaExtensionDataFiltered({
  page,
  limit,
  createdDateStart,
  createdDateEnd,
}: SlaExtensionDataFilteredParams): Generator<any> {
  try {
    const state: any = yield select();
    const data: any = yield call(
      getSlaExtensionDataFilteredService,
      page,
      limit,
      createdDateStart,
      createdDateEnd
    );

    data.cachedSlaExtensionData = [];
    data.metadata = {
      total: data.total,
      limit: data.limit,
      hasNext: data.hasNext,
      hasPrevious: data.hasPrevious,
      nextPage: data.nextPage,
      currentPage: data.currentPage,
      previousPage: data.PreviousPage,
    };
    // if meta data next page has value
    if (!data.hasNext) {
      const newCachedSlaExtensionData =
        state.slaextension.cachedSlaExtensionData;
      newCachedSlaExtensionData[page] = data.data;
      data.cachedSlaData = newCachedSlaExtensionData;
      const finalPages = newCachedSlaExtensionData.length - 1;
      data.pageCount = finalPages;
    } else {
      // set page count to next page
      data.pageCount = data.metadata.nextPage;
      if (!state.slaextension.cachedSlaExtensionData[page]) {
        // concat cached orders
        const newCachedSlaExtensionData =
          state.slaextension.cachedSlaExtensionData;
        newCachedSlaExtensionData[page] = data.data;
        data.cachedSlaExtensionData = newCachedSlaExtensionData;
      }
    }
    yield put({ type: actionTypes.GET_SLA_EXTENSION_FILTERED_SUCCESS, data });
  } catch (err) {
    if (err.status === 403) {
      yield put({ type: authActionTypes.LOGOUT_SUCCESS });
      bulmaToast.toast({
        message: 'Authentication Failed!',
        type: 'is-danger',
        position: 'top-center',
        dismissible: true,
        duration: 10000,
        closeOnClick: true,
      });
    } else {
      bulmaToast.toast({
        message: err.message || err.details,
        type: 'is-danger',
        position: 'top-center',
        dismissible: true,
        duration: 10000,
        closeOnClick: true,
      });
    }
    yield put({ type: actionTypes.GET_SLA_EXTENSION_FILTERED_FAILED, err });
  }
}

function* deleteSlaExtension({ id }: RemoveSlaExtension): Generator<any> {
  try {
    const state: any = yield select();
    const data: any = yield call(removeSlaExtensionId, id);
    const index = state.slaextension.slaExtensionData.findIndex(
      (sla: SlaExtensionObject) => {
        return sla.id === data.id;
      }
    );
    const slaExtensionData = [...state.slaextension.slaExtensionData];
    slaExtensionData[index].isRemoved = true;

    yield put({
      type: actionTypes.DELETE_SLA_EXTENSION_SUCCESS,
      slaExtensionData,
    });
  } catch (err) {
    if (err.status === 400) {
      yield put({ type: actionTypes.DELETE_SLA_EXTENSION_FAILED, err });
      bulmaToast.toast({
        message:
          'You are not allowed to remove this extension. Please contact your Admin.',
        type: 'is-danger',
        position: 'bottom-right',
        dismissible: true,
        duration: 10000,
        closeOnClick: true,
      });
    }
  }
}

function* watchReportsRequest() {
  yield all([
    takeLatest(
      actionTypes.GET_SINGLE_SLA_EXTENSION_REQUEST,
      getSingleSlaExtension
    ),
    takeLatest(actionTypes.EXTEND_SINGLE_SLA_REQUEST, extendSingleSla),
    takeLatest(actionTypes.EXTEND_BULK_SLA_REQUEST, extendBulkSla),
    takeLatest(
      actionTypes.GET_SLA_EXTENSION_FILTERED_REQUEST,
      getSlaExtensionDataFiltered
    ),
    takeLatest(actionTypes.DELETE_SLA_EXTENSION, deleteSlaExtension),
  ]);
}

export default [watchReportsRequest()];
