/* eslint-disable @typescript-eslint/no-explicit-any */
import { all, call, takeLatest, put, delay } from 'redux-saga/effects';
import * as bulmaToast from 'bulma-toast';
import {
  CreateBagParams,
  GetBagStickerParams,
  RemoveBagPackagesParams,
  AddBagPackagesParams,
  RegenerateBagStickerParams,
  UpdateBaggingParams,
  ValidatePackageParams,
  DownloadTnParams,
} from './interface';
import { actionTypes } from './reducer';
import { actionTypes as authActionTypes } from '../auth/reducer';
import {
  createBag as createBagService,
  closeBag as closeBagService,
  getBagNumber as getBagNumberService,
  regenerateBagSticker as regenerateBagStickerService,
  getAllPortCodes as getAllPortCodesService,
  removeBagPackages as removeBagPackagesService,
  addBagPackages as addBagPackagesService,
  updateBaggingStatus as updateBaggingStatusService,
  validatePackage as validatePackageService,
  downloadTnRequest as downloadTnService,
  // getBagSticker as getBagStickerService,
} from './service';
import { bagStatus } from '../../utils/constants';

const errorAudio = new Audio('sounds/error.mp3');

function* createBag({ body }: CreateBagParams): Generator<any> {
  try {
    const data = yield call(createBagService, body);
    bulmaToast.toast({
      message: 'Bag successfully created',
      type: 'is-success',
      position: 'bottom-right',
      dismissible: true,
      duration: 7000,
      closeOnClick: true,
      animate: { in: 'slideInRight', out: 'slideOutRight' },
    });
    yield put({ type: actionTypes.CREATE_BAG_SUCCESS, data });
  } catch (err) {
    if (err.status === 403) {
      yield put({ type: authActionTypes.LOGOUT_SUCCESS });
      bulmaToast.toast({
        message: 'Authentication Failed!',
        type: 'is-danger',
        position: 'top-center',
        dismissible: true,
        duration: 10000,
        closeOnClick: true,
      });
    }
    bulmaToast.toast({
      message: err.details || err.message,
      type: 'is-danger',
      position: 'top-center',
      dismissible: true,
      duration: 10000,
      closeOnClick: true,
    });
    yield put({ type: actionTypes.CREATE_BAG_FAILED, err });
  }
}

function* closeBag({ body }: CreateBagParams): Generator<any> {
  try {
    const data = yield call(closeBagService, body);
    bulmaToast.toast({
      message: 'Bag successfully closed',
      type: 'is-success',
      position: 'bottom-right',
      dismissible: true,
      duration: 10000,
      closeOnClick: true,
      animate: { in: 'slideInRight', out: 'slideOutRight' },
    });
    yield put({ type: actionTypes.CLOSE_BAG_SUCCESS, data });
  } catch (err) {
    if (err.status === 403) {
      yield put({ type: authActionTypes.LOGOUT_SUCCESS });
      bulmaToast.toast({
        message: 'Authentication Failed!',
        type: 'is-danger',
        position: 'top-center',
        dismissible: true,
        duration: 10000,
        closeOnClick: true,
      });
    }
    bulmaToast.toast({
      message: err.details || err.message,
      type: 'is-danger',
      position: 'bottom-right',
      dismissible: true,
      duration: 10000,
      closeOnClick: true,
      animate: { in: 'slideInRight', out: 'slideOutRight' },
    });
    yield put({ type: actionTypes.CLOSE_BAG_FAILED, err });
  }
}

/* This function is used for the waybill sticker push to the fareye

function* getBagSticker({ bagNumber }: GetBagStickerParams): Generator<any> {
  try {
    yield delay(2000); // give time for bag creation before retrieving bag sticker
    const data = yield call(getBagStickerService, bagNumber);
    yield put({ type: actionTypes.GET_BAG_STICKER_SUCCESS, data });
  } catch (err) {
    if (err.status === 403) {
      yield put({ type: authActionTypes.LOGOUT_SUCCESS });
      bulmaToast.toast({
        message: 'Authentication Failed!',
        type: 'is-danger',
        position: 'top-center',
        dismissible: true,
        duration: 10000,
        closeOnClick: true,
      });
    }
    bulmaToast.toast({
      message: err.details || err.message,
      type: 'is-danger',
      position: 'top-center',
      dismissible: true,
      duration: 10000,
      closeOnClick: true,
    });
    yield put({ type: actionTypes.GET_BAG_STICKER_FAILED, err });
  }
} */

function* regenerateBagSticker({
  body,
}: RegenerateBagStickerParams): Generator<any> {
  try {
    yield delay(2000); // give time for bag creation before retrieving bag sticker
    const data = yield call(regenerateBagStickerService, body);
    yield put({ type: actionTypes.REGENERATE_BAG_STICKER_SUCCESS, data });
  } catch (err) {
    if (err.status === 403) {
      yield put({ type: authActionTypes.LOGOUT_SUCCESS });
      bulmaToast.toast({
        message: 'Authentication Failed!',
        type: 'is-danger',
        position: 'top-center',
        dismissible: true,
        duration: 10000,
        closeOnClick: true,
      });
    }
    bulmaToast.toast({
      message: err.details || err.message,
      type: 'is-danger',
      position: 'top-center',
      dismissible: true,
      duration: 10000,
      closeOnClick: true,
    });
    yield put({ type: actionTypes.REGENERATE_BAG_STICKER_FAILED, err });
  }
}

function* getBagNumber({
  bagNumber,
  displayError,
}: GetBagStickerParams): Generator<any> {
  try {
    const data: any = yield call(getBagNumberService, bagNumber);
    if (data.bag.status === bagStatus.CLOSED && displayError === true) {
      bulmaToast.toast({
        message: 'This bag is already closed. Please enter a new one. ',
        type: 'is-danger',
        position: 'bottom-right',
        dismissible: true,
        duration: 10000,
        closeOnClick: true,
        animate: { in: 'slideInRight', out: 'slideOutRight' },
      });
    }
    yield put({ type: actionTypes.GET_BAG_NUMBER_SUCCESS, data });
  } catch (err) {
    bulmaToast.toast({
      message: err.details || err.message,
      type: 'is-danger',
      position: 'bottom-right',
      dismissible: true,
      duration: 10000,
      closeOnClick: true,
      animate: { in: 'slideInRight', out: 'slideOutRight' },
    });
    yield put({ type: actionTypes.GET_BAG_NUMBER_FAILED, err });
  }
}

function* removeBagPackages({ body }: RemoveBagPackagesParams): Generator<any> {
  try {
    const data = yield call(removeBagPackagesService, body);
    bulmaToast.toast({
      message: 'Tracking Number(s) Successfully Removed ',
      type: 'is-success',
      position: 'bottom-right',
      dismissible: true,
      duration: 10000,
      closeOnClick: true,
      animate: { in: 'slideInRight', out: 'slideOutRight' },
    });
    yield put({ type: actionTypes.REMOVE_BAG_PACKAGES_SUCCESS, data });
    yield put({
      type: actionTypes.GET_BAG_NUMBER_REQUEST,
      bagNumber: body.bagNumber,
    });
  } catch (err) {
    bulmaToast.toast({
      message: err.details || err.message,
      type: 'is-danger',
      position: 'bottom-right',
      dismissible: true,
      duration: 10000,
      closeOnClick: true,
      animate: { in: 'slideInRight', out: 'slideOutRight' },
    });
    yield put({ type: actionTypes.REMOVE_BAG_PACKAGES_FAILED, err });
  }
}

function* addBagPackages({ body }: AddBagPackagesParams): Generator<any> {
  try {
    const data: any = yield call(addBagPackagesService, body);
    bulmaToast.toast({
      message: `${data.successfulPackages.length} tracking number(s) have been bagged successfully`,
      type: 'is-success',
      position: 'bottom-right',
      dismissible: true,
      duration: 10000,
      closeOnClick: true,
      animate: { in: 'slideInRight', out: 'slideOutRight' },
    });
    yield put({ type: actionTypes.ADD_BAG_PACKAGES_SUCCESS, data });
  } catch (err) {
    bulmaToast.toast({
      message: err.details || err.message,
      type: 'is-danger',
      position: 'bottom-right',
      dismissible: true,
      duration: 10000,
      closeOnClick: true,
      animate: { in: 'slideInRight', out: 'slideOutRight' },
    });
    yield put({ type: actionTypes.ADD_BAG_PACKAGES_FAILED, err });
  }
}

function* validatePackage({ body }: ValidatePackageParams): Generator<any> {
  try {
    const data: any = yield call(validatePackageService, body);
    data.status.trackingNumber = body.trackingNumber;
    yield put({ type: actionTypes.VALIDATE_PACKAGE_SUCCESS, data });
  } catch (err) {
    bulmaToast.toast({
      message: err.details || err.message,
      type: 'is-danger',
      position: 'bottom-right',
      dismissible: true,
      duration: 10000,
      closeOnClick: true,
      animate: { in: 'slideInRight', out: 'slideOutRight' },
    });
    yield put({ type: actionTypes.VALIDATE_PACKAGE_FAILED, err });
  }
}

function* updateBaggingStatus({ body }: UpdateBaggingParams): Generator<any> {
  try {
    const data = yield call(updateBaggingStatusService, body);
    yield put({ type: actionTypes.UPDATE_BAGGING_STATUS_SUCCESS, data });
    if (body.force) {
      bulmaToast.toast({
        message: 'Bag successfully forced open!',
        type: 'is-success',
        position: 'bottom-right',
        dismissible: true,
        duration: 10000,
        closeOnClick: true,
        animate: { in: 'slideInRight', out: 'slideOutRight' },
      });
    }
  } catch (err) {
    bulmaToast.toast({
      message: Array.isArray(err.details)
        ? err.details[0]
        : err.details || err.message,
      type: 'is-danger',
      position: 'bottom-right',
      dismissible: true,
      duration: 10000,
      closeOnClick: true,
      animate: { in: 'slideInRight', out: 'slideOutRight' },
    });
    yield put({ type: actionTypes.UPDATE_BAGGING_STATUS_FAILED, err });
    errorAudio.play();
  }
}

function* getAllPortCodes(): Generator<any> {
  try {
    const data = yield call(getAllPortCodesService);
    yield put({ type: actionTypes.GET_ALL_PORTCODES_SUCCESS, data });
  } catch (err) {
    yield put({ type: actionTypes.GET_ALL_PORTCODES_FAILED, err });
  }
}

function* downloadTn({ bagNumber }: DownloadTnParams): Generator<any> {
  try {
    const data: any = yield call(downloadTnService, bagNumber);
    const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, `${bagNumber}.csv`);
    yield put({ type: actionTypes.DOWNLOAD_TN_SUCCESS, data });
  } catch (err) {
    yield put({ type: actionTypes.DOWNLOAD_TN_FAILED, err });
  }
}

function* watchBagsRequest() {
  yield all([takeLatest(actionTypes.CREATE_BAG_REQUEST, createBag)]);
  yield all([takeLatest(actionTypes.CLOSE_BAG_REQUEST, closeBag)]);
  yield all([
    takeLatest(actionTypes.UPDATE_BAGGING_STATUS_REQUEST, updateBaggingStatus),
  ]);
  yield all([
    takeLatest(actionTypes.REMOVE_BAG_PACKAGES_REQUEST, removeBagPackages),
  ]);
  yield all([takeLatest(actionTypes.ADD_BAG_PACKAGES_REQUEST, addBagPackages)]);
  yield all([
    takeLatest(actionTypes.VALIDATE_PACKAGE_REQUEST, validatePackage),
  ]);

  yield all([takeLatest(actionTypes.GET_BAG_NUMBER_REQUEST, getBagNumber)]);
  yield all([
    takeLatest(
      actionTypes.REGENERATE_BAG_STICKER_REQUEST,
      regenerateBagSticker
    ),
  ]);

  yield all([
    takeLatest(actionTypes.GET_ALL_PORTCODES_REQUEST, getAllPortCodes),
  ]);
  yield all([takeLatest(actionTypes.DOWNLOAD_TN_REQUEST, downloadTn)]);
  // yield all([takeLatest(actionTypes.GET_BAG_STICKER_REQUEST, getBagSticker)]);
}

export default [watchBagsRequest()];
