import React from 'react';
import loadable from '@loadable/component';
import Loading from '../../commons/loading/index';

export default [
  {
    path: '/login',
    exact: true,
    component: loadable(() => import('./login/index'), {
      fallback: <Loading />,
    }),
  },
];
