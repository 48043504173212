import React from 'react';
import { Link } from 'react-router-dom';

const NotFound: React.FC = () => {
  return (
    <div className="not-found hero is-fullheight">
      <div className="hero-body">
        <div className="container has-text-centered">
          <div className="title is-1 mb-6">Ooops!</div>
          <div className="subtitle is-4">Something went wrong :(</div>
          <Link to="/login">
            <p className="subtitle is-6 has-text-link">
              But you can still go back
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
